export function unixTimeToDatetime(unix) {
	// 2018-03-19 08:00:00
	unix = parseInt(unix)
	if (unix.toString().length === 10)
		unix *= 1000
	
	var date = new Date(unix)
	var month = date.getMonth() + 1
	var dateOfMonth = date.getDate()
	var year = date.getFullYear()
	var hours = date.getHours()
	var minutes = "0" + date.getMinutes()
	var seconds = "0" + date.getSeconds()
	
	return year + '/' + month + '/' + dateOfMonth + " " + hours + ':' + minutes.substring(-2) + ':' + seconds.substring(-2)
}

////////////////////////////////////////////////////////////////
// Dates
////////////////////////////////////////////////////////////////

export function getCurrentTime() {
  var now = new Date()
  return now.getTime()
}

export function getCurrentYear() {
  var now = new Date()
  return now.getFullYear()
}

export function datetimeToDate(datetime) {
  // 2018-03-19 08:00:00
  return new Date(datetime.replace(/-/g, '/'))
}

export function dateToTimeAMPM(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0'+minutes : minutes
  return hours + ':' + minutes + ' ' + ampm
}

export function getDateFromDatetime(str) {
  try {
    var date = datetimeToDate(str)
    return getMonthNameShort(date.getMonth()) + " " + date.getDate() + ", " + date.getFullYear()
  } catch (error) {
    return ""
  }
}

export function getDateCondensedFromDatetime(str) {
  try {
    var output = []
    var date = datetimeToDate(str)
    output.push(date.getMonth() + 1)
    output.push("/")
    output.push(date.getDate())
    output.push("/")
    var year = "" + date.getFullYear()
    output.push(year.substring(2))
    return output.join('')
  } catch (error) {
    return ""
  }
}

export function getTimeFromDatetime(str) {
  try {
    var date = datetimeToDate(str)
    // return date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    return dateToTimeAMPM(date)
  } catch (error) {
    return ""
  }
}

export function getTimeCondensedFromDatetime(str) {
  try {
    var output = []
    var date = (new Date(datetimeToDate(str))).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    var hour = date.substring(0, date.indexOf(':'))
		if (hour.substring(0, 1) === "0")
			hour = hour.substring(1)
    var minute = date.substring(date.indexOf(':')+1)
    minute = minute.substring(0, 2)
    var ampm = date.substring(date.indexOf(' ')+1)
    output.push(hour)
    if (minute != "00") {
      output.push(":")
      output.push(minute)
    }
    if (ampm == "AM")
      output.push("am")
    else if (ampm == "PM")
      output.push("pm")
  
    return output.join('')
  } catch (error) {
    return ""
  }
}

export function dateCompare(year1, month1, day1, year2, month2, day2) {
  // var date1Invalid = pastDateInvalid(month1, day1, year1)
  // var date2Invalid = pastDateInvalid(month2, day2, year2)
  // if (date1Invalid && date2Invalid)
  //   return -3
  // if (date1Invalid)
  //   return -2
  // if (date2Invalid)
  //   return -1
  var date1 = (new Date(year1, month1, day1)).getTime()
  var date2 = (new Date(year2, month2, day2)).getTime()
  if (date1 === date2)
    return 0
  if (date1 < date2)
    return 1
  if (date1 > date2)
    return 2
  return -4
}

export function pastDateInvalid(month, day, year) {
  var today = new Date()

  if (!(month >= 1 && month <= 12 && day >= 1 && day <= 31 && year >= 1910 && year <= today.getFullYear()))
    return true

  month--
  
  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29

  // Check that day is within range of given month
  if (!(day >= 1 && day <= monthLength[month]))
    return true
  
  var date = new Date(year, month, day)
  
  if (date >= today)
    return true

  return false
}

export function pastDateInvalidMonthYear(month, year) {
  var today = new Date()

  if (!(month >= 1 && month <= 12 && year >= 1910 && year <= today.getFullYear()))
    return true

  month--
  
  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29
  
  var date = new Date(year, month)

  // console.log("date: [" + date + "], today: [" + today + "]")
  // console.log("date >= today: " + (date >= today))
  
  if (date >= today)
    return true

  return false
}

export function pastDateInvalidYear(year) {
  var today = new Date()

  if (!(year >= 1910 && year <= today.getFullYear()))
    return true

  return false
}

export function futureDateInvalid(month, day, year) {
  var today = new Date()

  if (!(month >= 1 && month <= 12 && day >= 1 && day <= 31 && year >= today.getFullYear() && year <= 2099))
    return true

  month--
  
  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29

  // Check that day is within range of given month
  if (!(day >= 1 && day <= monthLength[month]))
    return true
  
  var date = new Date(year, month, day)
  
  if (date <= today)
    return true

  return false
}

export function getMonthName(month) {
  var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
  return monthNames[month]
}

export function getMonthNameShort(month) {
  var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
  return monthNames[month]
}

export function getFirstDayOfMonth(day, date) {
  var firstDayOfMonth = day - ((date-1) % 7);
  if (firstDayOfMonth < 0)
    firstDayOfMonth += 7;
  return firstDayOfMonth;
}

export function getDaysInMonth(month, year) {
  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;
  return monthLength[month]
}

export function getDateLong(ISO_date) {
  try {
    var parts = ISO_date.split("-")
    var date = new Date(parts[0], parts[1] - 1, parts[2])
    return getMonthName(date.getMonth()) + " " + date.getDate() + ", " + date.getFullYear()
  } catch(err) {
    return ""
  }
}