import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './SeasonSummary.css'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class SeasonSummary extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			objStandings_PF_PA: null,
			tableWidth: 0,
		}

		this.refTable = React.createRef()
  }

	componentDidMount() {
		window.addEventListener("resize", this.updateWidths.bind(this))
		this.updateWidths()
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWidths.bind(this))
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props !== prevProps)
			this.updateWidths()
		
		if (this.props.mapLeagueRosters !== prevProps.mapLeagueRosters)
			new Promise(
				(resolve) => {
					if (this.props.mapLeagueRosters)
						this.setState({objStandings_PF_PA: fnSleeper.getRegularSeasonStandings_PF_PA(this.props.mapLeagueRosters)})
					else
						this.setState({objStandings_PF_PA: null})
					resolve(true)
				}
			).then(() => {})
	}

	updateWidths() {
		if (this.refTable.current)
			new Promise(
				(resolve) => {
					this.setState({
						tableWidth: this.refTable.current.offsetWidth,
					})
					resolve(true)
				}
			)
	}

	getFixedData() {
		var obj = {
			headers: [
				{content: "Category"}
			],
			rows: this.getFixedRows()
		}
		return obj
	}

	getFixedRows() {
		var texts = [
			{icon: "🏅", text: "Best record"},
			{icon: "😞", text: "Worst record"},
			{icon: "💪", text: "PF (max)"},
			{icon: "👶", text: "PF (min)"},
			{icon: "😭", text: "PA (max)"},
			{icon: "🍀", text: "PA (min)"},
			{icon: "🔥", text: "Most high scores"},
			{icon: "🥴", text: "Most low scores"},
		]

		var rows = []

		for (const obj of texts) {
			rows.push(
				[
					{
						content: (
							<div class="SeasonSummary_category" style={{maxWidth: 'calc(' + parseInt(this.state.tableWidth + 1) + 'px * 0.7 - 96px)'}}>
								{/* <div class="SeasonSummary_category_icon">{obj.icon}</div> */}
								<div class="SeasonSummary_category_text">{obj.text}</div>
							</div>
						)
					}
				]
			)
		}

		return rows
	}

	getData() {
		var obj = {
			headers: [
				{
					content: <div style={{textAlign: 'right'}}>Stat</div>,
					numeric: true
				},
				{
					content: <div style={{marginLeft: '32px'}}>Team</div>,
				}
			],
			rows: this.getRows()
		}
		return obj
	}

	getRows() {
		var mapLeagueUsers = this.props.mapLeagueUsers
		var arrRegularSeasonStandings = this.props.arrRegularSeasonStandings
		var mapRecordsByOwnerID = this.props.mapRecordsByOwnerID
		if (
			mapLeagueUsers === null ||
			arrRegularSeasonStandings === null || arrRegularSeasonStandings.length === 0 ||
			mapRecordsByOwnerID === null
		)
			return null

		var rows = []
		var row = []

		row.push(
			{
				content: (
					<div class="SeasonSummary_user_stat">
						{fnSleeper.formatRecord(mapRecordsByOwnerID.get(arrRegularSeasonStandings[0]).record)}
					</div>
				),
				numeric: true
			}
		)
		row.push(
			{
				content: <User ownerID={arrRegularSeasonStandings[0]} mapLeagueUsers={mapLeagueUsers} />
			}
		)
		rows.push(row)
		
		row = []
		row.push(
			{
				content: (
					<div class="SeasonSummary_user_stat">
						{fnSleeper.formatRecord(mapRecordsByOwnerID.get(arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]).record)}
					</div>
				),
				numeric: true
			}
		)
		row.push(
			{
				content: <User ownerID={arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]} mapLeagueUsers={mapLeagueUsers} />
			}
		)
		rows.push(row)
		
		row = []
		row.push(
			{
				content: (
					<div class="SeasonSummary_user_stat">
						{fnSleeper.formatPoints(mapRecordsByOwnerID.get(this.state.objStandings_PF_PA.pfMost).pf)}
					</div>
				),
				numeric: true
			}
		)
		row.push(
			{
				content: this.state.objStandings_PF_PA ? <User ownerID={this.state.objStandings_PF_PA.pfMost} mapLeagueUsers={mapLeagueUsers} /> : null
			}
		)
		rows.push(row)
		
		row = []
		row.push(
			{
				content: (
					<div class="SeasonSummary_user_stat">
						{fnSleeper.formatPoints(mapRecordsByOwnerID.get(this.state.objStandings_PF_PA.pfLeast).pf)}
					</div>
				),
				numeric: true
			}
		)
		row.push(
			{
				content: this.state.objStandings_PF_PA ? <User ownerID={this.state.objStandings_PF_PA.pfLeast} mapLeagueUsers={mapLeagueUsers} /> : null
			}
		)
		rows.push(row)
		
		row = []
		row.push(
			{
				content: (
					<div class="SeasonSummary_user_stat">
						{fnSleeper.formatPoints(mapRecordsByOwnerID.get(this.state.objStandings_PF_PA.paMost).pa)}
					</div>
				),
				numeric: true
			}
		)
		row.push(
			{
				content: this.state.objStandings_PF_PA ? <User ownerID={this.state.objStandings_PF_PA.paMost} mapLeagueUsers={mapLeagueUsers} /> : null
			}
		)
		rows.push(row)
		
		row = []
		row.push(
			{
				content: (
					<div class="SeasonSummary_user_stat">
						{fnSleeper.formatPoints(mapRecordsByOwnerID.get(this.state.objStandings_PF_PA.paLeast).pa)}
					</div>
				),
				numeric: true
			}
		)
		row.push(
			{
				content: this.state.objStandings_PF_PA ? <User ownerID={this.state.objStandings_PF_PA.paLeast} mapLeagueUsers={mapLeagueUsers} /> : null
			}
		)
		rows.push(row)
		
		if (this.props.week && this.props.mapScoringLeaderByWeek)
			rows.push(this.getMostWeeklyHighLowScores(this.props.week, this.props.mapScoringLeaderByWeek, mapLeagueUsers))
		
		if (this.props.week && this.props.mapScoringLoserByWeek)
			rows.push(this.getMostWeeklyHighLowScores(this.props.week, this.props.mapScoringLoserByWeek, mapLeagueUsers))
		
		return rows
	}

	getMostWeeklyHighLowScores(week, mapScoringLeaderLoserByWeek, mapLeagueUsers) {
		var map = new Map()

		mapScoringLeaderLoserByWeek.forEach(
			(obj, _week) => {
				if (_week < week)
					for (const ownerID of obj.ownerIDs) {
						var count = 1
						if (map.has(ownerID))
							count = map.get(ownerID) + 1
						map.set(ownerID, count)
					}
			}
		)

		var max = 0

		map.forEach(
			(count, ownerID) => {
				if (count > max)
					max = count
			}
		)
		
		var row = []
		row.push(
			{
				content: <div class="SeasonSummary_user_stat">{max}</div>,
				numeric: true
			}
		)

		var inner = []
		map.forEach(
			(count, ownerID) => {
				if (count === max)
					inner.push(
						<li class="SeasonSummary_user_li">
							<User ownerID={ownerID} mapLeagueUsers={mapLeagueUsers} />
						</li>
					)
			}
		)

		row.push(
			{
				content: (
					<ul class="SeasonSummary_user_ul">
						{inner}
					</ul>
				)
			}
		)

		return row
	}

	html() {
		var week = this.props.week
		var playoffWeekStart = this.props.playoffWeekStart
		var secondaryText
		
		if (week) {
			if (week === 1)
				secondaryText = "Season not yet started"
			else if (playoffWeekStart && week >= playoffWeekStart)
				secondaryText = "Final summary"
			else
				secondaryText = "After " + (week-1).toString() + " weeks"
		} else {
			secondaryText = null
		}

		var html = []

		html.push(
			<div ref={this.refTable}>
				<Card
					clear
					primaryText={this.props.season ? this.props.season + " Regular season summary" : "Regular season summary"}
					secondaryText={secondaryText}
					body={
						<Card
							bodyNoPadding
							body={
								<div ref={this.refTable}>
									<DataTables
										noBorder
										fixedData={this.getFixedData()}
										data={this.getData()}
									/>
								</div>
							}
						/>
					}
				/>
			</div>
		)

		return html
	}

  render() {
    return (
      <div id="SeasonSummary">
				{this.html()}
      </div>
    )
  }
}