import React from 'react'

import './Card.css'

export default class Card extends React.Component {
  constructor(props) {
    super(props)
  }

  getClassPrimaryText() {
    if (!this.props.primaryText)
      return "hidden"
    
    var arr = ["Card_primaryText"]
    if (this.props.clear)
      arr.push("clear")
    if (this.props.icon)
      arr.push("icon")
    
    return arr.join(" ")
  }

  getClassSecondaryText() {
    if (!this.props.secondaryText)
      return "hidden"
    
    var arr = ["Card_secondaryText"]
    if (this.props.clear)
      arr.push("clear")
    if (this.props.icon)
      arr.push("icon")
    
    return arr.join(" ")
  }

  getStyleContainerTop() {
    var style = {}
    if (!this.props.fixedHeader && this.props.body || this.props.buttons) // this.props.buttons is a placeholder for the actual way of using buttons
      style.paddingBottom = '0'
    return style
  }

  getStyleContainerMiddle() {
    var style = {}
    if (this.props.bodyNoPadding) {
      style.paddingLeft = '0'
      style.paddingRight = '0'
      style.paddingBottom = '0'

      if (!this.props.primaryText && !this.props.secondaryText && !this.props.icon)
        style.paddingTop = '0'
    }
    if (this.props.fixedHeader)
      style.paddingTop = '0'
    if (this.props.buttons) // this.props.buttons is a placeholder for the actual way of using buttons
      style.paddingBottom = '0'
    return style
  }

  render() {
    return (
      <div
        class={this.props.hide? "hidden" : (this.props.clear ? "Card clear" : "Card")}
        id={this.props.id ? "card-" + this.props.id : null}
      >
        <div class={this.props.clear ? "Card_wrapper clear" : "Card_wrapper"}>
          <div class={this.props.primaryText || this.props.secondaryText || this.props.icon ? "Card_top_wrapper" : "hidden"}>
            <div class="Card_containerTop" style={this.getStyleContainerTop()}>
              <div style={this.props.icon && this.props.clear ? {paddingLeft: '16px'} : null}>
                <object
                  class={this.props.icon ? (this.props.clear ? "Card_icon clear" : "Card_icon") : "hidden"}
                  data={this.props.icon}
                  type={this.props.type}
                />
              </div>
              <div class="Card_topTextContainer" style={this.props.icon ? {marginLeft: '16px'} : null}>
                <div class={this.getClassPrimaryText()}>
                  {this.props.primaryText}
                </div>
                <div class={this.getClassSecondaryText()}>
                  {this.props.secondaryText}
                </div>
              </div>
            </div>
          </div>

          <div class={this.props.body ? (this.props.clear ? "Card_containerMiddle clear" : "Card_containerMiddle") : "hidden"} style={this.getStyleContainerMiddle()}>
            <div class="Card_body">
              {this.props.body}
            </div>
          </div>

          {/* <div class={this.props.buttons ? "Card_containerBottom" : "hidden"}>
            
          </div> */}
        </div>
      </div>
    )
  }
}