// import * as fn from '../../../utils/functions/general.js'

import React from 'react'

import './LogIn.css'

// import Header from '../../../components/header/HeaderRentOut/Header.jsx'
// import Footer from '../../../components/footer/Footer/Footer.jsx'
import LogInForm from './LogInForm.jsx'

export default class LogIn extends React.Component {
  constructor(props) {
		super(props)
		
		this.state = {
			error: null,
    }
	}

  render() {
    return (
			<div id="LogIn">
				{/* <Header price={this.state.price} /> */}
				<div class="LogIn_container">
					<LogInForm />
				</div>
				{/* <Footer /> */}
			</div>
    )
  }
}