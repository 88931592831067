export function sortInt(arr) {
	if (arr.length === 0) {
		console.log("sortInt() - arr.length === 0")
		return
	}
	if (!Number.isInteger(arr[0])) {
		console.log("sortInt() - !Number.isInteger(arr[0])")
		return
	}

	arr.sort(
		(a, b) => {
			return a - b
		}
	)

	return arr
}

export function sortFloat(arr) {
	if (arr.length === 0) {
		console.log("sortFloat() - arr.length === 0")
		return
	}
	if (!Number.isFinite(arr[0])) {
		console.log("sortFloat() - !Number.isFinite(arr[0])")
		return
	}

	arr.sort(
		(a, b) => {
			return a - b
		}
	)

	return arr
}

export function sortString(arr) {
	if (arr.length === 0) {
		console.log("sortString() - arr.length === 0")
		return
	}

	arr.sort()
	
	return arr
}

export function sortMapInt(map, sortByValue, reverse = false) {
	if (map.size === 0) {
		console.log("sortMapInt() - map.size === 0")
		return
	}

	var arr = [...map.entries()].sort(
		([k1, v1], [k2, v2]) => {
			return sortByValue ? v1 - v2 : k1 - k2
		}
	)

	if (!Number.isInteger(arr[0][1])) {
		console.log("sortMapInt() - !Number.isInteger(arr[0][1])")
		return
	}

	if (reverse)
		arr.reverse()
	
	return new Map(arr)
}

export function sortMapFloat(map, sortByValue, reverse = false) {
	if (map.size === 0) {
		console.log("sortMapFloat() - map.size === 0")
		return
	}

	var arr = [...map.entries()].sort(
		([k1, v1], [k2, v2]) => {
			return sortByValue ? v1 - v2 : k1 - k2
		}
	)

	if (!Number.isFinite(arr[0][1])) {
		console.log("sortMapFloat() - !Number.isFinite(arr[0][1])")
		return
	}

	if (reverse)
		arr.reverse()
	
	return new Map(arr)
}

export function sortMapString(map, sortByValue, reverse = false) {
	if (map.size === 0) {
		console.log("sortMapString() - map.size === 0")
		return
	}

	var arr = [...map.entries()]
	try {
		arr.sort(
			([k1, v1], [k2, v2]) => {
				return sortByValue ? v1.localeCompare(v2) : k1.localeCompare(k2)
			}
		)
	} catch (error) {
		console.log("sortMapString() error: " + error)
	}

	if (typeof arr[0][1] !== 'string') {
		console.log("sortMapString() - typeof arr[0][1] [" + typeof arr[0][1] + "] !== 'string'")
		return
	}

	if (reverse)
		arr.reverse()
	
	return new Map(arr)
}