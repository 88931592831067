import React from 'react'

import './HeaderModalNav.css'

import HeaderMenu from '../HeaderMenu/HeaderMenu.jsx'

export default class HeaderModalNav extends React.Component {
  constructor(props) {
		super(props)
	}

  render() {
    return (
			<div id="HeaderModalNav">
        <div class="HeaderModalNav_container">

          <div class="HeaderModalNav_leftWrapper">
            <HeaderMenu
              drawer
              handlerClose={this.props.handlerClose.bind(this)}
            />
          </div>

        </div>
			</div>
    )
  }
}