import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './DraftResult.css'

import Card from '../../../components/card/Card/Card.jsx'
import Switch from '../../../components/field/Switch/Switch.jsx'

export default class DraftResult extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			players: null,
			orderByRosterID: false,
		}

		this.refSwitchOrderByRosterID = React.createRef()
  }

	componentDidMount() {
		if (this.props.mapDraftPicksByDraftID)
			this.httpGetPlayers(this.props.mapDraftPicksByDraftID)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.mapDraftPicksByDraftID !== prevProps.mapDraftPicksByDraftID)
			new Promise(
				(resolve) => {
					if (this.props.mapDraftPicksByDraftID)
						this.httpGetPlayers(this.props.mapDraftPicksByDraftID)
					else
						this.setState({players: null})
					resolve(true)
				}
			).then(() => {})
	}

	handlerSwitch() {
		if (this.refSwitchOrderByRosterID && this.refSwitchOrderByRosterID.current && this.refSwitchOrderByRosterID.current.state)
			new Promise(
				(resolve) => {
					this.setState({orderByRosterID: this.refSwitchOrderByRosterID.current.state.checked === true})
					resolve(true)
				}
			).then(() => {})
	}

  httpGetPlayers(mapDraftPicksByDraftID) {
    var playerIDs = []
    mapDraftPicksByDraftID.forEach(
			(draft, draftID) => {
				draft.forEach(
					(obj) => {
						playerIDs.push(parseInt(obj.player_id))
					}
				)
    	}
		)
    
    var _this = this
    var URL = "/a/rest/sleeper/players"

    fetch(
			URL, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
				body: JSON.stringify(playerIDs),
			}
		)
    .then(
			(resp) => {
				return resp.json()
			}
		)
    .then(
			(data) => {
				if (data.hasOwnProperty("error"))
					_this.props.handlerError(data.error)
				else if (data.hasOwnProperty("players") && data.players !== "" && data.players.length > 0) {
					var map = new Map()
					data.players.forEach(
						(item) => {
							map.set(item.id, item.info)
						}
					)
					_this.setState({players: map})
				}
				else
					_this.setState({players: null})
			}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				_this.props.handlerError("Something went wrong... Please refresh the page.")
				_this.setState({players: null})
			}
		)
  }

	htmlPicks(ownerID, rosterID, draftSlot, numDraftSlots, orderByRosterID, mapPicksByDraftSlot, mapPicksByRosterID, players, mapLeagueUsers, isKeeper) {
		var html = []
		if (players.size === 0)
			return
		
		if (!orderByRosterID && !mapPicksByDraftSlot.has(draftSlot))
			return
		if (orderByRosterID && !mapPicksByRosterID.has(rosterID))
			return
		
		var effMap
		var effMapKey
		var length = 0
		if (orderByRosterID) {
			effMap = mapPicksByRosterID
			effMapKey = rosterID
			length = mapPicksByRosterID.get(rosterID).length
		} else {
			effMap = mapPicksByDraftSlot
			effMapKey = draftSlot
			length = mapPicksByDraftSlot.get(rosterID).length
		}

		for (var i = 0; i < length; i++) {
			var pick = effMap.get(effMapKey)[i]
			// isKeeper = true
			// pick.is_keeper = true
			var round = pick.round

			var pickedBy = null
			if (pick.picked_by !== ownerID)
				pickedBy = fnSleeper.getTeamName(pick.picked_by, mapLeagueUsers)

			var playerID = pick.player_id
			var playerTeam = fnSleeper.getPlayerTeam(playerID, players)
	
			html.push(
				<li class="DraftResult_draft_roster_cell pick">
					
					<div class="DraftResult_draft_pick_player_row">
						<div class="DraftResult_player_avatar_wrapper">
							<object class="DraftResult_player_avatar_img" data={fnSleeper.getPlayerAvatar(playerID)} type="image/png" />
						</div>
						<div class="DraftResult_player_name_wrapper">
							<div class="DraftResult_player_name first">
								{fnSleeper.getPlayerFirstName(playerID, players)}
							</div>
							<div class="DraftResult_player_name last">
								{fnSleeper.getPlayerLastName(playerID, players)}
							</div>
							<div class="DraftResult_player_name details">
								<div class="DraftResult_player_name position">
									{playerTeam === null ? null : playerTeam + " - "}
									{pick.metadata.position}
								</div>
							</div>
						</div>
					</div>
	
					<div class="DraftResult_draft_details">
						<div class="DraftResult_draft_pick_price currency amount">{round + "." + (pick.pick_no - ((round-1) * numDraftSlots)).toString()}</div>
						<div class="DraftResult_draft_details_right">
							<div
								class={
									pickedBy !== null ?
									(isKeeper && pick.is_keeper !== null ? "DraftResult_draft_pickedBy keeper" : "DraftResult_draft_pickedBy")
									: "hidden"
								}
							>
								{pickedBy}
							</div>
							<div class={isKeeper && pick.is_keeper !== null ? "DraftResult_draft_pick_keeper_wrapper" : "hidden"}>
								<div class="DraftResult_draft_pick_price keeper">K</div>
							</div>
						</div>
					</div>
				</li>
			)
		}

		return html
	}

	htmlPicksAuction(rosterID, mapPicksByRosterID, players, isKeeper) {
		var playerIDs = []
		mapPicksByRosterID.get(rosterID).forEach(
			(pick) => {
				playerIDs.push({
					playerID: pick.metadata.player_id,
					position: pick.metadata.position,
					amount: pick.metadata.amount,
					is_keeper: pick.is_keeper,
				})
			}
		)
		
		var html = []
		if (players.size === 0)
			return

		for (const obj of playerIDs) {
			var playerID = obj.playerID
			// var playerTeamAvatar = fnSleeper.getPlayerTeamAvatar(playerID, players)
			var playerTeam = fnSleeper.getPlayerTeam(playerID, players)

			html.push(
				<li class="DraftResult_draft_roster_cell pick">
					
					<div class="DraftResult_draft_pick_player_row">
						<div class="DraftResult_player_avatar_wrapper">
							<object class="DraftResult_player_avatar_img" data={fnSleeper.getPlayerAvatar(playerID)} type="image/png" />
						</div>
						<div class="DraftResult_player_name_wrapper">
							<div class="DraftResult_player_name first">
								{fnSleeper.getPlayerFirstName(playerID, players)}
							</div>
							<div class="DraftResult_player_name last">
								{fnSleeper.getPlayerLastName(playerID, players)}
							</div>
							<div class="DraftResult_player_name details">
								{/* <object class={playerTeamAvatar === null ? "hidden" : "DraftResult_player_teamAvatar_img"} data={playerTeamAvatar} type="image/png" /> */}
								<div class="DraftResult_player_name position">
									{playerTeam === null ? null : playerTeam + " - "}
									{obj.position}
									{/* {
										playerTeamAvatar === null ?
										null :
										" #" + fnSleeper.getPlayerNumber(playerID, players)
									} */}
								</div>
							</div>
						</div>
					</div>

					<div class="DraftResult_draft_details">
						<div class="DraftResult_draft_pick_price currency amount">$ {obj.amount}</div>
						<div class="DraftResult_draft_pick_keeper_wrapper">
							<div class={isKeeper && obj.is_keeper !== null ? "DraftResult_draft_pick_price keeper" : "hidden"}>K</div>
						</div>
					</div>
				</li>
			)
		}

		return html
	}

	htmlDraft(notAuction, arrRosterIDBySlot, draftPicks, draftSettings, mapLeagueRosters, mapLeagueUsers, players, isKeeper) {
		var mapRosterIDsByDraftSlot = new Map()
		var mapBudgets = new Map()

		for (const pick of draftPicks) {
			if (!mapRosterIDsByDraftSlot.has(pick.draft_slot)) {
				mapRosterIDsByDraftSlot.set(pick.draft_slot, pick.roster_id)

				if (draftSettings.hasOwnProperty('budget'))
					mapBudgets.set(pick.roster_id, draftSettings.budget)
			}
		}

		var numDraftSlots = mapRosterIDsByDraftSlot.size

		mapBudgets.forEach(
			(v, k) => {
				for (const prop in draftSettings) {
					if (prop.length >= 7 && prop.substring(0, 7) === "budget_")
						mapBudgets.set(parseInt(prop.substring(7)), draftSettings[prop])
				}
			}
		)
		// mapBudgets.forEach(
		// 	(v, k) => {
		// 		console.log(k + ": " + v)
		// 	}
		// )


		var mapPicksByDraftSlot = new Map()
		var mapPicksByRosterID = new Map()

		if (notAuction && !this.state.orderByRosterID) {
			for (const pick of draftPicks) {
				if (mapPicksByDraftSlot.has(pick.draft_slot)) {
					var rosterPicks = mapPicksByDraftSlot.get(pick.draft_slot)
					rosterPicks.push(pick)
					mapPicksByDraftSlot.set(pick.draft_slot, rosterPicks)
				}
				else
					mapPicksByDraftSlot.set(pick.draft_slot, [pick])
			}
		} else {
			for (const pick of draftPicks) {
				if (mapPicksByRosterID.has(pick.roster_id)) {
					var rosterPicks = mapPicksByRosterID.get(pick.roster_id)
					rosterPicks.push(pick)
					mapPicksByRosterID.set(pick.roster_id, rosterPicks)
				}
				else
					mapPicksByRosterID.set(pick.roster_id, [pick])
			}
		}

		var htmlDraft = []

		for (var i = 0; i < arrRosterIDBySlot.length; i++) {
			var rosterID = arrRosterIDBySlot[i]
			var draftSlot = i+1
			var ownerID = fnSleeper.getOwnerIDByRosterID(rosterID, mapLeagueRosters)
	
			htmlDraft.push(
				<li class="DraftResult_draft_roster">
					<div class="DraftResult_draft_roster_cell team" style={mapBudgets.size > 0 ? {height: '96px'} : null}>
						<div class="DraftResult_draft_team_row">
							<object class="DraftResult_team_avatar_img" data={fnSleeper.getUserAvatar(ownerID, mapLeagueUsers)} type="image/png" />
						</div>
						<div class="DraftResult_draft_team_row">
							<div class="DraftResult_team_name">{fnSleeper.getTeamName(ownerID, mapLeagueUsers)}</div>
						</div>
						{
							mapBudgets.size > 0 ?
							<div class="DraftResult_draft_team_row">
								<div class="DraftResult_team_budget">$ {mapBudgets.get(rosterID)}</div>
							</div>
							: null
						}
					</div>
					<ul>
						{
							notAuction ?
							this.htmlPicks(ownerID, rosterID, draftSlot, numDraftSlots, this.state.orderByRosterID, mapPicksByDraftSlot, mapPicksByRosterID, players, mapLeagueUsers, isKeeper) :
							this.htmlPicksAuction(rosterID, mapPicksByRosterID, players, isKeeper)
						}
					</ul>
				</li>
			)
		}

		return htmlDraft
	}

	html() {
		var draft = this.props.draft
		var secondaryText = this.props.secondaryText
		var notAuction = this.props.notAuction
		var arrRosterIDBySlot = this.props.arrRosterIDBySlot
		var mapDraftPicksByDraftID = this.props.mapDraftPicksByDraftID
		var mapLeagueRosters = this.props.mapLeagueRosters
		var mapLeagueUsers = this.props.mapLeagueUsers
		var liRounds = this.props.liRounds
		var isKeeper = this.props.isKeeper
		var players = this.state.players
		if (
			draft === null ||
			secondaryText === null ||
			notAuction === null ||
			arrRosterIDBySlot === null ||
			arrRosterIDBySlot.length === 0 ||
			mapDraftPicksByDraftID === null ||
			mapLeagueRosters === null ||
			mapLeagueUsers === null ||
			liRounds === null ||
			isKeeper === null ||
			players === null
		)
			return

		var html = []

		html.push(
			<Card
				primaryText={draft.season + " Draft results"}
				secondaryText={secondaryText}
				bodyNoPadding
				body={
					<div class="DraftResult_draft_body">
						<div class={notAuction ? "DraftResult_draft_toggleView" : "hidden"}>
							<div class="DraftResult_draft_toggleView_wrapper">
								<div class="DraftResult_draft_toggleView_label">Order by roster</div>
								<div class="DraftResult_draft_toggleView_switch">
									<Switch
										id={"draftResultsOrderByRosterID-" + draft.draft_id}
										handler={this.handlerSwitch.bind(this)}
										ref={this.refSwitchOrderByRosterID}
									/>
								</div>
							</div>
						</div>
						<div class="DraftResult_draft_wrapper">
							<div class={notAuction && !this.state.orderByRosterID ? "DraftResult_draft_fixedColumn" : "hidden"}>
								<li class="DraftResult_draft_fixedColumn_text header" />
								{liRounds}
							</div>
							<ul class={notAuction && !this.state.orderByRosterID ? "DraftResult_draft_header_wrapper fixedColumn" : "DraftResult_draft_header_wrapper"}>
								{
									this.htmlDraft(
										notAuction,
										arrRosterIDBySlot,
										mapDraftPicksByDraftID.get(draft.draft_id),
										draft.settings,
										mapLeagueRosters,
										mapLeagueUsers,
										players,
										isKeeper,
									)
								}
							</ul>
						</div>
					</div>
				}
			/>
		)
		
		return html
	}

  render() {
    return (
			<li class="DraftResult_draft">
				{this.html()}
			</li>
    )
  }
}