import React from 'react'

import './Switch.css'

export default class Switch extends React.Component {
  constructor(props) {
		super(props)
    
    this.state = {
			checked: false,
			focused: false,
    }

		this.refSwitch = React.createRef()
		this.refInput = React.createRef()
  }

	componentDidMount() {
		if (this.props.initVal)
			new Promise(
				(resolve) => {
					this.setState({checked: true})
					resolve(true)
				}
			).then(() => {})
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	if (this.state.focused !== prevState.focused)
	// 		console.log("this.state.focused: " + this.state.focused)
	// }

	handleClick() {
		new Promise(
			(resolve) => {
				this.setState({checked: !this.state.checked})
				resolve(true)
			}
		).then(() => {
			this.props.handler(this.state.checked)
		})
	}

	onFocus() {
		this.setState({focused: true})
	}

	onBlur() {
		this.setState({focused: false})
	}

	getClassUnderlay() {
		var output = []
		output.push("Switch_thumb_underlay")
		if (this.state.checked)
			output.push("checked")
		if (this.state.focused)
			output.push("focused")
		return output.join(" ")
	}

  render() {
    return (
      <div class="Switch" id={this.props.id ? "Switch-" + this.props.id : null} ref={this.refSwitch}>
				<div class={this.state.checked ? "Switch_track checked" : "Switch_track"} />
				<div
					class={this.getClassUnderlay()}
				>
					<div class={this.state.checked ? "Switch_thumb checked" : "Switch_thumb"}>
						<input
							class={this.state.checked ? "Switch_input checked" : "Switch_input"}
							type="checkbox"
							role="switch"
							id={this.props.id ? "switch-" + this.props.id : null}
							ref={this.refInput}
							onClick={this.handleClick.bind(this)}
							onFocus={this.onFocus.bind(this)}
							onBlur={this.onBlur.bind(this)}
						/>
					</div>
				</div>
      </div>
    )
  }
}