import React from 'react'

import './Divider.css'

export default class Divider extends React.Component {
  constructor(props) {
    super(props)
  }

  getClass() {
    var output = []
    output.push("Divider")

    if (this.props.fullBleed)
      output.push("fullBleed")
    else if (this.props.middle)
      output.push("middle")
    
    return output.join(' ')
  }

  render() {
    return (
      <li class={this.getClass()} role="separator">
        <div class="Divider_line" />
      </li>
    )
  }
}