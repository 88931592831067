import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './Standings.css'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class Standings extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			tableWidth: 0,
		}

		this.refTable = React.createRef()
  }

	componentDidMount() {
		window.addEventListener("resize", this.updateWidths.bind(this))
		this.updateWidths()
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWidths.bind(this))
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props !== prevProps)
			this.updateWidths()
	}

	updateWidths() {
		if (this.refTable.current)
			new Promise(
				(resolve) => {
					this.setState({
						tableWidth: this.refTable.current.offsetWidth,
					})
					resolve(true)
				}
			)
	}

  html() {
		var html = []

		var week = this.props.week
		var playoffWeekStart = this.props.playoffWeekStart
		var secondaryText
		
		if (week) {
			if (week === 1)
				secondaryText = "Season not yet started"
			else if (playoffWeekStart && week >= playoffWeekStart)
				secondaryText = "Final standings"
			else
				secondaryText = "After " + (week-1).toString() + " weeks"
		} else {
			secondaryText = null
		}

		html.push(
			<Card
				clear
				primaryText={this.props.season ? this.props.season + " Regular season standings" : "Regular season standings"}
				secondaryText={secondaryText}
				body={
					<Card
						bodyNoPadding
						body={
							<div ref={this.refTable}>
								<DataTables
									noBorder
									fixedData={this.getFixedData()}
									data={this.getData()}
									initSortOrder="0a"
								/>
							</div>
						}
					/>
				}
			/>
		)

		return html
  }

	getFixedData() {
		var obj = {
			headers: [
				{
					content: <div style={{paddingLeft: '62px'}}>Standings</div>,
					sort: true
				}
			],
			rows: this.getFixedRows()
		}
		return obj
	}

	getFixedRows() {
		var mapLeagueUsers = this.props.mapLeagueUsers
		var arrRegularSeasonStandings = this.props.arrRegularSeasonStandings
		if (mapLeagueUsers === null || arrRegularSeasonStandings === null)
			return null
		
		var rows = []
		
		var rank = 1
		for (const ownerID of arrRegularSeasonStandings) {
			var row = []
			row.push(
				{
					content: (
						<div class="Standings_user_wrapper" style={{maxWidth: 'calc(' + parseInt(this.state.tableWidth + 1) + 'px * 0.6 - 64px)'}}>
							<div class="Standings_user_span rank">{rank}</div>
							<div style={{marginLeft: '16px', width: 'calc(100% - 32px)'}}>
								<User ownerID={ownerID} mapLeagueUsers={mapLeagueUsers} />
							</div>
						</div>
					),
					value: parseInt(rank)
				}
			)
			rows.push(row)
			
			rank++
		}

		return rows
	}

	getData() {
		var obj = {
			headers: [
				{
					content: <div class="Standings_th">Record</div>,
					numeric: true,
				},
				{
					content: <div class="Standings_th">PF</div>,
					numeric: true,
					sort: true
				},
				{
					content: <div class="Standings_th">PA</div>,
					numeric: true,
					sort: true
				}
			],
			rows: this.getRows()
		}
		return obj
	}

	getRows() {
		var arrRegularSeasonStandings = this.props.arrRegularSeasonStandings
		var mapRecordsByOwnerID = this.props.mapRecordsByOwnerID
		if (arrRegularSeasonStandings === null || mapRecordsByOwnerID === null)
			return null

		var rows = []
		
		for (const ownerID of arrRegularSeasonStandings) {
			const pf = mapRecordsByOwnerID.get(ownerID).pf
			const pa = mapRecordsByOwnerID.get(ownerID).pa

			var row = []
			if (mapRecordsByOwnerID.has(ownerID)) {
				row.push(
					{
						content: <div class="Standings_td">{fnSleeper.formatRecord(mapRecordsByOwnerID.get(ownerID).record)}</div>,
						numeric: true
					}
				)
				row.push(
					{
						content: <div class="Standings_td">{fnSleeper.formatPoints(pf)}</div>,
						numeric: true,
						value: parseFloat(pf),
					}
				)
				row.push(
					{
						content: <div class="Standings_td">{fnSleeper.formatPoints(pa)}</div>,
						numeric: true,
						value: parseFloat(pa),
					}
				)
			} else {
				row.push({content: <div class="Standings_td">-</div>, numeric: true})
				row.push({content: <div class="Standings_td">-</div>, numeric: true})
				row.push({content: <div class="Standings_td">-</div>, numeric: true})
			}

			rows.push(row)
		}

		return rows
	}

  render() {
    return (
      <div id="Standings">
				{this.html()}
      </div>
    )
  }
}