import * as fnSort from '../../../utils/functions/sort.js'
import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './PastStats.css'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class PastStats extends React.Component {
  constructor(props) {
    super(props)
  }

	getFixedData() {
		return {
			headers: [
				{
					content: "Season",
					numeric: true,
					sort: true
				}
			],
			rows: this.getFixedRows()
		}
	}

	getFixedRows() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		if (arrPastSeasonsByLeagueID === null)
			return
		
		var rows = []

		for (const obj of arrPastSeasonsByLeagueID) {
			if (obj.league.status === "complete")
				rows.push(
					[
						{
							content: <div style={{height: '40px', lineHeight: '40px'}}>{obj.league.season}</div>,
							numeric: true,
							value: parseInt(obj.league.season)
						}
					]
				)
		}

		var archivedSeasons = this.props.archivedSeasons
		if (archivedSeasons !== null) {
			var arrYears = []

			archivedSeasons.mapStandingsByYear.forEach(
				(v, year) => {
					arrYears.push(parseInt(year))
				}
			)

			arrYears = fnSort.sortInt(arrYears).reverse()

			for (const year of arrYears)
				rows.push(
					[
						{
							content: <div style={{height: '40px', lineHeight: '40px'}}>{year}</div>,
							numeric: true,
							value: parseInt(year)
						}
					]
				)
		}

		return rows
	}

	getData() {
		return {
			headers: [
				{
					content: (
						<div class="PastStats_th_wrapper">
							<div class="PastStats_th_icon">🏅</div>
							<div class="PastStats_th_text">Best record</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStats_th_wrapper">
							<div class="PastStats_th_icon">😞</div>
							<div class="PastStats_th_text">Worst record</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStats_th_wrapper">
							<div class="PastStats_th_icon">💪</div>
							<div class="PastStats_th_text">PF (max)</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStats_th_wrapper">
							<div class="PastStats_th_icon">👶</div>
							<div class="PastStats_th_text">PF (min)</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStats_th_wrapper">
							<div class="PastStats_th_icon">😭</div>
							<div class="PastStats_th_text">PA (max)</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStats_th_wrapper">
							<div class="PastStats_th_icon">🍀</div>
							<div class="PastStats_th_text">PA (min)</div>
						</div>
					)
				}
			],
			rows: this.getRows()
		}
	}

	getRows() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		var mapOfMapLeagueRostersByLeagueID = this.props.mapOfMapLeagueRostersByLeagueID
		var mapLeagueUsers = this.props.mapLeagueUsers

		var archivedSeasons = this.props.archivedSeasons

		if (arrPastSeasonsByLeagueID === null || mapOfMapLeagueRostersByLeagueID === null || mapLeagueUsers === null)
			return
		
		var rows = []

		if (arrPastSeasonsByLeagueID)
			for (const obj of arrPastSeasonsByLeagueID) {
				var mapLeagueRosters = mapOfMapLeagueRostersByLeagueID.get(obj.leagueID)
				var arrRegularSeasonStandings = fnSleeper.getRegularSeasonStandings(mapLeagueRosters)
				var objStandings_PF_PA = fnSleeper.getRegularSeasonStandings_PF_PA(mapLeagueRosters)
				var mapRecords = objStandings_PF_PA.mapRecords
				
				var data = []

				if (Object.keys(objStandings_PF_PA).length > 0) {
					data.push(
						{
							content: (
								<User
									ownerID={arrRegularSeasonStandings[0]}
									mapLeagueUsers={mapLeagueUsers}
									secondaryText={"(" + fnSleeper.formatRecord(mapRecords.get(arrRegularSeasonStandings[0]).record) + ")"}
									secondaryFontWeight='500'
									maxWidth='100px'
								/>
							)
						}
					)
					data.push(
						{
							content: (
								<User
									ownerID={arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]}
									mapLeagueUsers={mapLeagueUsers}
									secondaryText={"(" + fnSleeper.formatRecord(mapRecords.get(arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]).record) + ")"}
									secondaryFontWeight='500'
									maxWidth='100px'
								/>
							)
						}
					)
					data.push(
						{
							content: (
								<User
									ownerID={objStandings_PF_PA.pfMost}
									mapLeagueUsers={mapLeagueUsers}
									secondaryText={"(" + mapRecords.get(objStandings_PF_PA.pfMost).pf + ")"}
									secondaryFontWeight='500'
									maxWidth='100px'
								/>
							)
						}
					)
					data.push(
						{
							content: (
								<User
									ownerID={objStandings_PF_PA.pfLeast}
									mapLeagueUsers={mapLeagueUsers}
									secondaryText={"(" + mapRecords.get(objStandings_PF_PA.pfLeast).pf + ")"}
									secondaryFontWeight='500'
									maxWidth='100px'
								/>
							)
						}
					)
					data.push(
						{
							content: (
								<User
									ownerID={objStandings_PF_PA.paMost}
									mapLeagueUsers={mapLeagueUsers}
									secondaryText={"(" + mapRecords.get(objStandings_PF_PA.paMost).pa + ")"}
									secondaryFontWeight='500'
									maxWidth='100px'
								/>
							)
						}
					)
					data.push(
						{
							content: (
								<User
									ownerID={objStandings_PF_PA.paLeast}
									mapLeagueUsers={mapLeagueUsers}
									secondaryText={"(" + mapRecords.get(objStandings_PF_PA.paLeast).pa + ")"}
									secondaryFontWeight='500'
									maxWidth='100px'
								/>
							)
						}
					)
				}
				
				if (data.length > 0)
					rows.push(data)
			}
		
		if (archivedSeasons) {
			for (const row of this.getArchivedRows(archivedSeasons))
				rows.push(row)
		}

		return rows
	}

	getArchivedRows(archivedSeasons) {
		var arrYears = []

		archivedSeasons.mapStandingsByYear.forEach(
			(v, year) => {
				arrYears.push(parseInt(year))
			}
		)

		arrYears = fnSort.sortInt(arrYears).reverse()

		var rows = []

		for (const year of arrYears) {
			var _mapLeagueRosters = new Map()

			for (const teamStats of archivedSeasons.mapTeamStatsByYear.get(year.toString())) {
				var record = []
				
				var strRecord = teamStats.record.split("-")
				for (var i = 0; i < strRecord.length; i++) {
					switch (i) {
						case 0:
							for (var j = 1; j <= parseInt(strRecord[i]); j++)
								record.push("W")
							break
						case 1:
							for (var j = 1; j <= parseInt(strRecord[i]); j++)
								record.push("L")
							break
						case 2:
							for (var j = 1; j <= parseInt(strRecord[i]); j++)
								record.push("T")
							break
					}
				}

				record = record.join("")

				var pf = teamStats.pf.split(".")
				var pf_decimal = pf.length > 0 ? parseFloat(pf[1]) : 0
				var pa = teamStats.pa.split(".")
				var pa_decimal = pa.length > 0 ? parseFloat(pa[1]) : 0

				var metadata = {record: record}
				var settings = {
					fpts: parseFloat(pf[0]),
					fpts_decimal: pf_decimal,
					fpts_against: parseFloat(pa[0]),
					fpts_against_decimal: pa_decimal,
				}

				var ownerID = teamStats.roster_id
				if (teamStats.hasOwnProperty("owner_id") && teamStats.owner_id !== null && teamStats.owner_id !== "")
					ownerID = teamStats.owner_id

				_mapLeagueRosters.set(
					ownerID,
					{
						metadata: metadata,
						settings: settings,
					}
				)
			}

			var arrRegularSeasonStandings = fnSleeper.getRegularSeasonStandings(_mapLeagueRosters)
			var objStandings_PF_PA = fnSleeper.getRegularSeasonStandings_PF_PA(_mapLeagueRosters)
			var mapRecords = objStandings_PF_PA.mapRecords
			
			var data = []

			if (Object.keys(objStandings_PF_PA).length > 0) {
				var teamName = archivedSeasons.mapRosterSlotsByRosterID.get(arrRegularSeasonStandings[0]).teamName
				var displayName = archivedSeasons.mapRosterSlotsByRosterID.get(arrRegularSeasonStandings[0]).displayName
				var avatar = archivedSeasons.mapRosterSlotsByRosterID.get(arrRegularSeasonStandings[0]).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"(" + fnSleeper.formatRecord(mapRecords.get(arrRegularSeasonStandings[0]).record) + ")"}
								secondaryFontWeight='500'
								maxWidth='100px'
							/>
						)
					}
				)

				teamName = archivedSeasons.mapRosterSlotsByRosterID.get(arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]).teamName
				displayName = archivedSeasons.mapRosterSlotsByRosterID.get(arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]).displayName
				avatar = archivedSeasons.mapRosterSlotsByRosterID.get(arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"(" + fnSleeper.formatRecord(mapRecords.get(arrRegularSeasonStandings[arrRegularSeasonStandings.length-1]).record) + ")"}
								secondaryFontWeight='500'
								maxWidth='100px'
							/>
						)
					}
				)

				teamName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.pfMost).teamName
				displayName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.pfMost).displayName
				avatar = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.pfMost).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"(" + mapRecords.get(objStandings_PF_PA.pfMost).pf + ")"}
								secondaryFontWeight='500'
								maxWidth='100px'
							/>
						)
					}
				)

				teamName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.pfLeast).teamName
				displayName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.pfLeast).displayName
				avatar = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.pfLeast).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"(" + mapRecords.get(objStandings_PF_PA.pfLeast).pf + ")"}
								secondaryFontWeight='500'
								maxWidth='100px'
							/>
						)
					}
				)

				teamName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.paMost).teamName
				displayName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.paMost).displayName
				avatar = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.paMost).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"(" + mapRecords.get(objStandings_PF_PA.paMost).pa + ")"}
								secondaryFontWeight='500'
								maxWidth='100px'
							/>
						)
					}
				)

				teamName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.paLeast).teamName
				displayName = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.paLeast).displayName
				avatar = archivedSeasons.mapRosterSlotsByRosterID.get(objStandings_PF_PA.paLeast).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"(" + mapRecords.get(objStandings_PF_PA.paLeast).pa + ")"}
								secondaryFontWeight='500'
								maxWidth='100px'
							/>
						)
					}
				)
			}
			
			if (data.length > 0)
				rows.push(data)
		}

		return rows
	}

  render() {
    return (
			<Card
				clear
				primaryText="Past season statistics"
				secondaryText="Regular season"
				body={
					<Card
						bodyNoPadding
						body={
							<DataTables
								noBorder
								fixedData={this.getFixedData()}
								data={this.getData()}
								initSortOrder="0d"
							/>
						}
					/>
				}
			/>
    )
  }
}