import React from 'react'

import './List.css'

export default class List extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ul class="List">
        {this.props.children}
      </ul>
    )
  }
}