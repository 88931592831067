import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './User.css'

export default class User extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			avatarWidth: 0,
		}

		this.refAvatar = React.createRef()
  }

	componentDidMount() {
		window.addEventListener('resize', this.handleResize.bind(this))
		this.handleResize()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize.bind(this))
	}

	handleResize() {
		if (this.refAvatar.current)
			new Promise(
				(resolve) => {
					this.setState({avatarWidth: this.refAvatar.current.offsetWidth})
					resolve(true)
				}
			)
	}

	getStyleAvatar() {
		var style = {}
		if (this.props.marginLeft)
			style.marginLeft = this.props.marginLeft
		if (this.props.height) {
			style.height = this.props.height
			style.width = this.props.height
		}
		return style
	}

	getStyleAvatarImg() {
		var style = {}
		if (this.props.hideName)
			style.padding = '0'
		if (this.props.height) {
			if (this.props.hideName) {
				style.height = this.props.height
				style.width = this.props.height
			} else {
				style.height = "calc(" + this.props.height + " - 4px)"
				style.width = "calc(" + this.props.height + " - 4px)"
			}
		}
		return style
	}

	getStyleNameWrapper() {
		if (!this.props.maxWidth)
			var widthSubtract = this.state.avatarWidth + 8

		var style = {}
		if (this.props.hideAvatar) {
			style.marginLeft = '0'
			if (!this.props.maxWidth)
				widthSubtract -= 8
		}
		else if (this.props.marginLeftName) {
			style.marginLeft = this.props.marginLeftName
			if (!this.props.maxWidth) {
				widthSubtract -= 8
				widthSubtract += parseInt(this.props.marginLeftName.split("px")[0])
			}
		}
		if (this.props.hideSecondary)
			style.height = '14px'
		
		if (this.props.maxWidth)
			style.maxWidth = this.props.maxWidth
		else
			style.maxWidth = 'calc(100% - ' + widthSubtract + 'px)'
		return style
	}

	getStyleSecondary() {
		var style = {}
		if (this.props.secondaryFontWeight)
			style.fontWeight = this.props.secondaryFontWeight
		return style
	}

  render() {
    return (
			<div class="User">
				<div
					class={this.props.hideAvatar ? "hidden" : "User_avatar"}
					style={this.props.hideAvatar ? null : this.getStyleAvatar()}
					ref={this.refAvatar}
				>
					<object
						class="User_avatar_img"
						style={this.getStyleAvatarImg()}
						data={
							this.props.avatar ?
							this.props.avatar :
								(
									this.props.ownerID && this.props.mapLeagueUsers ?
									fnSleeper.getUserAvatar(this.props.ownerID, this.props.mapLeagueUsers)
									: null
								)
						}
						type={this.props.type ? this.props.type : "image/png"}
					/>
				</div>

				<div class={this.props.hideName ? "hidden" : "User_name_wrapper"} style={this.getStyleNameWrapper()} >
					<div class="User_name">
						{
							this.props.teamName ?
							this.props.teamName :
								(
									this.props.ownerID && this.props.mapLeagueUsers ?
									fnSleeper.getTeamName(this.props.ownerID, this.props.mapLeagueUsers)
									: null
								)
						}
					</div>
					<div class={this.props.hideSecondary ? "hidden" : "User_name secondary"} style={this.getStyleSecondary()}>
						{
							this.props.secondaryText ?
							this.props.secondaryText : 
								(
									this.props.ownerID && this.props.mapLeagueUsers ?
									"@" + fnSleeper.getDisplayName(this.props.ownerID, this.props.mapLeagueUsers)
									: null
								)
						}
					</div>
				</div>
			</div>
    )
  }
}