import * as fn from '../../../../utils/functions/sleeper.js'

import React from 'react'

import './Header.css'

import MaterialIcons from '../../../icons/MaterialIcons/MaterialIcons.jsx'
import Menu from '../../../menu/Menu/Menu.jsx'
import MenuElement from '../../../menu/MenuElement/MenuElement.jsx'

import HeaderMenu from '../HeaderMenu/HeaderMenu.jsx'

export default class Header extends React.Component {
  constructor(props) {
		super(props)

    this.state = {
      dark: "0",
      avatar: null,
      showUserMenu: false,
      widthSubtract: 0,
    }

    this.refMenu = React.createRef()
		this.refMenuWrapper = React.createRef()
    this.refLeftWrapper = React.createRef()
    this.refRightWrapper = React.createRef()
	}

	componentDidMount() {
    this.updateWidths()
		window.addEventListener("resize", this.updateWidths.bind(this))
		document.addEventListener('mousedown', this.handleClick.bind(this));

    if (this.props.user !== null)
      new Promise(
        (resolve) => {
          this.setState({dark: this.props.user.dark})
          resolve(true)
        }
      ).then(() => {})
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWidths.bind(this))
		document.removeEventListener('mousedown', this.handleClick.bind(this));
	}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user && this.props.user !== null && this.state.dark !== this.props.user.dark)
      new Promise(
        (resolve) => {
          this.setState({dark: this.props.user.dark})
          resolve(true)
        }
      ).then(() => {})
    
    if (this.state.dark !== prevState.dark) {
			if (this.state.dark === "1")
				document.querySelector("html").setAttribute("dark", "true")
			else
				document.querySelector("html").removeAttribute("dark")
		}
		
    if (
      (
        this.props.user !== prevProps.user ||
        this.props.mapLeagueUsers !== prevProps.mapLeagueUsers
      ) &&
      this.props.user &&
      this.props.mapLeagueUsers
    ) {
      this.setState({avatar: fn.getUserAvatar(this.props.user.sleeperID, this.props.mapLeagueUsers)})
    }

    if (this.state.avatar !== prevState.avatar || this.state.showUserMenu !== prevState.showUserMenu)
      this.updateWidths()
  }

  updateWidths() {
    new Promise(
      (resolve) => {
        this.setState({
          widthSubtract: this.refLeftWrapper.current.offsetWidth + this.refRightWrapper.current.offsetWidth,
        })
        resolve(true)
      }
    ).then(() => {})
  }

	handleClick(event) {
		if (!this.refMenu.current.contains(event.target))
			new Promise(
				(resolve) => {
					this.setState({showUserMenu: false})
					resolve(true)
				}
			).then(() => {})
	}

  handleClickUser(event) {
		if (this.refMenu.current.contains(event.target) && !this.refMenuWrapper.current.contains(event.target))
			this.toggleMenu()
  }

	toggleMenu() {
		new Promise(
			(resolve) => {
				this.setState({showUserMenu: !this.state.showUserMenu})
				resolve(true)
			}
		).then(() => {})
	}

	handleDarkMode(checked) {
    var URL = "/a/rest/dark"

    fetch(
			URL, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
				body: JSON.stringify(checked),
			}
		)
    .then(
			(resp) => {
				return resp.json()
			}
		)
    .then(
			(data) => {
				if (data.hasOwnProperty("error"))
					this.props.handlerError(data.error)
				else if (data.hasOwnProperty("dark"))
          new Promise(
            (resolve) => {
              this.setState({dark: data.dark})
              resolve(true)
            }
          ).then(() => {})
				else
					this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		)
	}

  getMenuElems() {
    var elems = []
		
    elems.push(
			<MenuElement
				leadingMaterialIcon="dark_mode"
				text="Dark mode"
				switch={this.state.dark}
				handlerSwitch={this.handleDarkMode.bind(this)}
			/>
    )
		elems.push(
			<MenuElement
				leadingMaterialIcon="logout"
				text="Log out"
				href="/logout"
				onClick={this.toggleMenu.bind(this)}
			/>
		)

    return elems
  }

  render() {
    return (
			<div id="Header">
        <div class="Header_container">

          <span class="Header_leftWrapper">
            <div class="Header_HeaderMenu_wrapper" ref={this.refLeftWrapper}>
              <HeaderMenu
                leagueRules={this.props.leagueRules}
                arrActiveLeagues={this.props.arrActiveLeagues}
                handleChangeSelectLeague={this.props.handleChangeSelectLeague.bind(this)}
                handleChangeUpdateView={this.props.handleChangeUpdateView.bind(this)}
              />
            </div>
            <span class="Header_title_wrapper">
              <div class="Header_title" style={{maxWidth: 'calc(100vw - ' + this.state.widthSubtract + 'px - 68px)'}}>
                {this.props.leagueName}
              </div>
            </span>
          </span>

					<span class="Header_right_wrapper" ref={this.refRightWrapper}>
            <div class="Header_right">
              <div
								class={this.state.showUserMenu ? "Header_user_wrapper selected" : "Header_user_wrapper"}
								onClick={this.handleClickUser.bind(this)}
								ref={this.refMenu}
							>
                <span class="Header_right_container avatar">
                  <object class="Header_user_avatar" data={this.state.avatar} type="image/png" />
                </span>
                <span class="Header_right_container email">
                  <div class="Header_user_email">{this.props.user.email}</div>
                </span>
                <span class="Header_right_container expand">
                  <MaterialIcons size="24" color="var(--med-color)">expand_more</MaterialIcons>
                </span>

                <div
									class={this.state.showUserMenu ? "Header_user_menu_wrapper" : "hidden"}
									ref={this.refMenuWrapper}
								>
									<Menu elems={this.getMenuElems()} />
                </div>
              </div>
            </div>
					</span>

        </div>
			</div>
    )
  }
}