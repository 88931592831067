import React from 'react'

import './PastSeasons.css'

import PastStandings from './modules/PastStandings.jsx'
import PastStats from './modules/PastStats.jsx'
import PastChampionships from './modules/PastChampionships.jsx'
import PastRegSeasonWins from './modules/PastRegSeasonWins.jsx'
import PastPFLeaders from './modules/PastPFLeaders.jsx'
import PastTop3 from './modules/PastTop3.jsx'

export default class PastSeasons extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			archivedSeasons: null,
			mapWinnersBracketsByLeagueID: null,
			mapOfMapLeagueRostersByLeagueID: null,
		}

		this.refPastSeasons = React.createRef()
  }

	componentDidMount() {
		this.refPastSeasons.current.scrollTo(0, 0)

		var leagueID = this.props.leagueID
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID

		if (arrPastSeasonsByLeagueID && arrPastSeasonsByLeagueID.length > 0) {
			this.httpGetWinnersBracket(arrPastSeasonsByLeagueID)
			this.httpGetLeagueRosters(arrPastSeasonsByLeagueID)
		}
		else
			new Promise(
				(resolve) => {
					this.setState({
						mapWinnersBracketsByLeagueID: null,
						mapOfMapLeagueRostersByLeagueID: null,
					})
					resolve(true)
				}
			).then(() => {})

		if (leagueID && arrPastSeasonsByLeagueID) {
			this.httpGetArchivedSeasons(leagueID, arrPastSeasonsByLeagueID)
		}
		else
			new Promise(
				(resolve) => {
					this.setState({
						archivedSeasons: null,
					})
					resolve(true)
				}
			).then(() => {})
	}

	componentDidUpdate(prevProps, prevState) {
		var leagueID = this.props.leagueID
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID

		if (arrPastSeasonsByLeagueID !== prevProps.arrPastSeasonsByLeagueID) {
			if (arrPastSeasonsByLeagueID && arrPastSeasonsByLeagueID.length > 0) {
				this.httpGetWinnersBracket(arrPastSeasonsByLeagueID)
				this.httpGetLeagueRosters(arrPastSeasonsByLeagueID)
			}
			else
				new Promise(
					(resolve) => {
						this.setState({
							mapWinnersBracketsByLeagueID: null,
							mapOfMapLeagueRostersByLeagueID: null,
						})
						resolve(true)
					}
				).then(() => {})
		}

		if (leagueID !== prevProps.leagueID || arrPastSeasonsByLeagueID !== prevProps.arrPastSeasonsByLeagueID) {
			if (leagueID || arrPastSeasonsByLeagueID)
				this.httpGetArchivedSeasons(leagueID, arrPastSeasonsByLeagueID)
			else
				new Promise(
					(resolve) => {
						this.setState({
							archivedSeasons: null,
						})
						resolve(true)
					}
				).then(() => {})
		}
	}

	httpGetArchivedSeasons(leagueID, arrPastSeasonsByLeagueID) {
		var oldestLeagueID = null

		if (
			arrPastSeasonsByLeagueID &&
			arrPastSeasonsByLeagueID.length > 0 &&
			arrPastSeasonsByLeagueID[arrPastSeasonsByLeagueID.length - 1] === "720115173544763392"
		) {
			oldestLeagueID = arrPastSeasonsByLeagueID[arrPastSeasonsByLeagueID.length - 1]
		}
		else if (leagueID !== null && leagueID === "720115173544763392") {
			oldestLeagueID = leagueID
		}
		else {
			new Promise(
				(resolve) => {
					this.setState({archivedSeasons: null})
					resolve(true)
				}
			).then(() => {})
			return
		}
		
		var URL = "/a/rest/sleeper/archive/pastSeasons/" + oldestLeagueID

		fetch(
			URL, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
			}
		)
		.then(
			(resp) => {
				return resp.json()
			}
		)
		.then(
			(data) => {
				if (data.hasOwnProperty("error"))
					this.props.handlerError(data.error)
				else if (
					data.hasOwnProperty("roster_slots") && data.roster_slots !== "" &&
					data.hasOwnProperty("standings_by_year") && data.standings_by_year !== "" &&
					data.hasOwnProperty("team_stats_by_year") && data.team_stats_by_year !== ""
				) {
					var mapRosterSlotsByRosterID = new Map()
					for (const rosterSlot of data.roster_slots) {
						var ownerID = rosterSlot.hasOwnProperty("owner_id") ? rosterSlot.owner_id : rosterSlot.roster_id

						mapRosterSlotsByRosterID.set(
							rosterSlot.roster_id,
							{
								ownerID: ownerID,
								teamName: rosterSlot.team_name,
								displayName: rosterSlot.display_name,
								avatar: rosterSlot.avatar,
							}
						)
					}

					var mapStandingsByYear = new Map()
					for (const [year, standings] of Object.entries(data.standings_by_year))
						mapStandingsByYear.set(year, standings)

					var mapTeamStatsByYear = new Map()
					for (const [year, _arrTeamStats] of Object.entries(data.team_stats_by_year))
						for (const teamStats of _arrTeamStats) {
							var arrTeamStats = []
							if (mapTeamStatsByYear.has(year))
								arrTeamStats = mapTeamStatsByYear.get(year)
							arrTeamStats.push(teamStats)
							mapTeamStatsByYear.set(year, arrTeamStats)
						}

					new Promise(
						(resolve) => {
							this.setState({archivedSeasons: {
								mapRosterSlotsByRosterID: mapRosterSlotsByRosterID,
								mapStandingsByYear: mapStandingsByYear,
								mapTeamStatsByYear: mapTeamStatsByYear,
							}})
							resolve(true)
						}
					).then(() => {})
				}
				else
					new Promise(
						(resolve) => {
							this.setState({archivedSeasons: null})
							this.props.handlerError("Something went wrong... Please refresh the page.")
							resolve(true)
						}
					).then(() => {})
			}
		).then(() => {})
		.catch(
			(error) => {
				console.error('Error:', error)
				this.setState({error: "Something went wrong... Please refresh the page.", archivedSeasons: null})
			}
		)
	}

	httpGetWinnersBracket(arrPastSeasonsByLeagueID) {
		this.fetchWinnersBracket(arrPastSeasonsByLeagueID, 0, null)
	}

	fetchWinnersBracket(arrPastSeasonsByLeagueID, index, mapWinnersBracketsByLeagueID) {
		var leagueID = arrPastSeasonsByLeagueID[index].leagueID

    var URL = "https://api.sleeper.app/v1/league/" + leagueID + "/winners_bracket"

		if (mapWinnersBracketsByLeagueID === null)
			mapWinnersBracketsByLeagueID = new Map()

    fetch(
			URL, {
				method: 'GET',
			}
		).then(
			(resp) => {
				return resp.json()
			}
		).then(
			(data) => {
				if (data && data.length > 0) {
					mapWinnersBracketsByLeagueID.set(leagueID, data)

					if (index+1 < arrPastSeasonsByLeagueID.length)
						this.fetchWinnersBracket(arrPastSeasonsByLeagueID, index+1, mapWinnersBracketsByLeagueID)
					else
						new Promise(
							(resolve) => {
								this.setState({mapWinnersBracketsByLeagueID: mapWinnersBracketsByLeagueID})
								resolve(true)
							}
						).then(() => {})
				}
				else {
					this.setState({mapWinnersBracketsByLeagueID: null})
					this.props.handlerError("Empty result for '" + URL + "'")
				}
    	}
		).catch(
			(error) => {
				console.error('Error:', error)
				this.setState({mapWinnersBracketsByLeagueID: null})
				this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		)
	}

	httpGetLeagueRosters(arrPastSeasonsByLeagueID) {
		this.fetchLeagueRosters(arrPastSeasonsByLeagueID, 0, null)
	}

  fetchLeagueRosters(arrPastSeasonsByLeagueID, index, mapOfMapLeagueRostersByLeagueID) {
    var URL = "https://api.sleeper.app/v1/league/" + arrPastSeasonsByLeagueID[index].leagueID + "/rosters"

		if (mapOfMapLeagueRostersByLeagueID === null)
			mapOfMapLeagueRostersByLeagueID = new Map()

    fetch(
			URL, {
				method: 'GET',
			}
		)
    .then(
			(resp) => {
				return resp.json()
			}
		)
    .then(
			(data) => {
				if (data && data.length > 0) {
					var map = new Map()
					data.forEach(
						(item) => {
							map.set(item.owner_id, item)
						}
					)
					mapOfMapLeagueRostersByLeagueID.set(arrPastSeasonsByLeagueID[index].leagueID, map)

					if (index+1 < arrPastSeasonsByLeagueID.length)
						this.fetchLeagueRosters(arrPastSeasonsByLeagueID, index+1, mapOfMapLeagueRostersByLeagueID)
					else
						new Promise(
							(resolve) => {
								this.setState({mapOfMapLeagueRostersByLeagueID: mapOfMapLeagueRostersByLeagueID})
								resolve(true)
							}
						).then(() => {})
				}
				else {
					this.setState({mapOfMapLeagueRostersByLeagueID: null})
					this.props.handlerError("Empty result for '" + URL + "'")
				}
			}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.setState({mapOfMapLeagueRostersByLeagueID: null})
				this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		)
  }

  render() {
    return (
      <div id="PastSeasons" ref={this.refPastSeasons}>
        <ul class="PastSeasons_wrapper">
          <li class="PastSeasons_cardContainer">
            <PastStandings
							archivedSeasons={this.state.archivedSeasons}
              arrPastSeasonsByLeagueID={this.props.arrPastSeasonsByLeagueID}
              mapWinnersBracketsByLeagueID={this.state.mapWinnersBracketsByLeagueID}
              mapLeagueRosters={this.props.mapLeagueRosters}
              mapLeagueUsers={this.props.mapLeagueUsers}
              handlerError={this.props.handlerError.bind(this)}
            />
          </li>
          <li class="PastSeasons_cardContainer">
            <PastStats
							archivedSeasons={this.state.archivedSeasons}
              arrPastSeasonsByLeagueID={this.props.arrPastSeasonsByLeagueID}
              mapWinnersBracketsByLeagueID={this.state.mapWinnersBracketsByLeagueID}
              mapOfMapLeagueRostersByLeagueID={this.state.mapOfMapLeagueRostersByLeagueID}
              mapLeagueRosters={this.props.mapLeagueRosters}
              mapLeagueUsers={this.props.mapLeagueUsers}
              handlerError={this.props.handlerError.bind(this)}
            />
          </li>

					<li class="PastSeasons_columns_wrapper">
						<ul class="PastSeasons_columns_ul two">
							<li class="PastSeasons_cardContainer two">
								<PastChampionships
									archivedSeasons={this.state.archivedSeasons}
									arrPastSeasonsByLeagueID={this.props.arrPastSeasonsByLeagueID}
									mapWinnersBracketsByLeagueID={this.state.mapWinnersBracketsByLeagueID}
									mapLeagueRosters={this.props.mapLeagueRosters}
									mapLeagueUsers={this.props.mapLeagueUsers}
									handlerError={this.props.handlerError.bind(this)}
								/>
							</li>
						</ul>
						<ul class="PastSeasons_columns_ul two">
							<li class="PastSeasons_cardContainer two">
								<PastRegSeasonWins
									archivedSeasons={this.state.archivedSeasons}
									arrPastSeasonsByLeagueID={this.props.arrPastSeasonsByLeagueID}
									mapOfMapLeagueRostersByLeagueID={this.state.mapOfMapLeagueRostersByLeagueID}
									mapLeagueUsers={this.props.mapLeagueUsers}
									handlerError={this.props.handlerError.bind(this)}
								/>
							</li>
							<li class="PastSeasons_cardContainer two">
								<PastPFLeaders
									archivedSeasons={this.state.archivedSeasons}
									arrPastSeasonsByLeagueID={this.props.arrPastSeasonsByLeagueID}
									mapOfMapLeagueRostersByLeagueID={this.state.mapOfMapLeagueRostersByLeagueID}
									mapLeagueUsers={this.props.mapLeagueUsers}
									handlerError={this.props.handlerError.bind(this)}
								/>
							</li>
						</ul>
						<ul class="PastSeasons_columns_ul two">
							<li class="PastSeasons_cardContainer two">
								<PastTop3
									archivedSeasons={this.state.archivedSeasons}
									arrPastSeasonsByLeagueID={this.props.arrPastSeasonsByLeagueID}
									mapWinnersBracketsByLeagueID={this.state.mapWinnersBracketsByLeagueID}
									mapLeagueRosters={this.props.mapLeagueRosters}
									mapLeagueUsers={this.props.mapLeagueUsers}
									handlerError={this.props.handlerError.bind(this)}
								/>
							</li>
						</ul>
					</li>
        </ul>
      </div>
    )
  }
}