import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './Matchup.css'

export default class Matchup extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			aWidth: 0,
			bWidth: 0,
		}

		this.refA = React.createRef();
		this.refB = React.createRef();
  }

	componentDidMount() {
		this.setState({
			aWidth: this.refA.current.offsetWidth,
			bWidth: this.refB.current.offsetWidth,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props !== prevProps) {
			if (
				this.refA.current.offsetWidth !== this.state.aWidth ||
				this.refB.current.offsetWidth !== this.state.bWidth
			)
				new Promise(
					(resolve) => {
						this.setState({
							aWidth: this.refA.current.offsetWidth,
							bWidth: this.refB.current.offsetWidth,
						})
						resolve(true)
					}
				).then(() => {})
		}
	}

	getIcons(ownerID, matchup, mapLeagueRosters, scoringLeaderOwnerIDs, scoringLoserOwnerIDs, isCurrentWeek, left) {
		if (isCurrentWeek)
			return
		
		var max = {
			rosterID: [],
			points: null,
		}

		matchup.forEach(
			(v, k) => {
				if (max.points === null || v.points > max.points)
					max = {
						rosterID: [v.roster_id],
						points: v.points,
					}
				else if (v.points === max.points)
					max = {
						rosterID: max.rosterID.push(v.roster_id),
						points: v.points,
					}
			}
		)

		var icons = []
		
		if (left)
			for (const id of scoringLeaderOwnerIDs) { // highest scorer
				if (id === ownerID)
					icons.push("😏")
			}

		if (max.rosterID.length === 2) // tie
			icons.push("🤨")
		else if (max.rosterID.length === 1) // win
			mapLeagueRosters.forEach(
				(roster) => {
					if (roster.roster_id === max.rosterID[0] && roster.owner_id === ownerID) {
						icons.push("🏆")
						return
					}
				}
			)
		
		if (!left)
			for (const id of scoringLeaderOwnerIDs) { // highest scorer
				if (id === ownerID)
					icons.push("😏")
			}
		
		for (const id of scoringLoserOwnerIDs) { // lowest scorer
			if (id === ownerID)
				icons.push("💩")
		}
		
		return icons
	}

	getStyleTeamWrapper(aORb) {
		if (aORb === "a")
			return {width: 'calc(100% - ' + this.state.aWidth + 'px)'}
		else if (aORb === "b")
			return {width: 'calc(100% - ' + this.state.bWidth + 'px)'}
	}

	html() {
		var matchup = this.props.matchup
		var mapLeagueRosters = this.props.mapLeagueRosters
		var mapLeagueUsers = this.props.mapLeagueUsers
		var scoringLeaderOwnerIDs = this.props.scoringLeaderOwnerIDs
		var scoringLoserOwnerIDs = this.props.scoringLoserOwnerIDs
		var isCurrentWeek = this.props.isCurrentWeek

		var rosterID_A = matchup.get("A").roster_id
		var rosterID_B = matchup.get("B").roster_id
		var ownerID_A = null
		var ownerID_B = null
		mapLeagueRosters.forEach(
			(roster, owner_id) => {
				if (roster.roster_id === rosterID_A)
					ownerID_A = owner_id
				else if (roster.roster_id === rosterID_B)
					ownerID_B = owner_id
				if (ownerID_A !== null && ownerID_B !== null)
					return
			}
		)

		var mapRecords = fnSleeper.getRegularSeasonRecordsByOwnerID(mapLeagueRosters)

		var html = []

		html.push(
			<React.Fragment>
				<div class="Matchup_cellWrapper">
					<div class="Matchup_cell">
						<div class="Matchup_team_wrapper" style={this.getStyleTeamWrapper("a")}>
							<object class="Matchup_avatar" data={fnSleeper.getUserAvatar(ownerID_A, mapLeagueUsers)} type="image/png" />
							<div class="Matchup_team_text_wrapper">
								<div class="Matchup_team_text name">{fnSleeper.getTeamName(ownerID_A, mapLeagueUsers)}</div>
								<div class="Matchup_team_record_row">
									<object class="Matchup_avatar row" data={fnSleeper.getUserAvatar(ownerID_A, mapLeagueUsers)} type="image/png" />
									<div class="Matchup_team_text record">{mapRecords.has(ownerID_A) ? fnSleeper.formatRecord(mapRecords.get(ownerID_A).record) : null}</div>
								</div>
							</div>
						</div>
	
						<div class="Matchup_points_wrapper">
							<div class="Matchup_points" ref={this.refA}>{fnSleeper.formatPoints(matchup.get("A").points)}</div>
							<div class="Matchup_points_icons">{this.getIcons(ownerID_A, matchup, mapLeagueRosters, scoringLeaderOwnerIDs, scoringLoserOwnerIDs, isCurrentWeek, true)}</div>
						</div>
					</div>
				</div>
	
				<div class="Matchup_vs">vs</div>
	
				<div class="Matchup_cellWrapper right">
					<div class="Matchup_cell">
						<div class="Matchup_points_wrapper right">
							<div class="Matchup_points right" ref={this.refB}>{fnSleeper.formatPoints(matchup.get("B").points)}</div>
							<div class="Matchup_points_icons right">{this.getIcons(ownerID_B, matchup, mapLeagueRosters, scoringLeaderOwnerIDs, scoringLoserOwnerIDs, isCurrentWeek, false)}</div>
						</div>
	
						<div class="Matchup_team_wrapper right" style={this.getStyleTeamWrapper("b")}>
							<div class="Matchup_team_text_wrapper right">
								<div class="Matchup_team_text name right">{fnSleeper.getTeamName(ownerID_B, mapLeagueUsers)}</div>
								<div class="Matchup_team_record_row right">
									<div class="Matchup_team_text record right">{mapRecords.has(ownerID_B) ? fnSleeper.formatRecord(mapRecords.get(ownerID_B).record) : null}</div>
									<object class="Matchup_avatar row" data={fnSleeper.getUserAvatar(ownerID_B, mapLeagueUsers)} type="image/png" />
								</div>
							</div>
							<object class="Matchup_avatar right" data={fnSleeper.getUserAvatar(ownerID_B, mapLeagueUsers)} type="image/png" />
						</div>
					</div>
				</div>
			</React.Fragment>
		)

		return html
	}

  render() {
    return (
      <li class="Matchup">
				{this.html()}
      </li>
    )
  }
}