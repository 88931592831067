import React from 'react'

import './Alert.css'

export default class Alert extends React.Component {
  constructor(props) {
		super(props)
  }

  getClass() {
    if (this.props.hidden)
      return "hidden"
    return "Alert"
  }

  render() {
    return (
      <div class={this.getClass()}>
        {this.props.children}
      </div>
    )
  }
}