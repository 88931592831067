////////////////////////////////////////////////////////////////
// General
////////////////////////////////////////////////////////////////

export function getParamVal(param) {
  var url = new URL(window.location.href);
  return url.searchParams.get(param);
}

export function getPath() {
  var url = new URL(window.location.href);
  return url.pathname;
}

export function getPathRemoveParams(url) {
  return String(url).split('?')[0]
}

////////////////////////////////////////////////////////////////
// Forms
////////////////////////////////////////////////////////////////

export function serializeArray(form) {
  var field, l, s = []
  if (typeof form == 'object' && form.nodeName == "FORM") {
    var len = form.elements.length
    for (var i=0; i<len; i++) {
      field = form.elements[i]
      if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' && field.type != 'submit' && field.type != 'button') {
        if (field.type == 'select-multiple') {
          l = form.elements[i].options.length 
          for (var j=0; j<l; j++) {
            if(field.options[j].selected)
              s[s.length] = { name: field.name, value: field.options[j].value }
          }
        } else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {
          s[s.length] = { name: field.name, value: field.value }
        }
      }
    }
  }
  return s
}

////////////////////////////////////////////////////////////////
// Arrays
////////////////////////////////////////////////////////////////

export function sortByKey(array, key) {
	return array.sort(function(a, b) {
    var x = a[key]
    var y = b[key]
		return ((x < y) ? -1 : ((x > y) ? 1 : 0))
	})
}

export function findIndex(items, match) {
  for (var i=0; i<items.length; ++i) {
    if (items[i].id == match)
      return i
  }
  return -1
}

////////////////////////////////////////////////////////////////
// Maps
////////////////////////////////////////////////////////////////

export function createMap(keys, values) {
  var map = new Map()
  for (var i=0; i<keys.length; i++)
    map.set(keys[i], values[i])
  return map
}

////////////////////////////////////////////////////////////////
// Phone numbers
////////////////////////////////////////////////////////////////

export function trimPhone(string) {
  return string.replace(/[-+().\s]/g, '')
}

////////////////////////////////////////////////////////////////
// Time
////////////////////////////////////////////////////////////////

export function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}