import * as fnSleeper from '../../utils/functions/sleeper.js'

import React from 'react'

import './Home.css'

import CurrentMatchups from './modules/CurrentMatchups.jsx'
import WeeklySummaries from './modules/WeeklySummaries.jsx'
import Standings from './modules/Standings.jsx'
import SeasonSummary from './modules/SeasonSummary.jsx'
import WallOfShame from './modules/WallOfShame.jsx'

export default class Home extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			mapScoringLeaderByWeek: null,
			mapScoringLoserByWeek: null,
			mapMatchupsByWeek: null,
			mapRecordsByOwnerID: null,
			arrRegularSeasonStandings: null,
		}

		this.refHome = React.createRef()
  }

	componentDidMount() {
		this.refHome.current.scrollTo(0, 0)

		if (this.props.leagueID && this.props.week && this.props.playoffWeekStart && this.props.mapLeagueRosters)
			new Promise(
				(resolve) => {
					this.httpGetMatchups(this.props.leagueID, this.props.week, this.props.playoffWeekStart, this.props.mapLeagueRosters)
					resolve(true)
				}
			).then(() => {})
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.leagueID !== prevProps.leagueID ||
			this.props.week !== prevProps.week ||
			this.props.playoffWeekStart !== prevProps.playoffWeekStart ||
			this.props.mapLeagueRosters !== prevProps.mapLeagueRosters
		)
			this.httpGetMatchups(this.props.leagueID, this.props.week, this.props.playoffWeekStart, this.props.mapLeagueRosters)
		
		if (this.props.mapLeagueRosters !== prevProps.mapLeagueRosters)
			new Promise(
				(resolve) => {
					if (this.props.mapLeagueRosters)
						this.setState({
							mapRecordsByOwnerID: fnSleeper.getRegularSeasonRecordsByOwnerID(this.props.mapLeagueRosters),
							arrRegularSeasonStandings: fnSleeper.getRegularSeasonStandings(this.props.mapLeagueRosters),
						})
					else
						this.setState({
							mapRecordsByOwnerID: null,
							arrRegularSeasonStandings: null,
						})
					resolve(true)
				}
			).then(() => {})
	}

	//////////////////////////////////////////////////////////////////////////////////////

	httpGetMatchups(leagueID, currentWeek, playoffWeekStart, mapLeagueRosters) {
		if (
			this.props.leagueID &&
			this.props.week &&
			this.props.playoffWeekStart &&
			this.props.mapLeagueRosters
		)
			this.fetchMatchups(leagueID, 1, currentWeek, playoffWeekStart, null, mapLeagueRosters)
		else
			this.setState({
				mapMatchupsByWeek: null
			})
	}

	fetchMatchups(leagueID, week, currentWeek, playoffWeekStart, mapMatchupsByWeek, mapLeagueRosters) {
    var URL = "https://api.sleeper.app/v1/league/" + leagueID + "/matchups/" + week

		if (mapMatchupsByWeek == null)
			mapMatchupsByWeek = new Map()

		fetch(
			URL, {
				method: 'GET',
			}
		)
		.then(
			(resp) => {
				return resp.json()
			}
		)
		.then(
			(data) => {
				mapMatchupsByWeek.set(week, data)

				var nextWeek = parseInt(week) + 1
				if (data && data.length > 0 && nextWeek <= currentWeek && nextWeek < playoffWeekStart) {
					this.fetchMatchups(leagueID, nextWeek, currentWeek, playoffWeekStart, mapMatchupsByWeek, mapLeagueRosters)
				}
				else {
					new Promise(
						(resolve) => {
							this.setState({mapMatchupsByWeek: mapMatchupsByWeek})
							resolve(true)
						}
					).then(
						() => {
							this.updateScoringLeadersByWeek(this.state.mapMatchupsByWeek, mapLeagueRosters)
						}
					).then(() => {})
				}
			},
		).then(
			() => {}
		)
		.catch(
			(error) => {
				console.error('Error:', error)
				this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		)
  }

	updateScoringLeadersByWeek(mapMatchupsByWeek, mapLeagueRosters) {
		var mapMax = new Map()
		var mapMin = new Map()
		
		mapMatchupsByWeek.forEach(
			(matchup, week) => {
				var maxPoints = {rosterIDs: [], points: 0.0}
				var minPoints = {rosterIDs: [], points: 0.0}

				for (const team of matchup) {
					if (team.points > maxPoints.points)
						maxPoints = {
							rosterIDs: [team.roster_id],
							points: team.points
						}

					if (team.points > 0 && (minPoints.points === 0.0 || team.points < minPoints.points))
						minPoints = {
							rosterIDs: [team.roster_id],
							points: team.points
						}
				}

				for (const team of matchup) {
					if (team.points > 0 && team.points === maxPoints.points && team.roster_id !== maxPoints.rosterIDs[0])
						maxPoints.rosterIDs.push(team.roster_id)
					if (team.points > 0 && team.points === minPoints.points && team.roster_id !== minPoints.rosterIDs[0])
						minPoints.rosterIDs.push(team.roster_id)
				}
				
				var maxPointsOwnerIDs = []
				var minPointsOwnerIDs = []
				mapLeagueRosters.forEach(
					(roster, _ownerID) => {
						for (const rosterID of maxPoints.rosterIDs)
							if (rosterID === roster.roster_id)
								maxPointsOwnerIDs.push(_ownerID)
						for (const rosterID of minPoints.rosterIDs)
							if (rosterID === roster.roster_id)
								minPointsOwnerIDs.push(_ownerID)
					}
				)
				
				mapMax.set(week, {
					ownerIDs: maxPointsOwnerIDs,
					points: maxPoints.points,
				})
				mapMin.set(week, {
					ownerIDs: minPointsOwnerIDs,
					points: minPoints.points,
				})
			}
		)

		new Promise(
			(resolve) => {
				this.setState({
					mapScoringLeaderByWeek: mapMax,
					mapScoringLoserByWeek: mapMin,
			})
				resolve(true)
			}
		).then(() => {})
	}

	//////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div id="Home" ref={this.refHome}>
				<ul class="Home_wrapper">
					{
						this.props.week < this.props.playoffWeekStart ?
						<li class="Home_li">
							<CurrentMatchups
								leagueID={this.props.leagueID}
								playoffWeekStart={this.props.playoffWeekStart}
								season={this.props.season}
								week={this.props.week}
								mapLeagueRosters={this.props.mapLeagueRosters}
								mapLeagueUsers={this.props.mapLeagueUsers}
								mapScoringLeaderByWeek={this.state.mapScoringLeaderByWeek}
								mapScoringLoserByWeek={this.state.mapScoringLoserByWeek}
								mapMatchupsByWeek={this.state.mapMatchupsByWeek}
							/>
						</li>
						: null
					}
					<li class="Home_li">
						<WeeklySummaries
							leagueID={this.props.leagueID}
							playoffWeekStart={this.props.playoffWeekStart}
							season={this.props.season}
							week={this.props.week}
							mapLeagueRosters={this.props.mapLeagueRosters}
							mapLeagueUsers={this.props.mapLeagueUsers}
							mapScoringLeaderByWeek={this.state.mapScoringLeaderByWeek}
							mapScoringLoserByWeek={this.state.mapScoringLoserByWeek}
							mapMatchupsByWeek={this.state.mapMatchupsByWeek}
						/>
					</li>
					<li class="Home_li">
						<Standings
							season={this.props.season}
							week={this.props.week}
							playoffWeekStart={this.props.playoffWeekStart}
							mapLeagueRosters={this.props.mapLeagueRosters}
							mapLeagueUsers={this.props.mapLeagueUsers}
							mapRecordsByOwnerID={this.state.mapRecordsByOwnerID}
							arrRegularSeasonStandings={this.state.arrRegularSeasonStandings}
						/>
					</li>
					<li class="Home_li">
						<SeasonSummary
							season={this.props.season}
							week={this.props.week}
							playoffWeekStart={this.props.playoffWeekStart}
							mapLeagueRosters={this.props.mapLeagueRosters}
							mapLeagueUsers={this.props.mapLeagueUsers}
							mapRecordsByOwnerID={this.state.mapRecordsByOwnerID}
							arrRegularSeasonStandings={this.state.arrRegularSeasonStandings}
							mapScoringLeaderByWeek={this.state.mapScoringLeaderByWeek}
							mapScoringLoserByWeek={this.state.mapScoringLoserByWeek}
						/>
					</li>
					<li class="Home_li">
						<WallOfShame
							season={this.props.season}
							week={this.props.week}
							playoffWeekStart={this.props.playoffWeekStart}
							players={this.props.players}
							mapLeagueRosters={this.props.mapLeagueRosters}
							mapLeagueUsers={this.props.mapLeagueUsers}
							mapMatchupsByWeek={this.state.mapMatchupsByWeek}
							handlerError={this.props.handlerError.bind(this)}
						/>
					</li>
				</ul>
      </div>
    )
  }
}