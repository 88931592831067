import * as fnSleeper from '../../../utils/functions/sleeper.js'
import * as fnSort from '../../../utils/functions/sort.js'

import React from 'react'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class PastChampionships extends React.Component {
  constructor(props) {
    super(props)
  }

	getFixedData(quantities) {
		return {
			headers: [
				{
					content: "Qty",
					numeric: true,
					sort: true
				}
			],
			rows: this.getFixedRows(quantities)
		}
	}

	getFixedRows(quantities) {
		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []
			data.push(
				{
					content: <div>{quantities[i].quantity}</div>,
					numeric: true,
					value: quantities[i].quantity
				}
			)
			rows.push(data)
		}

		return rows
	}

	getData(quantities, mapLeagueUsers) {
		return {
			headers: [
				{
					content: "Team"
				}
			],
			rows: this.getRows(quantities, mapLeagueUsers)
		}
	}

	getRows(quantities, mapLeagueUsers) {
		var archivedSeasons = this.props.archivedSeasons

		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []

			if (mapLeagueUsers && mapLeagueUsers.has(quantities[i].ownerID))
				data.push(
					{
						content: <User ownerID={quantities[i].ownerID} mapLeagueUsers={mapLeagueUsers} />
					}
				)
			else if (archivedSeasons && archivedSeasons.mapRosterSlotsByRosterID.has(quantities[i].ownerID)) {
				var teamName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).teamName
				var displayName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).displayName
				var avatar = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"@" + displayName}
							/>
						)
					}
				)
			}
			
			rows.push(data)
		}


		return rows
	}

	body() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		var mapWinnersBracketsByLeagueID = this.props.mapWinnersBracketsByLeagueID
		var mapLeagueRosters = this.props.mapLeagueRosters
		var mapLeagueUsers = this.props.mapLeagueUsers

		var archivedSeasons = this.props.archivedSeasons

		if (arrPastSeasonsByLeagueID === null || mapWinnersBracketsByLeagueID === null || mapLeagueRosters === null || mapLeagueUsers === null)
			return
		
		var mapShipsByOwnerID = new Map()

		if (arrPastSeasonsByLeagueID)
			for (const obj of arrPastSeasonsByLeagueID) {
				var winnersBracket = mapWinnersBracketsByLeagueID.get(obj.leagueID)
				var arrStandings = fnSleeper.getBracketFinalStandings(winnersBracket)
				var ownerID = fnSleeper.getOwnerIDByRosterID(arrStandings[0], mapLeagueRosters)
				
				var quantity = 1
				if (mapShipsByOwnerID.has(ownerID))
					quantity = mapShipsByOwnerID.get(ownerID) + 1
				mapShipsByOwnerID.set(ownerID, quantity)
			}
		
		if (archivedSeasons) {
			archivedSeasons.mapStandingsByYear.forEach(
				(standings, year) => {
					var rosterID = standings.split(",")[0]
					if (archivedSeasons.mapRosterSlotsByRosterID.has(rosterID)) {
						var ownerID = archivedSeasons.mapRosterSlotsByRosterID.get(rosterID).ownerID
						if (ownerID === null)
							ownerID = rosterID
						
						var quantity = 1
						if (mapShipsByOwnerID.has(ownerID))
							quantity = mapShipsByOwnerID.get(ownerID) + 1
						mapShipsByOwnerID.set(ownerID, quantity)
					}
				}
			)
		}

		var quantities = []
		mapShipsByOwnerID.forEach(
			(v, k) => {
				quantities.push(v)
			}
		)
		
		quantities = fnSort.sortInt(quantities).reverse()
		
		for (var i = 0; i < quantities.length; i++) {
			var ownerID = null
			for (const [k, v] of mapShipsByOwnerID) {
				if (v === quantities[i]) {
					mapShipsByOwnerID.delete(k)
					ownerID = k
					break
				}
			}

			quantities[i] = {
				quantity: quantities[i],
				ownerID: ownerID,
			}
		}
		
		var html = []
		html.push(
			<DataTables
				noBorder
				fixedData={this.getFixedData(quantities)}
				data={this.getData(quantities, mapLeagueUsers)}
				initSortOrder="0d"
			/>
		)
		return html
	}

  render() {
    return (
			<Card
				clear
				primaryText="Championships"
				body={
					<Card
						bodyNoPadding
						body={this.body()}
					/>
				}
			/>
    )
  }
}