import * as fn from '../../../utils/functions/sleeper.js'

import React from 'react'

import './WeeklySummaries.css'

import Card from '../../../components/card/Card/Card.jsx'
import WeeklySummary from './WeeklySummary.jsx'

export default class WeeklySummaries extends React.Component {
  constructor(props) {
    super(props)
  }

	html_li() {
		var week = this.props.week
		if (week === null)
			return
		if (week > this.props.playoffWeekStart)
			week = this.props.playoffWeekStart
		
		var html = []
		for (var i = week-1; i >= 1; i--)
			html.push(
				<WeeklySummary
					week={i}
					mapScoringLeaderByWeek={this.props.mapScoringLeaderByWeek}
					mapScoringLoserByWeek={this.props.mapScoringLoserByWeek}
					mapMatchupsByWeek={this.props.mapMatchupsByWeek}
					mapLeagueRosters={this.props.mapLeagueRosters}
					mapLeagueUsers={this.props.mapLeagueUsers}
					isCurrentWeek={false}
				/>
			)
		return html
	}

  render() {
    return (
      <div id="WeeklySummaries">
				<Card
					clear
					primaryText="Weekly summaries"
					secondaryText={this.props.season ? this.props.season + " Regular season" : "Regular season"}
					body={
						<Card
							bodyNoPadding
							body={
								<ul class="WeeklySummaries_ul">
									{this.html_li()}
								</ul>
							}
						/>
					}
				/>
      </div>
    )
  }
}