import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './Roster.css'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import Player from '../../Sleeper/components/Player.jsx'

export default class Roster extends React.Component {
  constructor(props) {
    super(props)
  }

	getPricesToKeep(effPrices, playerID) {
		var newPrice = effPrices.get(playerID)
		newPrice = Math.max(parseInt(newPrice)+5, parseInt(Math.round(newPrice*1.25+0.5)))
		return newPrice
	}

	getPriceDeltas(effPrices, playerID) {
		var oldPrice = effPrices.get(playerID)
		var newPrice = parseInt(oldPrice) + 5
		newPrice = Math.max(newPrice, parseInt(Math.round(oldPrice*1.25+0.5)))
		return "+" + (newPrice - parseInt(oldPrice)).toString()
	}

  html() {
		var userID = null
		var user = null
		var teamName = null
		
		if (this.props.roster && this.props.roster !== null) {
			userID = this.props.roster.owner_id

			if (this.props.mapLeagueUsers) {
				user = this.props.mapLeagueUsers.get(userID)
				teamName = "Team " + user.display_name
				if (user.metadata.team_name)
					teamName = user.metadata.team_name
			}
		}
		
		var html = []
		
		html.push(
			<li class={this.props.isKeeper ? "Roster keeper" : "Roster"} id={userID ? userID : null}>
				<Card
					clear
					icon={userID && this.props.mapLeagueUsers ? fnSleeper.getUserAvatar(userID, this.props.mapLeagueUsers) : null}
					type="image/png"
					primaryText={teamName ? teamName : null}
					secondaryText={user ? "@" + user.display_name : null}
					body={
						<Card
							bodyNoPadding
							body={
								<DataTables
									noBorder
									fixedData={this.getFixedData()}
									data={this.getData()}
									initSortOrder="1a"
								/>
							}
						/>
					}
				/>
			</li>
		)

		return html
  }

	getFixedData() {
		var obj = {
			headers: [
				{
					content: <div class="Roster_th" style={{paddingLeft: '38px'}}>Player</div>,
					sort: true,
				}
			],
			rows: this.getFixedRows()
		}
		return obj
	}

	getFixedRows() {
		var roster = this.props.roster
		var players = this.props.players
		if (roster === null || players === null)
			return
		
		var rows = []
		
		roster.players.forEach(
			(playerID) => {
				rows.push(
					[
						{
							content: this.htmlPlayer(playerID),
							value: fnSleeper.getPlayerName(playerID, players)
						}
					]
				)
			}
		)

		return rows
	}

	htmlPlayer(playerID) {
		var players = this.props.players
		if (players === null)
			return
		
		var output = []

		output.push(
			<div class="Roster_player_wrapper">
				<Player playerID={playerID} players={players} />
			</div>
		)

		return output
	}

	getData() {
		var headers = [
			{
				content: <div class="Roster_th">Pos</div>,
				sort: true
			},
			{
				content: <div class="Roster_th">Ht</div>,
				numeric: true,
				sort: true
			},
			{
				content: <div class="Roster_th">Wt</div>,
				numeric: true,
				sort: true
			},
			{
				content: <div class="Roster_th">Age</div>,
				numeric: true,
				sort: true
			},
			{
				content: <div class="Roster_th">Exp</div>,
				numeric: true,
				sort: true
			},
		]
		if (this.props.isKeeper) {
			headers.push(
				{
					content: <div class="Roster_th">Price</div>,
					numeric: true,
					sort: true
				}
			)
			headers.push(
				{
					content: <div class="Roster_th">Price<br/>to keep</div>,
					numeric: true,
					sort: true
				}
			)
			headers.push(
				{
					content: <div class="Roster_th">Price<br/>chg</div>,
					numeric: true,
					sort: true
				}
			)
			headers.push(
				{
					content: <div class="Roster_th">Yrs<br/>left</div>,
					numeric: true,
					sort: true
				}
			)
		}

		var obj = {
			headers: headers,
			rows: this.getRows()
		}
		return obj
	}

	getRows() {
		var roster = this.props.roster
		var players = this.props.players
		var isKeeper = this.props.isKeeper

		if (roster === null || players === null)
			return
		
		var effPrices = this.props.effPrices
		var yearsLeft = this.props.yearsLeft

		var rows = []
		
		roster.players.forEach(
			(playerID) => {
				var playerInfo = players.get(parseInt(playerID))
				var playerYearsLeft = null
				if (yearsLeft)
					playerYearsLeft = yearsLeft.get(playerID)
				
				var row = []
				
				const pos = fnSleeper.getPlayerPosition(playerID, players)
				row.push(
					{
						content: <div class="Roster_td">{pos}</div>,
						value: pos
					}
				)
				row.push(
					{
						content: <div class="Roster_td">{fnSleeper.formatPlayerHeight(playerInfo.height)}</div>,
						numeric: true,
						value: parseInt(playerInfo.height)
					}
				)
				row.push(
					{
						content: <div class="Roster_td">{playerInfo.weight} lb</div>,
						numeric: true,
						value: parseInt(playerInfo.weight)
					}
				)
				row.push(
					{
						content: <div class="Roster_td">{playerInfo.age}</div>,
						numeric: true,
						value: parseInt(playerInfo.age)
					}
				)
				row.push(
					{
						content: (
							<div class="Roster_td numeric">
								{
									parseInt(playerInfo.years_exp) === 0 ?
									"R" :
									playerInfo.years_exp
								}
							</div>
						),
						value: parseInt(playerInfo.years_exp)
					}
				)

				if (isKeeper) {
					row.push(
						{
							content: (
								<div class="Roster_td currency">
									<span class="Roster_currency">$</span>
									<span>
										{effPrices ? effPrices.get(playerID) : "-"}
									</span>
								</div>
							),
							value: effPrices ? parseInt(effPrices.get(playerID)) : 0
						}
					)

					const priceToKeep = effPrices ? parseInt(this.getPricesToKeep(effPrices, playerID)) : 0
					row.push(
						{
							content: (
								<div class="Roster_td currency">
									<span class="Roster_currency">$</span>
									<span>
										{effPrices ? priceToKeep : "-"}
									</span>
								</div>
							),
							value: priceToKeep
						}
					)

					const priceDelta = effPrices ? parseInt(this.getPriceDeltas(effPrices, playerID)) : 0
					row.push(
						{
							content: (
								<div class="Roster_td numeric">
									{effPrices ? priceDelta : "-"}
								</div>
							),
							value: priceDelta
						}
					)
					row.push(
						{
							content: (
								<div class="Roster_td_yearsLeftWrapper1">
									<div class="Roster_td_yearsLeftWrapper2">
										<div class={playerYearsLeft !== null ? this.getClassYearsLeft(playerYearsLeft) : "Roster_td_yearsLeftWrapper3 clear"}>
											<div class="Roster_td yearsLeft">
												{playerYearsLeft !== null ? playerYearsLeft : "-"}
											</div>
										</div>
									</div>
								</div>
							),
							value: playerYearsLeft !== null ? playerYearsLeft : 0
						}
					)
				}

				rows.push(row)
			}
		)

		return rows
	}

	getClassYearsLeft(playerYearsLeft) {
		var output = ["Roster_td_yearsLeftWrapper3"]
		
		switch(playerYearsLeft) {
			case 0:
				output.push("zero")
				break
			case 1:
				output.push("one")
				break
			case 2:
				output.push("two")
				break
			case 3:
				output.push("three")
				break
		}

		return output.join(" ")
	}

  render() {
		return (
			this.html()
    )
  }
}