import * as fn from '../../utils/functions/general.js'
import * as fnString from '../../utils/functions/string.js'

import React from 'react'
import SmoothScroll from 'smooth-scroll'
import 'isomorphic-fetch'

import './LogInForm.css'

import Alert from '../../components/alert/Alert/Alert.jsx'
import Input from '../../components/field/Input/Input.jsx'
import Button from '../../components/button/Button/Button.jsx'

var scroll = new SmoothScroll('a[href*="#"]', { speed: 500 });

export default class LogInForm extends React.Component {
  constructor(props) {
		super(props)
		
		this.state = {
      triedSubmit: false,
      buttonEnabled: true,
      alertMessage: null,

      errorEmail: null,
      errorPassword: null,

      forgot: false,
      triedSubmitForgot: false,
      submitForgotStatus: "",
    }

    this.handleChange = this.handleChange.bind(this)
	}

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  
  handleChange(event) {
    const target = event.target
    const name = target.name

    switch(name) {
      case "email":
        this.updateAnyInvalid()
        break
      case "password":
        this.updateAnyInvalid()
        break
    }

    this.setState({ buttonEnabled: true }, () => this.updateAnyInvalid())
  }

  handleClickLogIn() {
    new Promise((resolve) => {
      this.setState({triedSubmit: true})
      resolve(true)
    })
    .then(() => {
      return this.updateAnyInvalid()
    })
    .then(() => {
      if (
        this.state.errorEmail ||
        this.state.errorPassword ||
        !this.state.buttonEnabled
      ) return false
      this.setState({buttonEnabled: false})
      return true
    })
    .then((resolve) => {
      if (resolve)
        this.submit()
      else
        this.scrollToError()
    })
  }

  handleClickForgot() {
    if (this.state.forgot)
      this.setState({forgot: false})
    else
      this.setState({forgot: true, submitForgotStatus: "Enter your email to receive instructions on how to reset your password."})
  }

  handleClickForgotSendEmail() {
    new Promise((resolve) => {
      this.setState({
        triedSubmitForgot: true,
        submitForgotStatus: "Sending email...",
      })
      resolve(true)
    })
    .then(() => {
      return this.updateEmailInvalid()
    })
    .then(() => {
      if (this.state.errorEmail || !this.state.buttonEnabled)
        return false
      this.setState({buttonEnabled: false})
      return true
    })
    .then((resolve) => {
      if (resolve)
        this.submitForgot()
      else
        this.scrollToError()
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  scrollToError() {
    var anchor = null

    if (this.state.errorEmail)
      anchor = document.getElementById("email")
    else if (this.state.errorPassword)
      anchor = document.getElementById("password")
    
    if (!anchor)
      return
		
		scroll.animateScroll(anchor);
    if (anchor.nodeName === "INPUT")
      anchor.focus()
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  
  updateAnyInvalid() {
    var emailEmpty = fnString.strEmpty(document.getElementById("email").value)
    var passwordEmpty = fnString.strEmpty(document.getElementById("password").value)

    var errorEmail = null
    var errorPassword = null

    if (this.state.triedSubmit && emailEmpty)
      errorEmail = "*Required"
    if (this.state.triedSubmit && passwordEmpty)
      errorPassword = "*Required"
    
    this.setState({
      errorEmail: errorEmail,
      errorPassword: errorPassword,
      alertMessage: null,
    })

    return true
  }

  updateEmailInvalid() {
    var emailEmpty = fnString.strEmpty(document.getElementById("email").value)

    var errorEmail = null

    if (this.state.triedSubmitForgot && emailEmpty)
      errorEmail = "*Required"
    
    this.setState({
      errorEmail: errorEmail,
      alertMessage: null,
    })

    return true
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  submit() {
    var formData = fn.serializeArray(document.getElementById('form'))
    
    var _this = this
    var URL = "/LogIn"

    fetch(URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify({formData}),
    })
    .then(function(response) {
      return response.json()
    })
    .then(function(data) {
      if (data.hasOwnProperty("error"))
        _this.setState({ alertMessage: data.error, buttonEnabled: true })
      else if (data.hasOwnProperty("errorEmail"))
        _this.setState({ errorEmail: data.errorEmail, buttonEnabled: true }, () => _this.scrollToError())
      else if (data.hasOwnProperty("errorPassword"))
        _this.setState({ errorPassword: data.errorPassword, buttonEnabled: true }, () => _this.scrollToError())
      else if (data.hasOwnProperty("success") && data.success === true) {
        var href = []
        href.push("/")
        if (fn.getParamVal("p"))
          href.push(fn.getParamVal("p"))
        href = href.join("")
        window.location.href = href
      }
      else
        _this.setState({ alertMessage: "Something went wrong... Please try again.", buttonEnabled: true })
    })
    .catch(function(error) {
      console.error('Error:', error)
      _this.setState({ alertMessage: "Something went wrong... Please refresh the page.", buttonEnabled: true })
    })
  }

  submitForgot() {
    var formData = fn.serializeArray(document.getElementById('form'))
    
    var _this = this
    var URL = "/Forgot"

    fetch(URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify({formData}),
    })
    .then(function(response) {
      return response.json()
    })
    .then(function(data) {
      if (data.hasOwnProperty("error"))
        _this.setState({ alertMessage: data.error, buttonEnabled: true })
      else if (data.hasOwnProperty("success") && data.success === true)
        _this.setState({ submitForgotStatus: "Password recovery email has been sent. Please check your inbox." })
      else
        _this.setState({ alertMessage: "Something went wrong... Please try again.", buttonEnabled: true })
    })
    .catch(function(error) {
      console.error('Error:', error)
      _this.setState({ alertMessage: "Something went wrong... Please refresh the page.", buttonEnabled: true })
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  hideAlert() { return !(this.state.triedSubmit && this.state.alertMessage != "" && this.state.alertMessage != null)}

  render() {
    return (
			<div id="LogInForm">

        <div style={{ fontSize: '20px', fontWeight: '400', letterSpacing: '0.25px', textAlign: 'center', marginBottom: '30px' }}>
          {this.state.forgot ? "Forgot password" : "Log in"}
        </div>

        <Alert hidden={this.hideAlert()}>{this.state.alertMessage}</Alert>

        <form id="form">

          <ul class="LogInForm_ul">
            <li class={this.state.forgot ? "LogInForm_li" : "hidden"}>
              <div class="LogInForm_subtextContainer">
                <div class="LogInForm_subtext">{this.state.submitForgotStatus}</div>
              </div>
            </li>
            <li class="LogInForm_li">
              <Input
                type="email"
                id="email"
                name="email"
                maxLength="255"
                onChange={this.handleChange}
                onEnter={this.state.forgot ? this.handleClickForgotSendEmail.bind(this) : this.handleClickLogIn.bind(this)}
                tabIndex="1"
                label="Email"
                errorText={this.state.errorEmail}
              />
            </li>
            <li class={this.state.forgot ? "hidden" : "LogInForm_li"}>
              <Input
                type="password"
                id="password"
                name="password"
                maxLength="255"
                onChange={this.handleChange}
                onEnter={this.handleClickLogIn.bind(this)}
                tabIndex="1"
                label="Password"
                errorText={this.state.errorPassword}
              />
            </li>
            <li class="LogInForm_li">
              <div style={{height: '40px', width: '100%'}}>
                <Button
                  fillHeight
                  fillWidth
                  raised
                  disabled={!this.state.buttonEnabled}
                  onClick={this.state.forgot ? this.handleClickForgotSendEmail.bind(this) : this.handleClickLogIn.bind(this)}
                  tabIndex="1"
                >{this.state.forgot ? "SUBMIT" : "LOG IN"}</Button>
              </div>
            </li>
            <li class="LogInForm_li">
              <div class="LogInForm_subtextContainer">
                <div class="LogInForm_subtext">
                  <a style={{cursor: 'pointer'}} onClick={this.handleClickForgot.bind(this)}>
                    {this.state.forgot ? "Back to log in" : "Forgot your password?"}
                  </a>
                </div>
              </div>
            </li>
          </ul>

        </form>

			</div>
    )
  }
}