import * as fnSleeper from '../../../utils/functions/sleeper.js'
import * as fnSort from '../../../utils/functions/sort.js'

import React from 'react'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class PastPFLeaders extends React.Component {
  constructor(props) {
    super(props)
  }

	getFixedData(quantities) {
		return {
			headers: [
				{
					content: "Qty",
					numeric: true,
					sort: true
				}
			],
			rows: this.getFixedRows(quantities)
		}
	}

	getFixedRows(quantities) {
		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []
			data.push(
				{
					content: <div>{quantities[i].quantity}</div>,
					numeric: true,
					value: quantities[i].quantity
				}
			)
			rows.push(data)
		}

		return rows
	}

	getData(quantities, mapLeagueUsers) {
		return {
			headers: [
				{
					content: "Team"
				}
			],
			rows: this.getRows(quantities, mapLeagueUsers)
		}
	}

	getRows(quantities, mapLeagueUsers) {
		var archivedSeasons = this.props.archivedSeasons

		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []

			if (mapLeagueUsers && mapLeagueUsers.has(quantities[i].ownerID))
				data.push(
					{
						content: <User ownerID={quantities[i].ownerID} mapLeagueUsers={mapLeagueUsers} />
					}
				)
			else if (archivedSeasons) {
				var teamName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).teamName
				var displayName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).displayName
				var avatar = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"@" + displayName}
							/>
						)
					}
				)
			}

			rows.push(data)
		}

		return rows
	}

	body() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		var mapOfMapLeagueRostersByLeagueID = this.props.mapOfMapLeagueRostersByLeagueID
		var mapLeagueUsers = this.props.mapLeagueUsers

		var archivedSeasons = this.props.archivedSeasons

		if (arrPastSeasonsByLeagueID === null || mapOfMapLeagueRostersByLeagueID === null || mapLeagueUsers === null)
			return
		
		var mapRegSeasonPFLeadersByOwnerID = new Map()

		if (arrPastSeasonsByLeagueID)
			for (const obj of arrPastSeasonsByLeagueID) {
				var mapRegularSeasonRecordsByOwnerID = fnSleeper.getRegularSeasonRecordsByOwnerID(mapOfMapLeagueRostersByLeagueID.get(obj.leagueID))

				var pfMax = {
					ownerID: 0,
					pf: 0
				}

				mapRegularSeasonRecordsByOwnerID.forEach(
					(v, k) => {
						if (v.pf > pfMax.pf) {
							pfMax.ownerID = k
							pfMax.pf = v.pf
						}
					}
				)

				var quantity = 1
				if (mapRegSeasonPFLeadersByOwnerID.has(pfMax.ownerID))
					quantity += mapRegSeasonPFLeadersByOwnerID.get(pfMax.ownerID)
				mapRegSeasonPFLeadersByOwnerID.set(pfMax.ownerID, quantity)
			}
		
		if (archivedSeasons) {
			archivedSeasons.mapTeamStatsByYear.forEach(
				(arrTeamStats, year) => {
					var pfMax = {
						ownerID: 0,
						pf: 0
					}

					for (const teamStats of arrTeamStats) {
						var pf = teamStats.pf.split(".")
						var pf_decimal = pf.length > 0 ? parseFloat(pf[1]) : 0
	
						var ownerID = teamStats.roster_id
						if (archivedSeasons.mapRosterSlotsByRosterID.has(teamStats.roster_id))
							ownerID = archivedSeasons.mapRosterSlotsByRosterID.get(teamStats.roster_id).ownerID
		
						pf = parseFloat(pf.toString() + "." + pf_decimal.toString())

						if (pf > pfMax.pf) {
							pfMax.ownerID = ownerID
							pfMax.pf = pf
						}
					}
					
					var quantity = 1
					if (mapRegSeasonPFLeadersByOwnerID.has(pfMax.ownerID))
						quantity += mapRegSeasonPFLeadersByOwnerID.get(pfMax.ownerID)
					mapRegSeasonPFLeadersByOwnerID.set(pfMax.ownerID, quantity)
				}
			)
		}

		var quantities = []
		mapRegSeasonPFLeadersByOwnerID.forEach(
			(v, k) => {
				quantities.push(v)
			}
		)
		
		quantities = fnSort.sortInt(quantities).reverse()
		
		for (var i = 0; i < quantities.length; i++) {
			var ownerID = null
			for (const [k, v] of mapRegSeasonPFLeadersByOwnerID) {
				if (v === quantities[i]) {
					mapRegSeasonPFLeadersByOwnerID.delete(k)
					ownerID = k
					break
				}
			}

			quantities[i] = {
				quantity: quantities[i],
				ownerID: ownerID,
			}
		}
		
		var html = []
		html.push(
			<DataTables
				noBorder
				fixedData={this.getFixedData(quantities)}
				data={this.getData(quantities, mapLeagueUsers)}
				initSortOrder="0d"
			/>
		)
		return html
	}

  render() {
    return (
			<Card
				clear
				primaryText="PF leaders"
				body={
					<Card
						bodyNoPadding
						body={this.body()}
					/>
				}
			/>
    )
  }
}