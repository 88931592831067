import * as fnSleeper from '../../../utils/functions/sleeper.js'
import * as fnSort from '../../../utils/functions/sort.js'

import React from 'react'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class PastRegSeasonWins extends React.Component {
  constructor(props) {
    super(props)
  }

	getFixedData(quantities) {
		return {
			headers: [
				{
					content: "Qty",
					numeric: true,
					sort: true
				}
			],
			rows: this.getFixedRows(quantities)
		}
	}

	getFixedRows(quantities) {
		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []
			data.push(
				{
					content: <div>{quantities[i].quantity}</div>,
					numeric: true,
					value: quantities[i].quantity
				}
			)
			rows.push(data)
		}

		return rows
	}

	getData(quantities, mapLeagueUsers) {
		return {
			headers: [
				{
					content: "Team"
				}
			],
			rows: this.getRows(quantities, mapLeagueUsers)
		}
	}

	getRows(quantities, mapLeagueUsers) {
		var archivedSeasons = this.props.archivedSeasons

		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []

			if (mapLeagueUsers && mapLeagueUsers.has(quantities[i].ownerID))
				data.push(
					{
						content: <User ownerID={quantities[i].ownerID} mapLeagueUsers={mapLeagueUsers} />
					}
				)
			else if (archivedSeasons) {
				var teamName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).teamName
				var displayName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).displayName
				var avatar = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"@" + displayName}
							/>
						)
					}
				)
			}

			rows.push(data)
		}

		return rows
	}

	body() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		var mapOfMapLeagueRostersByLeagueID = this.props.mapOfMapLeagueRostersByLeagueID
		var mapLeagueUsers = this.props.mapLeagueUsers

		var archivedSeasons = this.props.archivedSeasons

		if (arrPastSeasonsByLeagueID === null || mapOfMapLeagueRostersByLeagueID === null || mapLeagueUsers === null)
			return
		
		var mapRegSeasonWinsByOwnerID = new Map()

		if (arrPastSeasonsByLeagueID)
			for (const obj of arrPastSeasonsByLeagueID) {
				var ownerIDs = fnSleeper.getRegularSeasonStandings(mapOfMapLeagueRostersByLeagueID.get(obj.leagueID))

				var quantity = 1
				if (mapRegSeasonWinsByOwnerID.has(ownerIDs[0]))
					quantity = mapRegSeasonWinsByOwnerID.get(ownerIDs[0]) + 1
				mapRegSeasonWinsByOwnerID.set(ownerIDs[0], quantity)
			}
		
		if (archivedSeasons) {
			var _mapLeagueRosters = new Map()

			archivedSeasons.mapTeamStatsByYear.forEach(
				(arrTeamStats, year) => {
					for (const teamStats of arrTeamStats) {
						var record = []
					
						var strRecord = teamStats.record.split("-")
						for (var i = 0; i < strRecord.length; i++) {
							switch (i) {
								case 0:
									for (var j = 1; j <= parseInt(strRecord[i]); j++)
										record.push("W")
									break
								case 1:
									for (var j = 1; j <= parseInt(strRecord[i]); j++)
										record.push("L")
									break
								case 2:
									for (var j = 1; j <= parseInt(strRecord[i]); j++)
										record.push("T")
									break
							}
						}
	
						record = record.join("")
		
						var pf = teamStats.pf.split(".")
						var pf_decimal = pf.length > 0 ? parseFloat(pf[1]) : 0
						var pa = teamStats.pa.split(".")
						var pa_decimal = pa.length > 0 ? parseFloat(pa[1]) : 0
		
						var metadata = {record: record}
						var settings = {
							fpts: parseFloat(pf[0]),
							fpts_decimal: pf_decimal,
							fpts_against: parseFloat(pa[0]),
							fpts_against_decimal: pa_decimal,
						}
	
						var ownerID = teamStats.roster_id
						if (archivedSeasons.mapRosterSlotsByRosterID.has(teamStats.roster_id))
							ownerID = archivedSeasons.mapRosterSlotsByRosterID.get(teamStats.roster_id).ownerID
		
						_mapLeagueRosters.set(
							ownerID,
							{
								metadata: metadata,
								settings: settings,
							}
						)
					}

					var arrRegularSeasonStandings = fnSleeper.getRegularSeasonStandings(_mapLeagueRosters)
		
					var quantity = 1
					if (mapRegSeasonWinsByOwnerID.has(arrRegularSeasonStandings[0]))
						quantity = mapRegSeasonWinsByOwnerID.get(arrRegularSeasonStandings[0]) + 1
					mapRegSeasonWinsByOwnerID.set(arrRegularSeasonStandings[0], quantity)
				}
			)
		}

		var quantities = []
		mapRegSeasonWinsByOwnerID.forEach(
			(v, k) => {
				quantities.push(v)
			}
		)
		
		quantities = fnSort.sortInt(quantities).reverse()
		
		for (var i = 0; i < quantities.length; i++) {
			var ownerID = null
			for (const [k, v] of mapRegSeasonWinsByOwnerID) {
				if (v === quantities[i]) {
					mapRegSeasonWinsByOwnerID.delete(k)
					ownerID = k
					break
				}
			}

			quantities[i] = {
				quantity: quantities[i],
				ownerID: ownerID,
			}
		}
		
		var html = []
		html.push(
			<DataTables
				noBorder
				fixedData={this.getFixedData(quantities)}
				data={this.getData(quantities, mapLeagueUsers)}
				initSortOrder="0d"
			/>
		)
		return html
	}

  render() {
    return (
			<Card
				clear
				primaryText="Regular season wins"
				body={
					<Card
						bodyNoPadding
						body={this.body()}
					/>
				}
			/>
    )
  }
}