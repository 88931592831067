import * as fnSleeper from '../../../utils/functions/sleeper.js'
import * as fnSort from '../../../utils/functions/sort.js'

import React from 'react'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class PastTop3 extends React.Component {
  constructor(props) {
    super(props)
  }

	getFixedData(quantities) {
		return {
			headers: [
				{
					content: "Qty",
					numeric: true,
					sort: true
				}
			],
			rows: this.getFixedRows(quantities)
		}
	}

	getFixedRows(quantities) {
		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []
			data.push(
				{
					content: <div>{quantities[i].quantity}</div>,
					numeric: true,
					value: quantities[i].quantity
				}
			)
			rows.push(data)
		}

		return rows
	}

	getData(quantities, mapLeagueUsers) {
		return {
			headers: [
				{
					content: "Team"
				}
			],
			rows: this.getRows(quantities, mapLeagueUsers)
		}
	}

	getRows(quantities, mapLeagueUsers) {
		var archivedSeasons = this.props.archivedSeasons

		var rows = []
		
		for (var i = 0; i < quantities.length; i++) {
			var data = []
			
			if (mapLeagueUsers && mapLeagueUsers.has(quantities[i].ownerID))
				data.push(
					{
						content: <User ownerID={quantities[i].ownerID} mapLeagueUsers={mapLeagueUsers} />
					}
				)
			else if (archivedSeasons && archivedSeasons.mapRosterSlotsByRosterID.has(quantities[i].ownerID)) {
				var teamName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).teamName
				var displayName = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).displayName
				var avatar = archivedSeasons.mapRosterSlotsByRosterID.get(quantities[i].ownerID).avatar

				data.push(
					{
						content: (
							<User
								avatar={avatar}
								teamName={teamName && teamName !== "" ? teamName : displayName}
								secondaryText={"@" + displayName}
							/>
						)
					}
				)
			}

			rows.push(data)
		}

		return rows
	}

	body() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		var mapWinnersBracketsByLeagueID = this.props.mapWinnersBracketsByLeagueID
		var mapLeagueRosters = this.props.mapLeagueRosters
		var mapLeagueUsers = this.props.mapLeagueUsers

		var archivedSeasons = this.props.archivedSeasons

		if (arrPastSeasonsByLeagueID === null || mapWinnersBracketsByLeagueID === null || mapLeagueRosters === null || mapLeagueUsers === null)
			return
		
		var mapAppearancesByOwnerID = new Map()

		if (arrPastSeasonsByLeagueID)
			for (const obj of arrPastSeasonsByLeagueID) {
				var winnersBracket = mapWinnersBracketsByLeagueID.get(obj.leagueID)
				var arrStandings = fnSleeper.getBracketFinalStandings(winnersBracket)

				for (var i = 0; i < 3; i++) {
					var ownerID = fnSleeper.getOwnerIDByRosterID(arrStandings[i], mapLeagueRosters)
					
					var quantity = 1
					if (mapAppearancesByOwnerID.has(ownerID))
						quantity = mapAppearancesByOwnerID.get(ownerID) + 1
					mapAppearancesByOwnerID.set(ownerID, quantity)
				}
			}
		
		if (archivedSeasons) {
			var arrYears = []
	
			archivedSeasons.mapStandingsByYear.forEach(
				(v, year) => {
					arrYears.push(parseInt(year))
				}
			)
	
			arrYears = fnSort.sortInt(arrYears).reverse()

			for (const year of arrYears) {
				var arrStandings = archivedSeasons.mapStandingsByYear.get(year.toString()).split(",")
				
				for (var i = 0; i < 3; i++) {
					var rosterID = arrStandings[i]
					var ownerID = archivedSeasons.mapRosterSlotsByRosterID.get(rosterID).ownerID
					if (ownerID === null)
						ownerID = rosterID
					
					var quantity = 1
					if (mapAppearancesByOwnerID.has(ownerID))
						quantity = mapAppearancesByOwnerID.get(ownerID) + 1
					mapAppearancesByOwnerID.set(ownerID, quantity)
				}
			}
		}

		var quantities = []
		mapAppearancesByOwnerID.forEach(
			(v, k) => {
				quantities.push(v)
			}
		)
		
		quantities = fnSort.sortInt(quantities).reverse()
		
		for (var i = 0; i < quantities.length; i++) {
			var ownerID = null
			for (const [k, v] of mapAppearancesByOwnerID) {
				if (v === quantities[i]) {
					mapAppearancesByOwnerID.delete(k)
					ownerID = k
					break
				}
			}

			quantities[i] = {
				quantity: quantities[i],
				ownerID: ownerID,
			}
		}
		
		var html = []
		html.push(
			<DataTables
				noBorder
				fixedData={this.getFixedData(quantities)}
				data={this.getData(quantities, mapLeagueUsers)}
				initSortOrder="0d"
			/>
		)
		return html
	}

  render() {
    return (
			<Card
				clear
				primaryText="Appearances in the Top 3"
				body={
					<Card
						bodyNoPadding
						body={this.body()}
					/>
				}
			/>
    )
  }
}