import * as fn from '../../utils/functions/general.js'
import * as fnString from '../../utils/functions/string.js'

import React from 'react'
import SmoothScroll from 'smooth-scroll'
import 'isomorphic-fetch'

import './ResetPasswordForm.css'

import Alert from '../../components/alert/Alert/Alert.jsx'
import Input from '../../components/field/Input/Input.jsx'
import Button from '../../components/button/Button/Button.jsx'

var scroll = new SmoothScroll('a[href*="#"]', { speed: 500 });

const passwordMinChar = 8
const passwordMaxChar = 128

const elems = [
  "password1",
  "password2",
]

export default class ResetPasswordForm extends React.Component {
  constructor(props) {
		super(props)
		
		this.state = {
      triedSubmit: false,
      buttonEnabled: true,
      alertMessage: null,

      errorPassword1: null,
      errorPassword2: null,
    }

    this.handleChange = this.handleChange.bind(this)
	}

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  
  handleChange(event) {
    this.updateInvalid(event.target.name)
    this.setState({buttonEnabled: true})
  }

  handleClickLogIn() {
    new Promise((resolve) => {
      this.setState({triedSubmit: true})
      resolve(true)
    })
    .then(() => {
      for (var i=0; i<elems.length; i++)
        this.updateInvalid(elems[i])
      return true
    })
    .then(() => {
      if (
        this.state.errorPassword1 ||
        this.state.errorPassword2 ||
        !this.state.buttonEnabled
      ) return false
      this.setState({buttonEnabled: false})
      return true
    })
    .then((resolve) => {
      if (resolve)
        this.submit()
      else
        this.scrollToError()
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  scrollToError() {
    var anchor = null

    if (this.state.errorPassword1)
      anchor = document.getElementById("password1")
    else if (this.state.errorPassword2)
      anchor = document.getElementById("password2")
    
    if (!anchor)
      return
		
		scroll.animateScroll(anchor);
    if (anchor.nodeName === "INPUT")
      anchor.focus()
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  updateErrors() {
    (() => {
      return this.state.errorPassword1
    })
    (() => {
      return this.state.errorPassword2
    })
  }
  
  updateInvalid(name) {
    if (!this.state.triedSubmit)
      return;
    
    switch(name) {
      case "password1":
        var elem = document.getElementById(name).value
        if (fnString.strEmpty(elem))
          this.setState({ errorPassword1: "*Required"}, () => this.updateErrors())
        else if (fnString.strTooShort(elem, passwordMinChar))
          this.setState({ errorPassword1: "Must be greater than " + passwordMinChar + " characters"}, () => this.updateErrors())
        else if (fnString.strTooLong(elem, passwordMaxChar))
          this.setState({ errorPassword1: "Must not exceed " + passwordMaxChar + " characters"}, () => this.updateErrors())
        else
          this.setState({ errorPassword1: null }, () => this.updateErrors())
        break
      case "password2":
        if (document.getElementById("password1").value === document.getElementById("password2").value)
          this.setState({ errorPassword2: null }, () => this.updateErrors())
        else
          this.setState({ errorPassword2: "Passwords must match"}, () => this.updateErrors())
        break
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  submit() {
    var formData = fn.serializeArray(document.getElementById('form'))
    
    var _this = this
    var URL = "/ResetPassword"

    fetch(URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify({formData}),
    })
    .then(function(response) {
      return response.json()
    })
    .then(function(data) {
      if (data.hasOwnProperty("error"))
        _this.setState({ alertMessage: data.error, buttonEnabled: true })
      else if (data.hasOwnProperty("success") && data.success === true) {
        var href = []
        href.push("/")
        if (fn.getParamVal("p"))
          href.push(fn.getParamVal("p"))
        href = href.join("")
        window.location.href = href
      }
      else
        _this.setState({ alertMessage: "Something went wrong... Please try again.", buttonEnabled: true })
    })
    .catch(function(error) {
      console.error('Error:', error)
      _this.setState({ alertMessage: "Something went wrong... Please refresh the page.", buttonEnabled: true })
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  hideAlert() { return !(this.state.triedSubmit && this.state.alertMessage != "" && this.state.alertMessage != null)}

  render() {
    return (
			<div id="ResetPasswordForm">

        <div style={{ fontSize: '20px', fontWeight: '400', letterSpacing: '0.25px', textAlign: 'center', marginBottom: '30px' }}>
          Reset your password
        </div>

        <Alert hidden={this.hideAlert()}>{this.state.alertMessage}</Alert>

        <form id="form">

          <ul class="ResetPasswordForm_ul">
            <li class="ResetPasswordForm_li">
              <Input
                type="password"
                id="password1"
                name="password1"
                maxLength="128"
                onChange={this.handleChange}
                onEnter={this.handleClickLogIn.bind(this)}
                tabIndex="1"
                label="Enter new password"
                errorText={this.state.errorPassword1}
              />
            </li>
            <li class="ResetPasswordForm_li">
              <Input
                type="password"
                id="password2"
                name="password2"
                maxLength="128"
                onChange={this.handleChange}
                onEnter={this.handleClickLogIn.bind(this)}
                tabIndex="1"
                label="Re-enter new password"
                errorText={this.state.errorPassword2}
              />
            </li>
            <li class="ResetPasswordForm_li">
              <input id="e" name="e" type="hidden" autoComplete="off" value={fn.getParamVal("e")} />
              <input id="c" name="c" type="hidden" autoComplete="off" value={fn.getParamVal("c")} />
              <input id="n" name="n" type="hidden" autoComplete="off" value={fn.getParamVal("n")} />
              <div style={{height: '40px', width: '100%'}}>
                <Button
                  fillHeight
                  fillWidth
                  raised
                  disabled={!this.state.buttonEnabled}
                  onClick={this.handleClickLogIn.bind(this)}
                  tabIndex="1"
                >LOG IN</Button>
              </div>
            </li>
          </ul>

        </form>

			</div>
    )
  }
}