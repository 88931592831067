import * as fnSleeper from '../../utils/functions/sleeper.js'
import * as fnSort from '../../utils/functions/sort.js'

import React from 'react'

import './Rosters.css'

import Roster from './modules/Roster.jsx'

export default class Rosters extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			firstDraftID: null,
			latestDraftID: null,
			mapTransactionsByYear: null,
			arrLeagueIDsByYear: null,
			effPrices: null,
			yearsLeft: null,
		}

		this.refRosters = React.createRef()
  }

	componentDidMount() {
		this.refRosters.current.scrollTo(0, 0)

		if (this.props.leagueID)
			new Promise(
				(resolve) => {
					this.httpGetLeagueIDsByYear(this.props.leagueID)
					resolve(true)
				}
			).then(() => {})

		if (this.props.season && this.props.leagueID)
			new Promise(
				(resolve) => {
					this.httpGetTransactions(this.props.season, this.props.leagueID)
					resolve(true)
				}
			).then(() => {})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.mapDraftsByYear !== prevProps.mapDraftsByYear && this.props.mapDraftsByYear)
			this.getLatestDraftID(this.props.mapDraftsByYear)
		
		if (
			this.props.leagueID !== prevProps.leagueID &&
			this.props.leagueID
		) {
			new Promise((resolve) => {
				this.httpGetLeagueIDsByYear(this.props.leagueID)
				resolve(true)
			}).then(() => {})
		}
		
		if (
			(
				this.props.season !== prevProps.season ||
				this.props.leagueID !== prevProps.leagueID
			) &&
			this.props.season &&
			this.props.leagueID
		) {
			new Promise((resolve) => {
				this.httpGetTransactions(this.props.season, this.props.leagueID)
				resolve(true)
			}).then(() => {})
		}

		if (
			(
				this.props.season !== prevProps.season ||
				this.props.players !== prevProps.players ||
				this.props.mapDraftPicksByDraftID !== prevProps.mapDraftPicksByDraftID ||
				this.state.latestDraftID !== prevState.latestDraftID ||
				this.state.mapTransactionsByYear !== prevState.mapTransactionsByYear
			) &&
			this.props.season &&
			this.props.players &&
			this.props.mapDraftPicksByDraftID &&
			this.state.latestDraftID &&
			this.state.mapTransactionsByYear
		)
			new Promise(
				(resolve) => {
					this.getEffectivePrices(
						this.props.season,
						this.props.players,
						this.state.latestDraftID,
						this.props.mapDraftPicksByDraftID,
						this.state.mapTransactionsByYear
					)
					resolve(true)
				}
			).then(() => {})

		if (
			(
				this.props.isKeeper !== prevProps.isKeeper ||
				this.props.season !== prevProps.season ||
				this.props.mapLeagueRosters !== prevProps.mapLeagueRosters ||
				this.props.players !== prevProps.players ||
				this.props.mapDraftsByYear !== prevProps.mapDraftsByYear ||
				this.props.mapDraftPicksByDraftID !== prevProps.mapDraftPicksByDraftID ||
				this.state.firstDraftID !== prevState.firstDraftID ||
				this.state.mapTransactionsByYear !== prevState.mapTransactionsByYear
			) &&
			this.props.isKeeper &&
			this.props.season &&
			this.props.mapLeagueRosters &&
			this.props.players &&
			this.props.mapDraftsByYear &&
			this.props.mapDraftPicksByDraftID &&
			this.state.firstDraftID &&
			this.state.mapTransactionsByYear
		)
			new Promise(
				(resolve) => {
					this.getYearsLeft(
						this.props.season,
						this.props.mapLeagueRosters,
						this.props.players,
						this.state.firstDraftID,
						this.props.mapDraftsByYear,
						this.props.mapDraftPicksByDraftID,
						this.state.mapTransactionsByYear
					)
					resolve(true)
				}
			).then(() => {})
	}

	httpGetLeagueIDsByYear(leagueID) {
		this.fetchLeagueIDs(leagueID, null)
	}

	fetchLeagueIDs(leagueID, arrLeagueIDsByYear) {
    var _this = this
    var URL = "https://api.sleeper.app/v1/league/" + leagueID

		if (arrLeagueIDsByYear == null)
			arrLeagueIDsByYear = []

    fetch(
			URL, {
     		method: 'GET',
    	}
		)
    .then(
			(resp) => {
      	return resp.json()
    	}
		)
    .then(
			(data) => {
				if (data.season) {
					arrLeagueIDsByYear.push({
						year: parseInt(data.season),
						leagueID: leagueID
					})

					if (data.hasOwnProperty('previous_league_id') && data.previous_league_id != null && data.previous_league_id !== "0")
						this.fetchLeagueIDs(data.previous_league_id, arrLeagueIDsByYear)
					else {
						var _arrLeagueIDsByYear = []
						for (var i=arrLeagueIDsByYear.length-1; i>=0; i--) {
							_arrLeagueIDsByYear.push({
								year: arrLeagueIDsByYear[i].year,
								leagueID: arrLeagueIDsByYear[i].leagueID
							})
						}
						_this.setState({ arrLeagueIDsByYear: _arrLeagueIDsByYear })
					}
				}
				else {
					console.log("data: " + data)
					_this.props.handlerError("Empty result for '" + URL + "'")
					_this.setState({ arrLeagueIDsByYear: null })
				}
    	}
		).then(() => {})
    .catch(
			(error) => {
      console.error('Error:', error)
			_this.props.handlerError("Something went wrong... Please refresh the page.")
    })
	}

	httpGetTransactions(season, leagueID) {
		this.fetchTransactions(leagueID, season, 1, null)
	}

	fetchTransactions(leagueID, season, week, mapTransactionsByYear) {
		// console.log("week " + week)
		var URL = "https://api.sleeper.app/v1/league/" + leagueID + "/transactions/" + week

		if (mapTransactionsByYear == null)
			mapTransactionsByYear = new Map()
		
		fetch(
			URL, {
				method: 'GET',
			}
		)
    .then(
			(resp) => {
      	return resp.json()
    	}
		)
		.then(
			(data) => {
				var prevLeagueIDIndex = null
				var arrLeagueIDsByYear = this.state.arrLeagueIDsByYear

				if (arrLeagueIDsByYear)
					for (var i=arrLeagueIDsByYear.length-1; i>=0; i--) {
						if (arrLeagueIDsByYear[i].year === season) {
							if (i > 0)
								prevLeagueIDIndex = i-1
						}
					}

				if (data && data.length > 0) {
					// var oldSize = 0
					// if (mapTransactionsByYear.has(season))
					// 	oldSize = mapTransactionsByYear.get(season).length
					
					data.forEach(
						(item) => {
							if (item.status === "complete") {
								if (item.type === "waiver" || item.type === "trade") {
									// var playerID = Object.keys(item.adds)[0]

									if (!mapTransactionsByYear.has(season))
										mapTransactionsByYear.set(season, [])

									var array = mapTransactionsByYear.get(season)
									array.push({
										type: item.type,
										status_updated: item.status_updated,
										adds: item.adds,
										waiver_bid: item.type === "waiver" ? parseInt(item.settings.waiver_bid) : null,
									})
									mapTransactionsByYear.set(season, array)
								}
							}
						}
					)

					// var newSize = mapTransactionsByYear.get(season).length
					// console.log("mapTransactionsByYear.get(season).length: " + oldSize + " -> " + newSize)
					this.fetchTransactions(leagueID, season, week+1, mapTransactionsByYear)
				}
				else if (this.props.isKeeper && prevLeagueIDIndex != null) {
					var prev = arrLeagueIDsByYear[prevLeagueIDIndex]
					this.fetchTransactions(prev.leagueID, prev.year, 1, mapTransactionsByYear)
				}
				else {
					// console.log("updating transactions (size: " + mapTransactionsByYear.get(season).length + ")")
					new Promise(
						(resolve) => {
							this.setState({mapTransactionsByYear: mapTransactionsByYear})
							resolve(true)
						}
					).then(() => {})
				}
			},
		).then(
			() => {}
		)
    .catch(
			(error) => {
				console.error('Error:', error)
				this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		)
	}

	//////////////////////////////////////////////////////////////////////////////////////////

	getLatestDraftID(mapDraftsByYear) {
		var mapDraftYears = new Map()
		var arrDraftYears = []
		mapDraftsByYear.forEach(
			(arrDrafts, year) => {
				for (var i = 0; i < arrDrafts.length; i++) {
					if (!mapDraftYears.has(year) && arrDrafts[i].start_time != null && parseInt(arrDrafts[i].start_time) > 0) {
						mapDraftYears.set(year, arrDrafts[i])
						arrDraftYears.push(year)
					}
					else if (arrDrafts[i].start_time != null && parseInt(arrDrafts[i].start_time) > 0 && arrDrafts[i].start_time > mapDraftYears.get(year).start_time)
						mapDraftYears.set(year, arrDrafts[i])
				}
			}
		)

		arrDraftYears = fnSort.sortInt(arrDraftYears)
		
		var firstDraftID = null
		var latestDraftID = null
		for (const [year, arrDrafts] of mapDraftsByYear) {
			for (var i = 0; i < arrDrafts.length; i++) {
				var foundFirst = false
				var foundLatest = false

				if (year === arrDraftYears[0] && arrDrafts[i].start_time === mapDraftYears.get(year).start_time) {
					firstDraftID = mapDraftYears.get(year).draft_id
					foundFirst = true
				}
				if (year === arrDraftYears[arrDraftYears.length - 1] && arrDrafts[i].start_time === mapDraftYears.get(year).start_time) {
					latestDraftID = mapDraftYears.get(year).draft_id
					foundLatest = true
				}

				if (foundFirst && foundLatest)
					break
			}

			if (foundFirst && foundLatest)
				break
		}

		new Promise(
			(resolve) => {
				this.setState({
					firstDraftID: firstDraftID,
					latestDraftID: latestDraftID,
				})
				resolve(true)
			}
		).then(() => {})
	}

	getEffectivePrices(season, players, latestDraftID, mapDraftPicksByDraftID, mapTransactionsByYear) {
		var map = new Map()

		var draftPicks = new Map()
		mapDraftPicksByDraftID.get(latestDraftID).forEach(
			(item) => {
				draftPicks.set(item.player_id, item)
			}
		)

		players.forEach(
			(info, id) => {
				var playerID = id.toString()
				var pick = draftPicks.get(playerID)
				if (pick)
					map.set(playerID, parseInt(pick.metadata.amount))
				else
					map.set(playerID, 0)
			}
		)

		if (mapTransactionsByYear) {
			const transactions = mapTransactionsByYear.get(season)
			if (transactions && typeof transactions !== "undefined")
				transactions.forEach(
					(item) => {
						if (item.type === "waiver") {
							for (const playerID in item.adds) {
								if (map.has(playerID) && item.waiver_bid > map.get(playerID)) {
									// console.log("updating effPrice for '" + playerID + "' " + map.get(playerID) + " -> " + item.waiver_bid)
									map.set(playerID, item.waiver_bid)
								}
							}
						}
					}
				)
		}

		new Promise(
			(resolve) => {
				this.setState({effPrices: map})
				resolve(true)
			}
		).then(() => {})
	}

	getYearsLeft(season, mapLeagueRosters, players, firstDraftID, mapDraftsByYear, mapDraftPicksByDraftID, mapTransactionsByYear) {
		var map = new Map()

		players.forEach(
			(info, id) => {
				map.set(id.toString(), 3)
			}
		)

		if (firstDraftID !== "740614162127941632" || season >= 2024) {
			map = this.getYearsLeftRangeThroughDrafts(map, mapDraftsByYear, mapDraftPicksByDraftID, mapTransactionsByYear, players, mapLeagueRosters)
			this.setState({yearsLeft: map})
			return
		}
		
		var URL = "/a/rest/sleeper/archive/draftPicks/" + firstDraftID

		fetch(
			URL, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
			}
		)
		.then(
			(resp) => {
				return resp.json()
			}
		)
		.then(
			(data) => {
				if (data.hasOwnProperty("error"))
					this.props.handlerError(data.error)
				else if (data.hasOwnProperty("draft_picks") && data.draft_picks !== "") {
					data.draft_picks.forEach(
						(pick) => {
							if (map.has(pick.player_id)) {
								if (pick.hasOwnProperty("is_keeper") && pick.is_keeper === true || pick.is_keeper === false) {
									// console.log(fnSleeper.getPlayerInfoVerbose(pick.player_id, players) + ": " + map.get(pick.player_id) + " -> " + (map.get(pick.player_id)-1).toString())
									map.set(pick.player_id, map.get(pick.player_id) - 1)
								}
								else
									map.set(pick.player_id, 3)
							}
						}
					)

					map = this.getYearsLeftRangeThroughDrafts(map, mapDraftsByYear, mapDraftPicksByDraftID, mapTransactionsByYear, players, mapLeagueRosters)
					new Promise(
						(resolve) => {
							this.setState({yearsLeft: map})
							resolve(true)
						}
					).then(() => {})
				}
				else
					this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		).then(() => {})
		.catch(
			(error) => {
				console.error('Error:', error)
				this.setState({error: "Something went wrong... Please refresh the page.", yearsLeft: null})
			}
		)
	}

	getYearsLeftRangeThroughDrafts(map, mapDraftsByYear, mapDraftPicksByDraftID, mapTransactionsByYear, players, mapLeagueRosters) {
		var mapDraftYears = new Map()
		var arrDraftYears = []
		mapDraftsByYear.forEach(
			(arrDrafts, year) => {
				for (var i = 0; i < arrDrafts.length; i++) {
					if (!mapDraftYears.has(year)) {
						mapDraftYears.set(year, [arrDrafts[i].start_time])
						arrDraftYears.push(year)
					}
					else {
						var startTimes = mapDraftYears.get(year)
						startTimes.push(arrDrafts[i].start_times)
						startTimes = fnSort.sortInt(startTimes).reverse()
						mapDraftYears.set(year, startTimes)
					}
				}
			}
		)

		arrDraftYears = fnSort.sortInt(arrDraftYears).reverse()

		for (var i = 0; i < arrDraftYears.length; i++) {
			var startTimes = mapDraftYears.get(arrDraftYears[i])

			for (const startTime of startTimes) {
				for (const [year, arrDrafts] of mapDraftsByYear) {
					var found = false

					for (var j = 0; j < arrDrafts.length; j++) {
						if (year === arrDraftYears[i] && arrDrafts[j].start_time === startTime) {
							var season = parseInt(arrDrafts[j].season)
							var draftPicks = mapDraftPicksByDraftID.get(arrDrafts[j].draft_id)
				
							draftPicks.forEach(
								(pick) => {
									if (map.has(pick.player_id)) {
										if (pick.is_keeper === null)
											map.set(pick.player_id, 3)
										else {
											var yearsLeft = map.get(pick.player_id)
											// console.log(fnSleeper.getPlayerInfoVerbose(pick.player_id, players) + ": " + yearsLeft + " -> " + (yearsLeft-1).toString())
											yearsLeft--
											if (yearsLeft < 0) {
												this.props.handlerError("yearsLeft = '" + yearsLeft + "' for " + fnSleeper.getPlayerInfoVerbose(pick.player_id, players))
												return
											}
				
											if (yearsLeft < 3) {
												var lastTradeTime = 0
												
												if (mapTransactionsByYear.has(season) && mapTransactionsByYear.get(season) !== null) {
													mapTransactionsByYear.get(season).forEach(
														(transaction) => {
															if (transaction.type === "trade")
																for (const playerID in transaction.adds)
																	if (playerID === pick.player_id && transaction.status_updated > lastTradeTime)
																		lastTradeTime = transaction.status_updated
														}
													)
						
													if (lastTradeTime > 0)
														mapTransactionsByYear.get(season).forEach(
															(transaction) => {
																if (transaction.type === "trade" && transaction.status_updated === lastTradeTime)
																	for (const playerID in transaction.adds)
																		if (playerID === pick.player_id && transaction.adds.playerID !== fnSleeper.getRosterID(pick.picked_by, mapLeagueRosters)) {
																			// console.log(fnSleeper.getPlayerInfoVerbose(pick.player_id, players) + ": " + yearsLeft + " -> " + (yearsLeft+1).toString())
																			yearsLeft++
																			return
																		}
															}
														)
												}
											}
				
											map.set(pick.player_id, yearsLeft)
										}
									}
								}
							)
	
							found = true
							break
						}
					}

					if (found)
						break
				}
			}
		}

		return map
	}

	//////////////////////////////////////////////////////////////////////////////////////////

  html() {
		var mapLeagueRosters = this.props.mapLeagueRosters
		if (mapLeagueRosters === null)
			return
				
		var html = []

		mapLeagueRosters.forEach(
			(roster) => {
				html.push(
					<Roster
						mapLeagueUsers={this.props.mapLeagueUsers}
						roster={roster}
						players={this.props.players}
						isKeeper={this.props.isKeeper}
						effPrices={this.state.effPrices}
						yearsLeft={this.state.yearsLeft}
					/>
				)
			}
		)

		return html
  }

  render() {
    return (
      <div id="Rosters" ref={this.refRosters}>
				<ul>
					{this.html()}
				</ul>
      </div>
    )
  }
}