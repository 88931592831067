import React from 'react'
import 'isomorphic-fetch'

export default class User extends React.Component {
  constructor(props) {
    super(props)
  }
  
  componentDidMount() {
    var URL = "/a/rest/user"

    fetch(
      URL, {
        credentials: 'same-origin'
      }
    )
    .then(
      (resp) => {
        return resp.json()
      }
    )
    .then(
      (data) => {
        this.props.handlerUpdateUser(data)
      }
    )
  }

  render() {
    return (
      <React.Fragment />
    )
  }
}