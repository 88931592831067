import React from 'react'

import './AlertDialog.css'

// import DialogButton from '../DialogButton/DialogButton.jsx'
import Button from '../../button/Button/Button.jsx'

export default class AlertDialog extends React.Component {
  constructor(props) {
    super(props)
		
		this.state = {
      scrollY: 0,
    }
  }

  componentWillMount() {
    var y = window.scrollY
    this.setState({ scrollY: y })
    document.body.style.setProperty('top', '-' + y + 'px')
    document.body.style.overflow = "hidden"
    document.body.style.position = "fixed"
  }

  componentWillUnmount() {
    document.body.style.overflow = null
    document.body.style.position = null
    document.body.style.top = null
    window.scrollTo(0, this.state.scrollY)
  }

  getText() {
    var output = []

    var text = this.props.text.split("\n")
    for (var i=0; i<text.length; i++) {
      var _text = text[i]
      output.push(_text)
      output.push(<br />)
    }

    return output
  }

  render() {
    return (
      <div class="AlertDialog_mask">
        <div class="AlertDialog_wrapper1">
          <div class="AlertDialog_wrapper2">
            <div class="AlertDialog">
              {
                this.props.header ?
                <h2 class="AlertDialog_h2">{this.props.header}</h2>
                : null
              }
              <section class="AlertDialog_section">
                {this.props.text ? this.getText() : "..."}
              </section>
              <footer class="AlertDialog_footer">
                {/* <DialogButton onClick={this.props.onClick}>Got it</DialogButton> */}
                <Button onClick={this.props.onClick}>GOT IT</Button>
              </footer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}