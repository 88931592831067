import React from 'react'

import * as fnSort from '../../../utils/functions/sort.js'

import './DataTables.css'

import MaterialIcons from '../../icons/MaterialIcons/MaterialIcons.jsx'

export default class DataTables extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			divWidth: 0,
			tableWidth: 0,
			sortOrder: null,
			sortColumn: null,
			rowOrder: null,
		}

		this.refDataTableDiv = React.createRef()
		this.refTable = React.createRef()
  }

	componentDidMount() {
		window.addEventListener("resize", this.updateWidths.bind(this))
		this.updateWidths()

		if (this.props.initSortOrder)
			this.setState({sortOrder: this.props.initSortOrder})
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWidths.bind(this))
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props !== prevProps)
			this.updateWidths()

		if (
			this.props.fixedData !== prevProps.fixedData ||
			this.props.data !== prevProps.data ||
			this.state.sortOrder !== prevState.sortOrder ||
			this.state.sortColumn !== prevState.sortColumn
		)
			this.updateSortData(this.props.fixedData, this.props.data, this.state.sortOrder, this.state.sortColumn)
		
		if (
			this.props.fixedData !== prevProps.fixedData ||
			this.props.data !== prevProps.data ||
			this.state.sortOrder !== prevState.sortOrder
		)
			this.updateRowOrder(this.props.fixedData, this.props.data, this.state.sortOrder)

		// if (this.state.sortOrder !== prevState.sortOrder)
		// 	console.log("sortOrder: " + this.state.sortOrder)
	}

	updateWidths() {
		if (this.refDataTableDiv.current && this.refTable.current)
			new Promise(
				(resolve) => {
					this.setState({
						divWidth: this.refDataTableDiv.current.offsetWidth,
						tableWidth: this.refTable.current.offsetWidth,
					})
					resolve(true)
				}
			)
	}

	updateSortData(fixedData, data, sortOrder, sortColumn) {
		var numColumns = 0

		if (fixedData && fixedData.hasOwnProperty('headers'))
			numColumns += fixedData.headers.length
		if (data && data.hasOwnProperty('headers'))
			numColumns += data.headers.length
		
		var newSortOrder = []
		if (sortOrder !== null)
			newSortOrder = sortOrder.split(",")
		
		if (sortOrder === null)
			newSortOrder.push("0a")
		else if (sortColumn) {
			var sortColumnNum = parseInt(sortColumn)
			var sortColumnDir = "a"

			for (const char of sortColumn)
				if (char === "a" || char === "d") {
					if (char === "d")
						sortColumnDir = "d"
					break
				}

			if (sortColumnNum >= 0 && sortColumnNum < numColumns)
				newSortOrder.unshift(String(sortColumnNum) + sortColumnDir)
		}
		
		if (newSortOrder.length >= 2 && parseInt(newSortOrder[0]) === parseInt(newSortOrder[1]))
			newSortOrder.splice(1, 1)

		newSortOrder = newSortOrder.slice(0, Math.min(newSortOrder.length, numColumns, 2))
		if (this.state.sortOrder === null || newSortOrder[0] !== this.state.sortOrder.split(",")[0])
			this.setState({sortOrder: newSortOrder.join(",")})
	}

	updateRowOrder(fixedData, data, sortOrder) {
		if (sortOrder === null)
			sortOrder = []
		else if (sortOrder)
			sortOrder = sortOrder.split(",")
		
		var rowOrder = []
		
		for (var i=0; i<sortOrder.length; i++) {
			var sortColumn = sortOrder[i]
			var columnIdx = parseInt(sortColumn)
			var descending = false
			for (const char of sortColumn)
				if (char === "d") {
					descending = true
					break
				}

			switch (i) {
				case 0:
					if (columnIdx >= 0 && columnIdx < fixedData.headers.length + data.headers.length) {
						if (columnIdx >= fixedData.headers.length)
							rowOrder = this.sortRows(data.rows, columnIdx - fixedData.headers.length, descending)
						else
							rowOrder = this.sortRows(fixedData.rows, columnIdx, descending)
					}
					else
						for (var j=0; j<fixedData.rows.length; j++)
							rowOrder.push(j)
					break
				default:
					//
			}
		}

		this.setState({rowOrder: rowOrder})
	}

	sortRows(rows, columnIdx, descending) {
		var rowOrder = []
		if (!rows || rows.length === 0 || rows[0].length === 0)
			return rowOrder
		
		if (!rows[0][columnIdx].hasOwnProperty('value')) {
			for (var i=0; i<rows.length; i++)
				rowOrder.push(i)
			return rowOrder
		}

		var type = typeof rows[0][columnIdx].value
		// console.log("type: " + type)
		
		switch (type) {
			case 'number': break
			case 'string': break
			default:
				for (var i=0; i<rows.length; i++)
					rowOrder.push(i)
				return rowOrder
		}

		var mapValuesByRowID = new Map()
		
		for (var i=0; i<rows.length; i++)
			mapValuesByRowID.set(i, rows[i][columnIdx].value)
		
		switch (type) {
			case 'number':
				if (Number.isInteger(mapValuesByRowID.get(0)))
					mapValuesByRowID = new Map(fnSort.sortMapInt(mapValuesByRowID, true, descending))
				else
					mapValuesByRowID = new Map(fnSort.sortMapFloat(mapValuesByRowID, true, descending))
				break
			case 'string':
				mapValuesByRowID = new Map(fnSort.sortMapString(mapValuesByRowID, true, descending))
				break
		}

		mapValuesByRowID.forEach(
			(v, k) => {
				rowOrder.push(k)
			}
		)
		
		return rowOrder
	}

	htmlGetHeaders(fixed) {
		var output = []

		var headers
		if (fixed)
			headers = this.props.fixedData.headers
		else if (this.props.data)
			headers = this.props.data.headers
		
		var sortOrder = []
		if (this.state.sortOrder !== null)
			sortOrder = this.state.sortOrder.split(",")
				
		var sortColumnDir = "a"
		if (sortOrder && sortOrder.length > 0)
			for (const char of sortOrder[0])
				if (char === "a" || char === "d") {
					if (char === "d")
						sortColumnDir = "d"
					break
				}
		
		if (headers)
			for (var i=0; i < headers.length; i++) {
				var header = headers[i]

				output.push(
					<th class="DataTable_th">
						<div class={header.hasOwnProperty('numeric') && header.numeric ? "DataTable_th_row numeric" : "DataTable_th_row"}>
							{header.content}
							<div
								class={header.hasOwnProperty('sort') && header.sort ? "DataTable_th_trailingIconWrapper" : "hidden"}
								onClick={sortOrder.length > 0 ? this.setSortColumn.bind(this, this.props.fixedData.headers.length, fixed, sortColumnDir, sortOrder[0], i) : null}
							>
								{
									(fixed && sortOrder.length > 0 && parseInt(sortOrder[0]) === i) || 
									(!fixed && sortOrder.length > 0 && parseInt(sortOrder[0]) === i + this.props.fixedData.headers.length) ?
									(sortColumnDir === "d" ? <MaterialIcons size="18">arrow_drop_down</MaterialIcons> : <MaterialIcons size="18">arrow_drop_up</MaterialIcons>)
									:
									<MaterialIcons size="18">unfold_more</MaterialIcons>
								}
							</div>
						</div>
					</th>
				)
			}

		return output
	}

	setSortColumn(fixedDataHeadersLength, fixed, sortColumnDir, sortOrder, i) {
		var sortColumn = null
		
		if (sortOrder.length > 0) {
			sortColumn = []

			if (fixed)
				sortColumn.push(String(i))
			else
				sortColumn.push(String(i + fixedDataHeadersLength))
			
			if ((fixed && parseInt(sortOrder) === i) || (!fixed && parseInt(sortOrder) === i + fixedDataHeadersLength)) {
				if (sortColumnDir === "a")
					sortColumn.push("d")
				else
					sortColumn.push("a")
			} else
				sortColumn.push("a")
			
			sortColumn = sortColumn.join("")
		}

		this.setState({sortColumn: sortColumn})
	}

	htmlGetBody(fixed) {
		var output = []

		var rows
		if (fixed)
			rows = this.props.fixedData.rows
		else if (this.props.data)
			rows = this.props.data.rows
		
		if (rows === null || !rows || rows.length === 0)
			return output
		
		var _this = this

		if (this.state.rowOrder === null)
			rows.forEach(
				(row) => {output.push(<tr class="DataTable_tr">{_this.htmlGetRow(row)}</tr>)}
			)
		else
			for (const rowID of this.state.rowOrder)
				output.push(<tr class="DataTable_tr">{_this.htmlGetRow(rows[rowID])}</tr>)

		return output
	}

	htmlGetRow(row) {
		var output = []

		if (row)
			row.forEach(
				(data) => {
					if (
						(data.hasOwnProperty('numeric') && data.numeric) ||
						(data.hasOwnProperty('value') && typeof data.value === 'number')
					)
						output.push(<td class="DataTable_td numeric">{data.content}</td>)
					else
						output.push(<td class="DataTable_td">{data.content}</td>)
				}
			)

		return output
	}

  render() {
    return (
      <div class={this.props.hide? "hidden" : (this.props.noBorder? "DataTables noBorder" : "DataTables")}>
				<div class={this.props.fixedData ? "DataTable fixed" : "hidden"}>
					<table class="DataTable_table">
						<thead class="DataTable_thead">
							<tr class="DataTable_header_tr">
								{this.htmlGetHeaders(this.props.fixedData)}
							</tr>
						</thead>
						<tbody class="DataTable_tbody">
							{this.htmlGetBody(this.props.fixedData)}
						</tbody>
					</table>
				</div>
				
				<div class={this.state.tableWidth > this.state.divWidth ? "DataTable boxShadow" : "DataTable"} ref={this.refDataTableDiv}>
					<table class="DataTable_table" ref={this.refTable}>
						<thead class="DataTable_thead">
							<tr class="DataTable_header_tr">
								{this.htmlGetHeaders()}
							</tr>
						</thead>
						<tbody class="DataTable_tbody">
							{this.htmlGetBody()}
						</tbody>
					</table>
				</div>
      </div>
    )
  }
}