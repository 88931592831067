import React from 'react'

import './Menu.css'

import List from '../../list/List/List.jsx'

export default class Menu extends React.Component {
  constructor(props) {
		super(props)
	}

	getClassMenu() {
		if (this.props.drawer)
			return "Menu drawer"
		return "Menu"
	}

	getStyle() {
		var output = {}
		if (this.props.top) {
			output.position = 'relative'
			output.top = this.props.top
		}
		return output
	}

  render() {
    return (
			<div class={this.getClassMenu()} style={this.getStyle()}>
				<List>
					{this.props.elems}
				</List>
			</div>
    )
  }
}