import React from 'react'

import './Button.css'

export default class Button extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      focused: false,
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  componentDidMount() {
    var id = this.props.id
    if (id) {
      if (this.props.primaryColor)
        document.getElementById(id).style.setProperty("--primary-color", this.props.primaryColor)
      if (this.props.backgroundColor)
        document.getElementById(id).style.setProperty("--background-color", this.props.backgroundColor)
      if (this.props.foregroundColor)
        document.getElementById(id).style.setProperty("--foreground-color", this.props.foregroundColor)
      if (this.props.borderColor)
        document.getElementById(id).style.setProperty("--border-color", this.props.borderColor)
      if (this.props.disabledForegroundColor)
        document.getElementById(id).style.setProperty("--disabled-foreground-color", this.props.disabledForegroundColor)
      if (this.props.disabledBackgroundColor)
        document.getElementById(id).style.setProperty("--disabled-background-color", this.props.disabledBackgroundColor)
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onFocus() {
    this.setState({ focused: true })
  }
  onBlur() {
    this.setState({ focused: false })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  getButtonClass() {
    var output = []

    output.push("Button")

    if (this.props.fillHeight)
      output.push("fillHeight")
    if (this.props.fillWidth)
      output.push("fillWidth")
    
    if (this.props.raised)
      output.push("raised")
    else if (this.props.unelevated)
      output.push("unelevated")
    else if (this.props.outlined)
      output.push("outlined")
    
    if (this.state.focused && this.props.raised)
      output.push("focused")

    if (this.props.disabled)
      output.push("disabled")

    return output.join(" ")
  }

  getSpanClass() {
    var output = []
    
    output.push("Button_ripple")

    if (this.props.raised)
      output.push("raised")
    else if (this.props.unelevated)
      output.push("unelevated")
    
    if (this.state.focused)
      output.push("focused")

    if (this.props.disabled)
      output.push("disabled")
    
    return output.join(" ")
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.href ?
          <a
            href={this.props.disabled ? null : this.props.href}
            class={this.getButtonClass()}
            id={this.props.id}
            name={this.props.name}
            tabIndex={this.props.noTab ? "-1" : "0"}
          >
            <span class={this.getSpanClass()} />
            {this.props.children ? this.props.children : "..."}
          </a>
          :
          <button
            type="button"
            class={this.getButtonClass()}
            id={this.props.id}
            name={this.props.name}
            tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
            onClick={this.props.onClick}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
            disabled={this.props.disabled}
          >
            <span class={this.getSpanClass()} />
            {this.props.children ? this.props.children : "..."}
          </button>
        }
      </React.Fragment>
    )
  }
}