import React from 'react'

import './ModalNavigation.css'

import Menu from '../../menu/Menu/Menu.jsx'

export default class ModalNavigation extends React.Component {
  constructor(props) {
		super(props)
	}

  render() {
    return (
			<div id={this.props.visible ? "ModalNavigation visible" : "ModalNavigation"}>
				<div class={this.props.visible ? "ModalNavigation_bg visible" : "ModalNavigation_bg"} onClick={this.props.handlerClose.bind(this)} />
				
        <div class={this.props.visible ? "ModalNavigation_drawerBg visible" : "ModalNavigation_drawerBg"}>
					{this.props.Header}
					<div class="ModalNavigation_Menu_wrapper">
						<Menu
							drawer
							top={this.props.top}
							elems={this.props.elems}
							handleChange={this.props.handleChange ? this.props.handleChange.bind(this) : null}
						/>
					</div>
        </div>
			</div>
    )
  }
}