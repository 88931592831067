import * as fnSort from '../../../utils/functions/sort.js'
import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './PastStandings.css'

import Card from '../../../components/card/Card/Card.jsx'
import DataTables from '../../../components/dataTables/DataTables/DataTables.jsx'
import User from '../components/User.jsx'

export default class PastStandings extends React.Component {
  constructor(props) {
    super(props)
  }

	getFixedData() {
		return {
			headers: [
				{
					content: "Season",
					numeric: true,
					sort: true
				}
			],
			rows: this.getFixedRows()
		}
	}

	getFixedRows() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		if (arrPastSeasonsByLeagueID === null)
			return
		
		var rows = []

		for (const obj of arrPastSeasonsByLeagueID) {
			if (obj.league.status === "complete")
				rows.push(
					[
						{
							content: <div>{obj.league.season}</div>,
							numeric: true,
							value: parseInt(obj.league.season)
						}
					]
				)
		}

		var archivedSeasons = this.props.archivedSeasons
		if (archivedSeasons !== null) {
			var arrYears = []

			archivedSeasons.mapStandingsByYear.forEach(
				(v, year) => {
					arrYears.push(parseInt(year))
				}
			)

			arrYears = fnSort.sortInt(arrYears).reverse()

			for (const year of arrYears)
				rows.push(
					[
						{
							content: <div>{year}</div>,
							numeric: true,
							value: year
						}
					]
				)
		}

		return rows
	}

	getData() {
		return {
			headers: [
				{
					content: (
						<div class="PastStandings_th_wrapper">
							<div class="PastStandings_th_icon">🥇</div>
							<div class="PastStandings_th_text">Champion</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStandings_th_wrapper">
							<div class="PastStandings_th_icon">🥈</div>
							<div class="PastStandings_th_text">2nd</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStandings_th_wrapper">
							<div class="PastStandings_th_icon">🥉</div>
							<div class="PastStandings_th_text">3rd</div>
						</div>
					)
				},
				{
					content: (
						<div class="PastStandings_th_wrapper">
							<div class="PastStandings_th_icon"></div>
							<div class="PastStandings_th_text">4th</div>
						</div>
					)
				}
			],
			rows: this.getRows()
		}
	}

	getRows() {
		var arrPastSeasonsByLeagueID = this.props.arrPastSeasonsByLeagueID
		var mapWinnersBracketsByLeagueID = this.props.mapWinnersBracketsByLeagueID
		var mapLeagueRosters = this.props.mapLeagueRosters
		var mapLeagueUsers = this.props.mapLeagueUsers

		var archivedSeasons = this.props.archivedSeasons

		if (arrPastSeasonsByLeagueID === null || mapWinnersBracketsByLeagueID === null || mapLeagueRosters === null || mapLeagueUsers === null)
			return
		
		var rows = []

		if (arrPastSeasonsByLeagueID)
			for (const obj of arrPastSeasonsByLeagueID) {
				var winnersBracket = mapWinnersBracketsByLeagueID.get(obj.leagueID)
				var arrStandings = fnSleeper.getBracketFinalStandings(winnersBracket)
				
				var data = []
				for (const rosterID of arrStandings) {
					
					var ownerID = fnSleeper.getOwnerIDByRosterID(rosterID, mapLeagueRosters)
					data.push(
						{
							content: (
								<User
									ownerID={ownerID}
									mapLeagueUsers={mapLeagueUsers}
									maxWidth='100px'
								/>
							)
						}
					)
				}
				
				rows.push(data)
			}
		
		if (archivedSeasons) {
			var arrYears = []

			archivedSeasons.mapStandingsByYear.forEach(
				(v, year) => {
					arrYears.push(parseInt(year))
				}
			)

			arrYears = fnSort.sortInt(arrYears).reverse()

			for (const year of arrYears) {
				var arrStandings = archivedSeasons.mapStandingsByYear.get(year.toString()).split(",")

				var data = []

				var i = 0
				for (const rosterID of arrStandings) {
					if (archivedSeasons.mapRosterSlotsByRosterID.has(rosterID)) {
						var teamName = archivedSeasons.mapRosterSlotsByRosterID.get(rosterID).teamName
						var displayName = archivedSeasons.mapRosterSlotsByRosterID.get(rosterID).displayName
						var avatar = archivedSeasons.mapRosterSlotsByRosterID.get(rosterID).avatar
						
						data.push(
							{
								content: (
									<User
										avatar={avatar}
										teamName={teamName && teamName !== "" ? teamName : displayName}
										secondaryText={"@" + displayName}
										maxWidth='100px'
									/>
								)
							}
						)
	
						i++
						if (i > 3)
							break
					}
				}
				
				rows.push(data)
			}
		}

		return rows
	}

  render() {
    return (
			<Card
				clear
				primaryText="Past season standings"
				body={
					<Card
						bodyNoPadding
						body={
							<DataTables
								noBorder
								fixedData={this.getFixedData()}
								data={this.getData()}
								initSortOrder="0d"
							/>
						}
					/>
				}
			/>
    )
  }
}