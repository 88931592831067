import React from 'react'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link
} from "react-router-dom";

import './Select.css'

import List from '../../list/List/List.jsx'
import MaterialIcons from '../../icons/MaterialIcons/MaterialIcons.jsx'

const liHeight = 48

export default class Select extends React.Component {
  constructor(props) {
		super(props)
    
    this.state = {
      focused: false,
      notEmpty: false,
      showMenu: false,
      value: null,
      text: null,
      options: [""],
    }

    this.handleClick = this.handleClick.bind(this)
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick)
    document.addEventListener('keydown', this.handleKeyDown.bind(this))

    if (this.props.defaultValue) 
      this.setState({ notEmpty: true, value: this.props.defaultValue, text: this.props.map.get(this.props.defaultValue) })
      
    var map = this.props.map
    if (map) {
      var _options = [""]
      for (let key of map.keys())
        _options.push(key)
      this.setState({ options: _options })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      var element = document.getElementById(this.props.id)

      if (element.value)
        this.setState({ notEmpty: true })

      var propsDefVal = this.props.defaultValue
      if (propsDefVal !== prevProps.defaultValue && propsDefVal && !this.state.value)
        this.setState({ notEmpty: true, value: propsDefVal, text: this.props.map.get(propsDefVal) })
    }

    if (this.state.showMenu && this.state.showMenu !== prevState.showMenu) {
      var elem = null

      const elements = document.getElementById("SelectWrapper_" + this.props.id).getElementsByTagName("li")
      for (var i=0; i<elements.length; i++) {
        if (elements[i].getAttribute('data-value') === (this.state.value ? this.state.value : "")) {
          elem = elements[i]
          break
        }
      }
      
      const offsetTop = elem ? elem.offsetTop : 0
      const offsetTop2 = offsetTop - (this.calcStyleMenuWrapper()[3] - liHeight)
      const elemMenuWrapper = document.getElementById("Select_MenuWrapper_" + this.props.id)
      elemMenuWrapper.scrollTop = Math.min(offsetTop, offsetTop2)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick)
    document.removeEventListener('keydown', this.handleKeyDown.bind(this))
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  setRefSelect(node) {this.RefSelect = node}
  setRefMenu(node) {this.RefMenu = node}

  handleClick(event) {
    if (document.body.contains(this.RefSelect) && this.RefSelect.contains(event.target)) {
      if (this.state.showMenu)
        this.setState({ showMenu: false })
      else
        this.setState({ focused: true, showMenu: true })
    }
    else if (this.state.showMenu && !this.RefMenu.contains(event.target))
      this.setState({ focused: false, showMenu: false })
    else
      this.setState({ focused: false })
  }

  handleClickOption(value) {
    this.setState({
      notEmpty: value ? true : false,
      showMenu: false,
      value: value,
      text: this.props.map.get(value),
    })
    
    document.getElementById("Select_" + this.props.id).focus()
  }

  handleKeyDown(event) {
    if (this.state.focused) {
      if (this.state.showMenu) {
        switch (event.key) {
          case "ArrowUp":
            event.preventDefault()
            this.arrowUp()
            break
          case "ArrowDown":
            event.preventDefault()
            this.arrowDown()
            break
          case "Escape":
            event.preventDefault()
            this.setState({showMenu: false})
            document.getElementById("Select_" + this.props.id).focus()
            break
          case "Enter":
          case " ":
            event.preventDefault()
            this.enter(event)
            break
        }
      } else {
        switch (event.key) {
          case "Enter":
          case " ":
          case "ArrowUp":
          case "ArrowDown":
            event.preventDefault()
            this.setState({ showMenu: true }, () => this.focusElement(this.state.value))
            break
        }
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  arrowUp() {
    const _options = this.state.options
    var i = 0
    for (; i<_options.length; i++) {
      if (document.activeElement.getAttribute('data-value') === _options[i])
        break
    }
    i = Math.max(i-1, 0)

    for (var j=0; j<_options.length; j++) {
      if (_options[j] === _options[i])
        this.focusElement(_options[j])
      else
        this.blurElement(_options[j])
    }
  }

  arrowDown() {
    const _options = this.state.options
    var i = 0
    for (; i<_options.length; i++) {
      if (document.activeElement.getAttribute('data-value') === _options[i])
        break
    }
    i = Math.min(i+1, _options.length-1)

    for (var j=0; j<_options.length; j++) {
      if (_options[j] === _options[i])
        this.focusElement(_options[j])
      else
        this.blurElement(_options[j])
    }
  }

  enter(event) {
    new Promise((resolve) => {
      const _options = this.state.options
      this.handleClickOption(event.target.getAttribute('data-value'))
      for (var i=0; i<_options.length; i++)
        this.blurElement(_options[i])
      resolve(true)
    })
    .then(() => {
      this.props.onChange(event)
    })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  focusElement(value) {
    var elements = document.getElementById("SelectWrapper_" + this.props.id).getElementsByTagName("li")
    for (var i=0; i<elements.length; i++) {
      var element = elements[i]
      if (element.getAttribute('data-value') === (value ? value : "")) {
        element.focus()
        element.tabIndex = "0"
        break
      }
    }
  }

  blurElement(value) {
    var elements = document.getElementById("SelectWrapper_" + this.props.id).getElementsByTagName("li")
    for (var i=0; i<elements.length; i++) {
      var element = elements[i]
      if (element.getAttribute('data-value') === (value ? value : "")) {
        element.blur()
        element.tabIndex = "-1"
        break
      }
    }
  }

  onFocus(e) {
    if (e.currentTarget.contains(document.activeElement))
      this.setState({ notEmpty: true, focused: true })
    else
      this.setState({ focused: true })
  }
  onBlur(e) {
    if (this.state.showMenu)
      return
    if (!e.currentTarget.contains(document.activeElement) && !document.getElementById(this.props.id).value)
      this.setState({ notEmpty: false, focused: false })
    else
      this.setState({ focused: false })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getClassSelect() {
    var output = []

    output.push("Select_input")
    if (this.props.errorText)
      output.push("error")
    
    return output.join(" ")
  }

  getClassLabel() {
    var output = []

    output.push("Select_label")
    if (this.state.focused)
      output.push("focused")
    else if (this.state.notEmpty)
      output.push("notEmpty")
    
    if (this.props.errorText)
      output.push("error")

    return output.join(" ")
  }

  getStyleMenuWrapper() {
    const style = this.calcStyleMenuWrapper()

    return {
      left: "0px",
      transformOrigin: style[0],
      top: style[1],
      bottom: style[2],
      maxHeight: style[3] + "px",
    }
  }

  calcStyleMenuWrapper() {
    const elem = document.getElementById("SelectWrapper_" + this.props.id)
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

    var transformOrigin = "center top"
    var top = "56px"
    var bottom = "unset"
    var maxHeight = h - 32

    if (elem) {
      const hAbove = elem.getBoundingClientRect().top - 16
      const hBelow = h - elem.getBoundingClientRect().bottom - 16

      const menuHeight = Math.max(this.props.map.size * liHeight + liHeight + 16, liHeight + 16)

      if (hAbove > hBelow && (menuHeight - liHeight) > hBelow) {
        transformOrigin = "center bottom"
        top = "unset"
        bottom = "76px"
        maxHeight = Math.min(maxHeight, hAbove)
      } else {
        maxHeight = Math.min(maxHeight, hBelow)
      }
    }

    return [transformOrigin, top, bottom, maxHeight]
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getList() {
    var output = []

    if (!this.props.hideBlank)
      output.push(
        <li
          class={this.state.value === "" || this.state.value === null ? "Select_li selected" : "Select_li"}
          role="option"
          tabIndex="-1"
          disabled
          data-value=""
          onClick={
            (event) => {
              new Promise((resolve) => {
                this.handleClickOption(null)
                resolve(true)
              })
              .then(() => {
                event.target = document.getElementById(this.props.id)
                this.props.onChange(event)
              })
            }
          }
        />
      )

    var map = this.props.map
    if (map) {
      for (let [key, value] of map.entries()) {
        output.push(
          <li style={{listStyleType: 'none'}}>{
            this.props.reactRouterLinkToPath ?
            <div class="Select_list_wrapper">
              <Link
                class={key === this.state.value ? "Select_a selected" : "Select_a"}
                role="option"
                tabIndex="-1"
                data-value={key}
                to={this.props.reactRouterLinkToPath + key}
                onClick={
                  (event) => {
                    new Promise((resolve) => {
                      this.handleClickOption(key)
                      resolve(true)
                    })
                    .then(() => {
                      event.target = document.getElementById(this.props.id)
                      if (this.props.onChange)
                        this.props.onChange(event)
                    })
                  }
                }
              >
                {value}
              </Link>
            </div>
            :
            <div class="Select_list_wrapper">
              <a
                class={key === this.state.value ? "Select_a selected" : "Select_a"}
                role="option"
                tabIndex="-1"
                data-value={key}
                onClick={
                  (event) => {
                    new Promise((resolve) => {
                      this.handleClickOption(key)
                      resolve(true)
                    })
                    .then(() => {
                      event.target = document.getElementById(this.props.id)
                      this.props.onChange(event)
                    })
                  }
              }>
                {value}
              </a>
            </div>
          }
          </li>
        )
      }
    }

    return output
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div class="Select" id={"SelectWrapper_" + this.props.id} style={this.props.width ? {width: this.props.width} : null}>
        <div class={this.state.focused ? "Select_Wrapper focused" : "Select_Wrapper"}>
          <div class="Select_TrailingIconWrapper">
            <MaterialIcons size="24" dark color={this.props.errorText ? "var(--err-color)" : null}>{this.props.errorText ? "error" : "arrow_drop_down"}</MaterialIcons>
          </div>
          <div
            class={this.getClassSelect()}
            id={"Select_" + this.props.id}
            ref={this.setRefSelect.bind(this)}
            tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
          >
            {this.state.text}
          </div>
          <input
            type="hidden"
            id={this.props.id}
            name={this.props.name}
            value={this.state.value}
          />
          <div class={this.state.focused ? (this.props.errorText ? "Select_Line focused error" : "Select_Line focused") : "Select_Line"} />
          <span class={this.getClassLabel()}>
            {this.props.label ? (this.props.errorText ? this.props.label + "*" : this.props.label) : null}
          </span>
        </div>
        {
          this.props.helperText || this.props.errorText ?
          <div class="Select_HelperTextWrapper">
            <div class={this.props.errorText ? "Select_HelperText error" : "Select_HelperText"}>
              {this.props.errorText ? this.props.errorText : this.props.helperText}
            </div>
          </div>
          : null
        }
        <div
          class={this.state.showMenu ? "Select_MenuWrapper show" : "Select_MenuWrapper"}
          id={"Select_MenuWrapper_" + this.props.id}
          style={this.getStyleMenuWrapper()}
          ref={this.setRefMenu.bind(this)}
        >
          <List>
            {this.getList()}
          </List>
        </div>
      </div>
    )
  }
}