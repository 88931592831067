import React from 'react'
import ReactDOM from 'react-dom'
import './utils/css/default.css'

import LogIn from './pages/LogIn/LogIn.jsx'
import ResetPassword from './pages/ResetPassword/ResetPassword.jsx'
import Sleeper from './pages/Sleeper/Sleeper.jsx'

function Root() {
	switch (window.location.pathname) {
		case "/ResetPassword":
			return (<ResetPassword />)
		case "/a/Sleeper":
			return (<Sleeper />)
		default:
			return (<LogIn />)
	}
}

ReactDOM.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>,
	document.getElementById('Root')
)