import { sortFloat } from "./sort"
import { numCharsInString } from "./string"

////////////////////////////////////////////////////////////////
// User
////////////////////////////////////////////////////////////////

export function getTeamName(ownerID, mapLeagueUsers) {
	if (mapLeagueUsers === null || !mapLeagueUsers.has(ownerID)) {
		// console.log("getTeamName() - Cannot find ownerID '" + ownerID + "' in mapLeagueUsers: " + mapLeagueUsers)
		return "-"
	}
	
	if (mapLeagueUsers.get(ownerID).metadata.team_name)
		return mapLeagueUsers.get(ownerID).metadata.team_name
	return mapLeagueUsers.get(ownerID).display_name
}

export function getDisplayName(ownerID, mapLeagueUsers) {
	if (mapLeagueUsers === null || !mapLeagueUsers.has(ownerID)) {
		// console.log("getDisplayName() - Cannot find ownerID '" + ownerID + "' in mapLeagueUsers: " + mapLeagueUsers)
		return "-"
	}
	
	if (mapLeagueUsers.get(ownerID).metadata.display_name)
		return mapLeagueUsers.get(ownerID).metadata.display_name
	return mapLeagueUsers.get(ownerID).display_name
}

export function getUserAvatar(ownerID, mapLeagueUsers) {
	if (mapLeagueUsers === null || !mapLeagueUsers.has(ownerID)) {
		// console.log("getUserAvatar() - Cannot find ownerID '" + ownerID + "'")
		return null
	}
	
	if (mapLeagueUsers.get(ownerID).metadata.avatar)
		return mapLeagueUsers.get(ownerID).metadata.avatar
	return "https://sleepercdn.com/avatars/" + mapLeagueUsers.get(ownerID).avatar
}

export function getRosterID(ownerID, mapLeagueRosters) {
	if (!mapLeagueRosters.has(ownerID)) {
		// console.log("getRosterID() - Cannot find ownerID '" + ownerID + "'")
		return "-"
	}

	return mapLeagueRosters.get(ownerID).roster_id
}

export function getOwnerIDByRosterID(rosterID, mapLeagueRosters) {
	var ownerID = null
	mapLeagueRosters.forEach(
		(v, k) => {
			if (v.roster_id === rosterID) {
				ownerID = v.owner_id
				return
			}
		}
	)
	return ownerID
}

////////////////////////////////////////////////////////////////
// Player
////////////////////////////////////////////////////////////////

export function getPlayerInfoVerbose(playerID, players) {
	if (!players.has(parseInt(playerID))) {
		console.log("getPlayerInfoVerbose() - Cannot find playerID '" + playerID + "'")
		return "-"
	}

	var player = players.get(parseInt(playerID))
	return "'" + player.full_name + ", " + player.position + " #" + player.number + "'"
}

export function getPlayerName(playerID, players) {
	if (!players.has(parseInt(playerID))) {
		console.log("getPlayerName() - Cannot find playerID '" + playerID + "'")
		return "-"
	}

	return players.get(parseInt(playerID)).full_name
}

export function getPlayerFirstName(playerID, players) {
	if (!players.has(parseInt(playerID))) {
		console.log("getPlayerFirstName() - Cannot find playerID '" + playerID + "'")
		return "-"
	}

	return players.get(parseInt(playerID)).first_name
}

export function getPlayerLastName(playerID, players) {
	if (!players.has(parseInt(playerID))) {
		console.log("getPlayerLastName() - Cannot find playerID '" + playerID + "'")
		return "-"
	}

	return players.get(parseInt(playerID)).last_name
}

export function getPlayerTeam(playerID, players) {
	if (players === null || !players.has(parseInt(playerID))) {
		console.log("getPlayerTeam() - Cannot find playerID '" + playerID + "'")
		return null
	}

	return players.get(parseInt(playerID)).team
}

export function getPlayerAge(playerID, players) {
	if (!players.has(parseInt(playerID))) {
		console.log("getPlayerAge() - Cannot find playerID '" + playerID + "'")
		return "-"
	}

	return players.get(parseInt(playerID)).age
}

export function getPlayerPosition(playerID, players) {
	if (!players.has(parseInt(playerID))) {
		console.log("getPlayerPosition() - Cannot find playerID '" + playerID + "'")
		return "-"
	}

	return players.get(parseInt(playerID)).position
}

export function getPlayerNumber(playerID, players) {
	if (!players.has(parseInt(playerID))) {
		console.log("getPlayerNumber() - Cannot find playerID '" + playerID + "'")
		return "-"
	}

	return players.get(parseInt(playerID)).number
}

export function getPlayerAvatar(playerID) {
	return "https://sleepercdn.com/content/nfl/players/" + playerID + ".jpg"
}

export function getPlayerTeamAvatar(playerID, players) {
	if (players === null || !players.has(parseInt(playerID))) {
		console.log("getPlayerTeamAvatar() - Cannot find playerID '" + playerID + "'")
		return null
	}

	var playerTeam = getPlayerTeam(playerID, players)
	if (playerTeam === null)
		return null
	
	return "https://sleepercdn.com/images/team_logos/nfl/" + playerTeam.toLowerCase() + ".png"
}

export function formatPlayerHeight(inches) {
	if (inches === null || !inches || inches === 0)
		return "-"
	
	var str = []
	str.push(parseInt(inches / 12))
	str.push("' ")
	str.push(parseInt(inches % 12))
	str.push('"')
	return str.join("")
}

////////////////////////////////////////////////////////////////
// Utility
////////////////////////////////////////////////////////////////

export function getRegularSeasonStandings(mapLeagueRosters) {
	// console.log("getRegularSeasonStandings()")
	if (mapLeagueRosters === null) {
		console.log("Error: mapLeagueRosters === null")
		return
	}

	var map = getRegularSeasonRecordsByOwnerID(mapLeagueRosters)

	var totalMatchesPlayed = 0

	// count most Ws
	var mostWs = 0
	map.forEach(
		(obj) => {
			if (totalMatchesPlayed === 0)
				totalMatchesPlayed = obj.record.length
			
			var numW = numCharsInString(obj.record, "W")
			if (numW > mostWs)
				mostWs = numW
		}
	)

	var standings = []

	// rank based on Ws
	for (var i = mostWs; i >= 0; i--) { // i = targetWs we're searching for
		// console.log("Ws: " + i)
		var arrWs = []
		var arrLs = []
		var arrTs = []
		var fewestLs = totalMatchesPlayed
		var fewestTs = totalMatchesPlayed

		map.forEach(
			(obj, ownerID) => {
				if (numCharsInString(obj.record, "W") === i) {
					arrWs.push(ownerID)
					
					var numL = numCharsInString(obj.record, "L")
					if (numL < fewestLs)
						fewestLs = numL
				}
			}
		)

		// rank based on Ls
		if (arrWs.length > 1)
			map.forEach(
				(obj, ownerID) => {
					if (
						numCharsInString(obj.record, "W") === i &&
						numCharsInString(obj.record, "L") === fewestLs
					) {
						arrLs.push(ownerID)

						var numT = numCharsInString(obj.record, "T")
						if (numT < fewestTs)
							fewestTs = numT
					}
				}
			)

		// rank based on Ts
		if (arrLs.length > 1)
			map.forEach(
				(obj, ownerID) => {
					if (
						numCharsInString(obj.record, "W") === i &&
						numCharsInString(obj.record, "L") === fewestLs &&
						numCharsInString(obj.record, "T") === fewestTs
					) {
						arrTs.push(ownerID)
					}
				}
			)

		var PFs = []
		var PAs = []

		// rank based on PF and PA
		if (arrTs.length > 1) {
			map.forEach(
				(obj, ownerID) => {
					if (
						numCharsInString(obj.record, "W") === i &&
						numCharsInString(obj.record, "L") === fewestLs &&
						numCharsInString(obj.record, "T") === fewestTs
					) {
						PFs.push(obj.pf)
						PAs.push(obj.pa)
					}
				}
			)

			PFs = sortFloat(PFs)
			PFs.reverse()
			PAs = sortFloat(PAs)
		}

		////////////////////
		var finalists = []

		if (PFs.length > 0) {
			for (var l = 0; l < PFs.length; l++) {
				var targetPF = PFs[l]
				var ownerToAdd = null

				map.forEach(
					(obj, ownerID) => {
						if (
							numCharsInString(obj.record, "W") === i &&
							numCharsInString(obj.record, "L") === fewestLs &&
							numCharsInString(obj.record, "T") === fewestTs &&
							obj.pf === targetPF
						) {
							ownerToAdd = ownerID
							return
						}
					}
				)
				
				for (var m = l+1; m < PFs.length; m++) {
					if (PFs[m] === targetPF) {
						for (var n = 0; n < PAs.length; n++)
							map.forEach(
								(obj, ownerID) => {
									if (
										numCharsInString(obj.record, "W") === i &&
										numCharsInString(obj.record, "L") === fewestLs &&
										numCharsInString(obj.record, "T") === fewestTs &&
										obj.pf === targetPF &&
										obj.pa === PAs[n]
									) {
										ownerToAdd = ownerID
										return
									}
								}
							)
					}
					else
						break
				}
			
				standings.push(ownerToAdd)
				// console.log("added " + ownerToAdd)
			}
		}
		else if (arrTs.length > 0) {
			for (const tie of arrTs)
				finalists.push(tie)
		} else if (arrLs.length > 0) {
			for (const loss of arrLs)
				finalists.push(loss)
		} else {
			for (const win of arrWs)
				finalists.push(win)
		}

		if (finalists.length === 1) {
			standings.push(finalists[0])
			// console.log("added " + finalists[0])
		} else if (finalists.length > 1) {
			PFs = []
			PAs = []
	
			for (const finalist of finalists) {
				PFs.push(map.get(finalist).pf)
				PAs.push(map.get(finalist).pa)
			}
	
			PFs = sortFloat(PFs)
			PFs.reverse()
			PAs = sortFloat(PAs)

			for (var l = 0; l < PFs.length; l++) {
				var targetPF = PFs[l]
				var ownerToAdd = null
				// console.log("i: " + i)
				// console.log("fewestLs: " + fewestLs)
				// console.log("fewestTs: " + fewestTs)

				map.forEach(
					(obj, ownerID) => {
						if (
							numCharsInString(obj.record, "W") === i &&
							numCharsInString(obj.record, "L") === fewestLs &&
							numCharsInString(obj.record, "T") === fewestTs &&
							obj.pf === targetPF
						) {
							ownerToAdd = ownerID
							return
						}
					}
				)
				
				for (var m = l+1; m < PFs.length; m++) {
					if (PFs[m] === targetPF) {
						for (var n = 0; n < PAs.length; n++)
							map.forEach(
								(obj, ownerID) => {
									if (
										numCharsInString(obj.record, "W") === i &&
										numCharsInString(obj.record, "L") === fewestLs &&
										numCharsInString(obj.record, "T") === fewestTs &&
										obj.pf === targetPF &&
										obj.pa === PAs[n]
									) {
										ownerToAdd = ownerID
										return
									}
								}
							)
					}
					else
						break
				}
			
				if (ownerToAdd !== null) {
					standings.push(ownerToAdd)
					console.log("added " + ownerToAdd)
				}
			}
		}
		
	}

	// console.log("standings.length: + " + standings.length)
	// for (var i = 0; i < standings.length; i++)
	// 	console.log("[" + i + "] " + standings[i])
	return standings
}

export function getRegularSeasonStandings_PF_PA(mapLeagueRosters) {
	// console.log("getRegularSeasonStandings()")
	if (mapLeagueRosters === null) {
		console.log("Error: mapLeagueRosters === null")
		return
	}

	var map = getRegularSeasonRecordsByOwnerID(mapLeagueRosters)

	var totalMatchesPlayed = 0

	var objStandings_PF_PA = {
		mapRecords: new Map(map),
		standings: null,
		pfMost: 0,
		pfLeast: 0,
		paMost: 0,
		paLeast: 0,
	}

	// count most Ws
	var mostWs = 0
	map.forEach(
		(obj, ownerID) => {
			if (totalMatchesPlayed === 0)
				totalMatchesPlayed = obj.record.length
			
			var numW = numCharsInString(obj.record, "W")
			if (numW > mostWs)
				mostWs = numW
			
			if (objStandings_PF_PA.pfMost === 0 || obj.pf > map.get(objStandings_PF_PA.pfMost).pf)
				objStandings_PF_PA.pfMost = ownerID
			if (objStandings_PF_PA.pfLeast === 0 || obj.pf < map.get(objStandings_PF_PA.pfLeast).pf)
				objStandings_PF_PA.pfLeast = ownerID
			if (objStandings_PF_PA.paMost === 0 || obj.pa > map.get(objStandings_PF_PA.paMost).pa)
				objStandings_PF_PA.paMost = ownerID
			if (objStandings_PF_PA.paLeast === 0 || obj.pa < map.get(objStandings_PF_PA.paLeast).pa)
				objStandings_PF_PA.paLeast = ownerID
		}
	)

	var standings = []

	// rank based on Ws
	for (var i = mostWs; i >= 0; i--) { // i = targetWs we're searching for
		// console.log("Ws: " + i)
		var arrWs = []
		var arrLs = []
		var arrTs = []
		var fewestLs = totalMatchesPlayed
		var fewestTs = totalMatchesPlayed

		map.forEach(
			(obj, ownerID) => {
				if (numCharsInString(obj.record, "W") === i) {
					arrWs.push(ownerID)
					
					var numL = numCharsInString(obj.record, "L")
					if (numL < fewestLs)
						fewestLs = numL
				}
			}
		)

		// rank based on Ls
		if (arrWs.length > 1)
			map.forEach(
				(obj, ownerID) => {
					if (
						numCharsInString(obj.record, "W") === i &&
						numCharsInString(obj.record, "L") === fewestLs
					) {
						arrLs.push(ownerID)

						var numT = numCharsInString(obj.record, "T")
						if (numT < fewestTs)
							fewestTs = numT
					}
				}
			)

		// rank based on Ts
		if (arrLs.length > 1)
			map.forEach(
				(obj, ownerID) => {
					if (
						numCharsInString(obj.record, "W") === i &&
						numCharsInString(obj.record, "L") === fewestLs &&
						numCharsInString(obj.record, "T") === fewestTs
					) {
						arrTs.push(ownerID)
					}
				}
			)

		var PFs = []
		var PAs = []

		// rank based on PF and PA
		if (arrTs.length > 1) {
			map.forEach(
				(obj, ownerID) => {
					if (
						numCharsInString(obj.record, "W") === i &&
						numCharsInString(obj.record, "L") === fewestLs &&
						numCharsInString(obj.record, "T") === fewestTs
					) {
						PFs.push(obj.pf)
						PAs.push(obj.pa)
					}
				}
			)

			PFs = sortFloat(PFs)
			PFs.reverse()
			PAs = sortFloat(PAs)
		}

		////////////////////
		var finalists = []

		if (PFs.length > 0) {
			for (var l = 0; l < PFs.length; l++) {
				var targetPF = PFs[l]
				var ownerToAdd = null

				map.forEach(
					(obj, ownerID) => {
						if (
							numCharsInString(obj.record, "W") === i &&
							numCharsInString(obj.record, "L") === fewestLs &&
							numCharsInString(obj.record, "T") === fewestTs &&
							obj.pf === targetPF
						) {
							ownerToAdd = ownerID
							return
						}
					}
				)
				
				for (var m = l+1; m < PFs.length; m++) {
					if (PFs[m] === targetPF) {
						for (var n = 0; n < PAs.length; n++)
							map.forEach(
								(obj, ownerID) => {
									if (
										numCharsInString(obj.record, "W") === i &&
										numCharsInString(obj.record, "L") === fewestLs &&
										numCharsInString(obj.record, "T") === fewestTs &&
										obj.pf === targetPF &&
										obj.pa === PAs[n]
									) {
										ownerToAdd = ownerID
										return
									}
								}
							)
					}
					else
						break
				}
			
				standings.push(ownerToAdd)
				// console.log("added " + ownerToAdd)
			}
		}
		else if (arrTs.length > 0) {
			for (const tie of arrTs)
				finalists.push(tie)
		} else if (arrLs.length > 0) {
			for (const loss of arrLs)
				finalists.push(loss)
		} else {
			for (const win of arrWs)
				finalists.push(win)
		}

		if (finalists.length === 1) {
			standings.push(finalists[0])
			// console.log("added " + finalists[0])
		} else if (finalists.length > 1) {
			PFs = []
			PAs = []
	
			for (const finalist of finalists) {
				PFs.push(map.get(finalist).pf)
				PAs.push(map.get(finalist).pa)
			}
	
			PFs = sortFloat(PFs)
			PFs.reverse()
			PAs = sortFloat(PAs)

			for (var l = 0; l < PFs.length; l++) {
				var targetPF = PFs[l]
				var ownerToAdd = null
				// console.log("i: " + i)
				// console.log("fewestLs: " + fewestLs)
				// console.log("fewestTs: " + fewestTs)

				map.forEach(
					(obj, ownerID) => {
						if (
							numCharsInString(obj.record, "W") === i &&
							numCharsInString(obj.record, "L") === fewestLs &&
							numCharsInString(obj.record, "T") === fewestTs &&
							obj.pf === targetPF
						) {
							ownerToAdd = ownerID
							return
						}
					}
				)
				
				for (var m = l+1; m < PFs.length; m++) {
					if (PFs[m] === targetPF) {
						for (var n = 0; n < PAs.length; n++)
							map.forEach(
								(obj, ownerID) => {
									if (
										numCharsInString(obj.record, "W") === i &&
										numCharsInString(obj.record, "L") === fewestLs &&
										numCharsInString(obj.record, "T") === fewestTs &&
										obj.pf === targetPF &&
										obj.pa === PAs[n]
									) {
										ownerToAdd = ownerID
										return
									}
								}
							)
					}
					else
						break
				}
			
				if (ownerToAdd !== null) {
					standings.push(ownerToAdd)
					console.log("added " + ownerToAdd)
				}
			}
		}
		
	}

	objStandings_PF_PA.standings = standings[0]
	return objStandings_PF_PA
}

export function getRegularSeasonRecordsByOwnerID(mapLeagueRosters) {
	if (mapLeagueRosters === null) {
		console.log("Error: mapLeagueRosters === null")
		return
	}

	var map = new Map()
	mapLeagueRosters.forEach(
		(roster, ownerID) => {
			if (
				roster.hasOwnProperty('metadata') &&
				roster.metadata &&
				roster.metadata.hasOwnProperty('record') &&
				roster.hasOwnProperty('settings') && 
				roster.settings &&
				roster.settings.hasOwnProperty('fpts') &&
				roster.settings.hasOwnProperty('fpts_decimal') &&
				roster.settings.hasOwnProperty('fpts_against') &&
				roster.settings.hasOwnProperty('fpts_against_decimal')
			) {
				var record = roster.metadata.record
				var pf = parseFloat((roster.settings.fpts).toString() + "." + (roster.settings.fpts_decimal).toString())
				var pa = parseFloat((roster.settings.fpts_against).toString() + "." + (roster.settings.fpts_against_decimal).toString())
				map.set(ownerID, {record: record, pf: pf, pa: pa})
			}
		}
	)

	return map
}

export function formatRecord(record) {
	var str = numCharsInString(record, "W").toString()
	str += "-"
	str += numCharsInString(record, "L").toString()
	var Ts = numCharsInString(record, "T")
	if (Ts > 0) {
		str += "-"
		str += Ts.toString()
	}
	return str
}

export function getBracketFinalStandings(bracket) {
	// console.log("getBracketFinalStandings()")
	if (bracket === null || bracket.length === 0)
		return

	var rounds = 0
	var mapMatchupsByRound = new Map()

	var teams = new Map()

	for (const matchup of bracket) {
		if (matchup.w === null || !Number.isInteger(matchup.w))
			return []
		
		if (matchup.r > rounds)
			rounds = matchup.r
		
		if (mapMatchupsByRound.has(matchup.r)) {
			var arrMatchups = mapMatchupsByRound.get(matchup.r)
			arrMatchups.push(matchup)
			mapMatchupsByRound.set(matchup.r, arrMatchups)
		}
		else
			mapMatchupsByRound.set(matchup.r, [matchup])
		
		if (!teams.has(matchup.w))
			teams.set(matchup.w, null)
		if (!teams.has(matchup.l))
			teams.set(matchup.l, null)
	}

	// teams.forEach(
	// 	(v, k) => {
	// 		console.log("team " + k + ": " + getTeamName(getOwnerIDByRosterID(k, mapLeagueRosters), mapLeagueUsers))
	// 	}
	// )
	
	var arrStandings = []

	for (var targetWs = rounds - 1; targetWs > 0; targetWs--) {
		var winners = new Map(teams)

		for (var rnd = 1; rnd <= rounds; rnd++) {
			var arrMatchups = mapMatchupsByRound.get(rnd)
	
			for (const matchup of arrMatchups) {
				if (rnd <= targetWs && winners.has(matchup.l))
					winners.delete(matchup.l)
			}
		}

		for (var rnd = targetWs + 1; rnd <= rounds; rnd++) {
			var arrMatchups = mapMatchupsByRound.get(rnd)

			var i=0
			for (const matchup of arrMatchups) {
				if (winners.has(matchup.w) && winners.has(matchup.l)) {
					arrStandings.push(matchup.w)
					winners.delete(matchup.w)
					teams.delete(matchup.w)
				}
				if (winners.has(matchup.w)) {
					arrStandings.push(matchup.w)
					winners.delete(matchup.w)
					teams.delete(matchup.w)
				}
				if (winners.has(matchup.l)) {
					arrStandings.push(matchup.l)
					winners.delete(matchup.l)
					teams.delete(matchup.l)
				}
			}
		}
	}

	return arrStandings
}

export function formatPoints(points) {
	if (!points.toString().includes("."))
		points = points.toString() + "."
	points = points.toString() + "00"
	return points.substring(0, points.indexOf(".") + 3)
}