import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './Player.css'

export default class Player extends React.Component {
  constructor(props) {
    super(props)
  }

	html() {
		var playerID = this.props.playerID
		var players = this.props.players
		if (playerID === null || players === null)
			return

		var playerTeamAvatar = fnSleeper.getPlayerTeamAvatar(playerID, players)
		var playerTeam = fnSleeper.getPlayerTeam(playerID, players)
		var playerPosition = fnSleeper.getPlayerPosition(playerID, players)

		var html = []

		html.push(
			<React.Fragment>
				<div class="Player_avatar_wrapper">
					<object class="Player_avatar_img" data={fnSleeper.getPlayerAvatar(playerID)} type="image/png" />
				</div>

				<div class="Player_name_wrapper">
					<div class="Player_name_text first">
						{fnSleeper.getPlayerFirstName(playerID, players)}
					</div>
					<div class="Player_name_text last">
						{fnSleeper.getPlayerLastName(playerID, players)}
					</div>
					<div class="Player_details_wrapper">
						<object class={playerTeamAvatar ? "Player_details_team_img" : "hidden"} data={playerTeamAvatar} type="image/png" />
						<div class="Player_details_text_pos">
							{
								playerTeamAvatar ?
								playerPosition
								:
								(
									playerTeam ?
									playerTeam + " - " + playerPosition
									: playerPosition
								)
							}
							{
								playerTeam ?
								" #" + fnSleeper.getPlayerNumber(playerID, players)
								: null
							}
						</div>
					</div>
				</div>
			</React.Fragment>
		)

		return html
	}

  render() {
    return (
			<div class="Player">
				{this.html()}
			</div>
    )
  }
}