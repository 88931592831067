////////////////////////////////////////////////////////////////
// Strings
////////////////////////////////////////////////////////////////

export function strEmpty(string) {
  return string == null ? true : !(string.length >= 1)
}

export function strTooShort(string, minLength) {
  return !(string.length >= minLength)
}

export function strTooLong(string, count) {
  return string.length > count
}

export function strTrim(string) {
  if (string)
    return string.trim()
  return ""
}

export function strCompare(str1, str2) {
  str1 = strTrim(str1)
  str2 = strTrim(str2)
  if (typeof str1 != "string" && typeof str2 != "string")
    return -3
  if (typeof str1 != "string")
    return -2
  if (typeof str2 != "string")
    return -1
  if (str1 == str2)
    return 0
  if (str1 != str2)
    return 1
}

export function numCharsInString(str, char) {
  return str.split(char).length - 1
}