import * as fn from '../../utils/functions/general.js'

import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import 'isomorphic-fetch'

import './Sleeper.css'

import User from '../../services/user/User.jsx'
import Header from '../../components/header/Sleeper/Header/Header.jsx'
import AlertDialog from '../../components/dialog/AlertDialog/AlertDialog.jsx'

import Home from './Home.jsx'
import Rosters from './Rosters.jsx'
import DraftResults from './DraftResults.jsx'
import PastSeasons from './PastSeasons.jsx'
import LeagueRules from './LeagueRules.jsx'

var hidden = "hidden"

export default class Sleeper extends React.Component {
  constructor(props) {
		super(props)
		
		this.state = {
			ws: null,
			hidden: false,

			error: null,
			json_user: [],

      season: null,
      week: null,
			
      arrActiveLeagues: null,
      mapLeagueUsers: null,
      mapLeagueRosters: null,
			arrPastSeasonsByLeagueID: null,
			arrDraftIDs: null,
      mapDraftsByYear: null,
			mapDraftPicksByDraftID: null,

      players: null,

			leagueID: null,
			leagueName: null,
			leagueDescription: null,
      isKeeper: false,
			leagueRules: null,
			playoffWeekStart: null,
    }
	}

	componentDidMount() {
		if (hidden in document)
			document.addEventListener("visibilitychange", this.onVisibilityChange.bind(this))
		else if ((hidden = "mozHidden") in document)
			document.addEventListener("mozvisibilitychange", this.onVisibilityChange.bind(this))
		else if ((hidden = "webkitHidden") in document)
			document.addEventListener("webkitvisibilitychange", this.onVisibilityChange.bind(this))
		else if ((hidden = "msHidden") in document)
			document.addEventListener("msvisibilitychange", this.onVisibilityChange.bind(this))
		else if ("onfocusin" in document) // IE9 and lower
			document.onfocusin = document.onfocusout = this.onVisibilityChange.bind(this)
		else // all others
			window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this.onVisibilityChange.bind(this)
		
		// set the initial state (but only if browser supports the Page Visibility API)
		if (document[hidden] !== undefined) {
			// console.log("document[hidden]: " + document[hidden])
			this.onVisibilityChange.bind(this)({type: document[hidden] ? "blur" : "focus"})
		}

		if (!window["WebSocket"])
			this.setState({error: "Your browser does not support WebSockets"})
	}

  componentDidUpdate(prevProps, prevState) {
		// console.log("componentDidUpdate()")
		// for (const prop in this.state) {
		// 	if (this.state[prop] === null)
		// 		console.log(prop + ": " + this.state[prop])
		// }
		// if (this.state.ws !== prevState.ws)
		// 	console.log("update(): ws")
		// if (this.state.hidden !== prevState.hidden)
		// 	console.log("update(): hidden")
		// if (this.state.json_user !== prevState.json_user)
		// 	console.log("update(): json_user")
		// if (this.state.season !== prevState.season)
		// 	console.log("update(): season")
		// if (this.state.week !== prevState.week)
		// 	console.log("update(): week = " + this.state.week)
		// if (this.state.arrActiveLeagues !== prevState.arrActiveLeagues)
		// 	console.log("update(): arrActiveLeagues")
		// if (this.state.mapLeagueUsers !== prevState.mapLeagueUsers)
		// 	console.log("update(): mapLeagueUsers")
		// if (this.state.mapLeagueRosters !== prevState.mapLeagueRosters)
		// 	console.log("update(): mapLeagueRosters")
		// if (this.state.arrPastSeasonsByLeagueID !== prevState.arrPastSeasonsByLeagueID)
		// 	console.log("update(): arrPastSeasonsByLeagueID")
		// if (this.state.arrDraftIDs !== prevState.arrDraftIDs)
		// 	console.log("update(): arrDraftIDs")
		// if (this.state.mapDraftsByYear !== prevState.mapDraftsByYear)
		// 	console.log("update(): mapDraftsByYear")
		// if (this.state.mapDraftPicksByDraftID !== prevState.mapDraftPicksByDraftID)
		// 	console.log("update(): mapDraftPicksByDraftID")
		// if (this.state.players !== prevState.players)
		// 	console.log("update(): players = " + this.state.players)
		// if (this.state.leagueID !== prevState.leagueID)
		// 	console.log("update(): leagueID")
		// if (this.state.leagueName !== prevState.leagueName)
		// 	console.log("update(): leagueName")
		// if (this.state.leagueDescription !== prevState.leagueDescription)
		// 	console.log("update(): leagueDescription")
		// if (this.state.isKeeper !== prevState.isKeeper)
		// 	console.log("update(): isKeeper")
		// if (this.state.leagueRules !== prevState.leagueRules)
		// 	console.log("update(): leagueRules")
		// if (this.state.playoffWeekStart !== prevState.playoffWeekStart)
		// 	console.log("update(): playoffWeekStart")

		if (this.state.leagueID !== prevState.leagueID) {
			if (this.state.leagueID) {
				this.httpGetLeagueUsers(this.state.leagueID)
				this.httpGetLeagueRosters(this.state.leagueID)
				this.httpGetPastSeasonsForThisLeague(this.state.leagueID)
			}
			else
				new Promise(
					(resolve) => {
						this.setState({
							mapLeagueUsers: null,
							mapLeagueRosters: null,
							arrPastSeasonsByLeagueID: null,
						})
						resolve(true)
					}
				).then(() => {})
		}

		if (
			this.state.leagueID !== prevState.leagueID ||
			this.state.arrActiveLeagues !== prevState.arrActiveLeagues ||
			this.state.arrPastSeasonsByLeagueID !== prevState.arrPastSeasonsByLeagueID
		) {
			if (this.state.leagueID && this.state.arrActiveLeagues && this.state.arrPastSeasonsByLeagueID)
				this.httpGetDraftIDs(this.state.leagueID, this.state.arrActiveLeagues, this.state.arrPastSeasonsByLeagueID)
			else
				new Promise(
					(resolve) => {
						this.setState({
							arrDraftIDs: null,
						})
						resolve(true)
					}
				).then(() => {})
		}

		if (this.state.mapLeagueRosters !== prevState.mapLeagueRosters) {
			if (this.state.mapLeagueRosters)
				this.httpGetPlayers(this.state.mapLeagueRosters)
			else
				new Promise(
					(resolve) => {
						this.setState({players: null})
						resolve(true)
					}
				).then(() => {})
		}

		if (this.state.arrDraftIDs !== prevState.arrDraftIDs) {
			if (this.state.arrDraftIDs) {
				this.httpGetDraftsByYear(this.state.arrDraftIDs)
				this.httpGetDraftPicksByYear(this.state.arrDraftIDs)
			}
			else
				new Promise(
					(resolve) => {
						this.setState({
							mapDraftsByYear: null,
							mapDraftPicksByDraftID: null,
						})
						resolve(true)
					}
				).then(() => {})
		}
  }

	onVisibilityChange(event) {
		var evtMap = {
			focus: "visible",
			focusin: "visible",
			pageshow: "visible",
			blur: "hidden",
			focusout: "hidden",
			pagehide: "hidden",
		}

		var visibility
		event = event || window.event
		if (event.type in evtMap)
			visibility = evtMap[event.type]
		else
			visibility = document[hidden] ? "hidden" : "visible"
		
		// console.log("visibility: " + visibility)
		if (this.state.ws !== null && visibility === "hidden") {
			this.state.ws.close()
			this.setState({hidden: true, ws: null})
		}
		else if (this.state.ws === null && visibility === "visible") {
			var ws = new WebSocket(this.getWebSocketURI())
			this.setState({hidden: false, ws: ws})
			this.wss(ws)
		}
	}

  wss(ws) {
		// ws.onopen = (event) => {
		// 	console.log("WebSocket OPEN")
		// }
		ws.onclose = (event) => {
			// console.log("WebSocket CLOSED")
			this.setState({ws: null})
		}
		ws.onerror = (event) => {
			this.setState({error: "WebSocket error: " + event.data})
		}
		ws.onmessage = (event) => {
			console.log("WebSocket msg from server: " + event.data)
			// var messages = event.data.split('\n')
			// for (var i = 0; i < messages.length; i++)
			// 	console.log(messages[i])
		}
	}

	getWebSocketURI() {
		var uri = "ws://"
		if (document.location.protocol === "https:")
			uri = "wss://"
		uri += document.location.host + "/a/wss"
		return uri
	}

	//////////////////////////////////////////////////////////////////////////////////////////////////////////

	handlerUpdateUser(data) {
		if (data.error)
			this.setState({error: data.error})
		else {
			new Promise(
        (resolve) => {
          this.setState(
            {
              error: null,
              json_user: data.user
            }
          )
          resolve(true)
			  }
      ).then(
        () => {
					this.httpGetState()
        }
			).then(() => {})
		}
	}

  handlerUpdateLeagueID() {
    var leagueID = fn.getParamVal("leagueID")
		// console.log("handlerUpdateLeagueID() " + leagueID)

    if (leagueID === this.state.leagueID)
			return

		new Promise(
			(resolve) => {
				this.setState({
					mapLeagueUsers: null,
					mapLeagueRosters: null,
					arrPastSeasonsByLeagueID: null,
					arrDraftIDs: null,
					mapDraftsByYear: null,
					mapDraftPicksByDraftID: null,

					leagueID: null,
					leagueName: null,
					leagueDescription: null,
					isKeeper: false,
					leagueRules: null,
					playoffWeekStart: null,
					players: null,
				})
				resolve(true)
			}
		).then(
			() => {
				var leagueName = null
				var leagueDescription = null
		
				if (this.state.arrActiveLeagues)
					for (const item of this.state.arrActiveLeagues) {
						if (item.league_id === leagueID) {
							leagueName = item.name
							leagueDescription = this.generateLeagueDescription(item)
							break
						}
					}
				
				if (leagueName === null) {
					this.setState({error: "League name not found... Redirecting..."})
					fn.sleep(5000).then(() => {window.location.href = "/a/Sleeper"})
					return
				}

				this.setState({
					leagueID: leagueID,
					leagueName: leagueName,
					leagueDescription: leagueDescription
				})
			}
		).then(
			() => {
				if (this.state.json_user && this.state.season)
					this.httpGetLeaguesForUser(this.state.json_user.sleeperID, this.state.season)
			}
		).then(
			() => {
				this.httpGetLeagueRules(leagueID)
			}
		).then(() => {})
  }

	handlerUpdateView() {
		// console.log("handlerUpdateView()")
		new Promise(
			(resolve) => {
				this.setState({
					arrActiveLeagues: null,
					mapLeagueUsers: null,
					mapLeagueRosters: null,
					arrPastSeasonsByLeagueID: null,
					arrDraftIDs: null,
					mapDraftsByYear: null,
					mapDraftPicksByDraftID: null,

					leagueID: null,
					leagueName: null,
					leagueDescription: null,
					isKeeper: false,
					leagueRules: null,
					playoffWeekStart: null,
				})
				resolve(true)
			}
		).then(
			() => {
				this.httpGetState()
			}
		).then(() => {})
	}

	handlerError(error) { this.setState({error: error}) }

  handleClickCloseDialog() { this.setState({ error: null }) }

	//////////////////////////////////////////////////////////////////////////////////////////////////////////

	async httpGetState() {
		try {
			var URL = "https://api.sleeper.app/v1/state/nfl"
			const resp = await fetch(
				URL,
				{
					method: 'GET',
				}
			)
	
			const json = await resp.json()
			console.log("json: ")
			console.log(json)
			
			if (json.season && json.week !== null) {
				var season = parseInt(json.season)
				var week = parseInt(json.week)
				if (json.season_type) {
					if (json.season_type === "pre")
						week = 1
					else if (json.season_type !== "regular") {
						week = 15
						if (json.season_type === "off")
							season = parseInt(json.previous_season)
					}
				}
				
				new Promise(
					(resolve) => {
						this.setState({
							season: season,
							week: week,
						})
						resolve(true)
					}
				).then(
					this.httpGetLeaguesForUser(this.state.json_user.sleeperID, season)
				)
			}
			else
				this.setState({
					error: "Empty result for '" + URL + "'",
					season: null,
					week: null
				})
		} catch(error) {
			console.error('Error: ', error)
			this.setState({ error: "Something went wrong... Please refresh the page." })
		}
	}

	httpGetLeaguesForUser(sleeperID, season) {
		// console.log("httpGetLeaguesForUser()")
    var URL = "https://api.sleeper.app/v1/user/" + sleeperID + "/leagues/nfl/" + season

    fetch(
			URL, {
				method: 'GET',
			}
		).then(
			(resp) => {
				return resp.json()
			}
		).then(
			(data) => {
				if (data && data.length > 0) {
					var _leagueID = null
					var _playoffWeekStart = null
					var _leagueName = null
					var _leagueDescription = null

					var paramVal = fn.getParamVal("leagueID")
					var arrActiveLeagues = []
					data.forEach(
						(item) => {
							arrActiveLeagues.push(item)
						}
					)

					arrActiveLeagues.forEach(
						(item) => {
							if (item.league_id === paramVal) {
								_leagueID = item.league_id
								_playoffWeekStart = item.settings.playoff_week_start
								_leagueName = item.name
								_leagueDescription = this.generateLeagueDescription(item)
								return
							}
						}
					)

					if (_leagueID === null) {
						if (arrActiveLeagues.length > 0)
							window.location.href = "/a/Sleeper?leagueID=" + arrActiveLeagues[0].league_id
						else
							window.location.href = "/404"
					}

					new Promise(
						(resolve) => {
							// console.log("updating leagueID")
							this.setState({
								leagueID: _leagueID,
								playoffWeekStart: _playoffWeekStart,
								leagueName: _leagueName,
								leagueDescription: _leagueDescription,
								arrActiveLeagues: arrActiveLeagues
							})
							resolve(true)
						}
					).then(() => {
						if (this.state.leagueID)
							this.httpGetLeagueRules(this.state.leagueID)
					})
				}
				else
					this.setState({
						error: "Empty result for '" + URL + "'",
						arrActiveLeagues: null,
				})
    	}
		).then(
			() => {}
		).catch(
			(error) => {
				console.error('Error:', error)
				this.setState({ error: "Something went wrong... Please refresh the page." })
			}
		)
	}

  httpGetLeagueUsers(leagueID) {
		// console.log("httpGetLeagueUsers()")
    var URL = "https://api.sleeper.app/v1/league/" + leagueID + "/users"

    fetch(
			URL, {
				method: 'GET',
			}
		)
    .then(
			(resp) => {
				return resp.json()
			}
		)
    .then(
			(data) => {
				if (data && data.length > 0) {
					var mapLeagueUsers = new Map()
					data.forEach(
						(item) => {
							mapLeagueUsers.set(item.user_id, item)
						}
					)

					new Promise(
						(resolve) => {
							this.setState({ mapLeagueUsers: mapLeagueUsers })
							resolve(true)
						}
					).then(() => {})
				}
				else
					this.setState({
						error: "Empty result for '" + URL + "'",
						mapLeagueUsers: null
				})
			}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.setState({ error: "Something went wrong... Please refresh the page." })
			}
		)
  }

  httpGetLeagueRosters(leagueID) {
    var URL = "https://api.sleeper.app/v1/league/" + leagueID + "/rosters"

    fetch(
			URL, {
				method: 'GET',
			}
		)
    .then(
			(resp) => {
				return resp.json()
			}
		)
    .then(
			(data) => {
				if (data && data.length > 0) {
					var mapLeagueRosters = new Map()
					data.forEach(
						(item) => {
							mapLeagueRosters.set(item.owner_id, item)
						}
					)
					new Promise(
						(resolve) => {
							this.setState({ mapLeagueRosters: mapLeagueRosters })
							resolve(true)
						}
					).then(() => {})
				}
				else
					this.setState({
						error: "Empty result for '" + URL + "'",
						mapLeagueRosters: null
				})
			}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.setState({ error: "Something went wrong... Please refresh the page." })
			}
		)
  }

	httpGetPastSeasonsForThisLeague(leagueID) {
		this.fetchLeagueForArrPastSeasons(leagueID, null)
	}

	fetchLeagueForArrPastSeasons(leagueID, arrPastSeasonsByLeagueID) {
    var URL = "https://api.sleeper.app/v1/league/" + leagueID

		if (arrPastSeasonsByLeagueID === null)
			arrPastSeasonsByLeagueID = []

    fetch(
			URL, {
				method: 'GET',
			}
		).then(
			(resp) => {
				return resp.json()
			}
		).then(
			(data) => {
				if (data) {
          if (data.status === "complete")
            arrPastSeasonsByLeagueID.push({
              leagueID: data.league_id,
              league: data,
            })
					
					new Promise(
						(resolve) => {
							if (data.hasOwnProperty('previous_league_id') && data.previous_league_id != null && data.previous_league_id !== "0")
								this.fetchLeagueForArrPastSeasons(data.previous_league_id, arrPastSeasonsByLeagueID)
							else
								this.setState({arrPastSeasonsByLeagueID: arrPastSeasonsByLeagueID})
							resolve(true)
						}
					).then(() => {})
				}
				else {
					this.setState({
						error: "Empty result for '" + URL + "'",
						arrPastSeasonsByLeagueID: null,
					})
				}
    	}
		).catch(
			(error) => {
				console.error('Error:', error)
				this.setState({
					error: "Something went wrong... Please refresh the page.",
					arrPastSeasonsByLeagueID: null,
				})
			}
		)
	}

  httpGetDraftIDs(leagueID, arrActiveLeagues, arrPastSeasonsByLeagueID) {
		var arrLeagues = []
		for (var i = 0; i < arrActiveLeagues.length; i++) {
			if (arrActiveLeagues[i].league_id === leagueID) {
				arrLeagues.push(arrActiveLeagues[i])
				break
			}
		}
		for (var i = 0; i < arrPastSeasonsByLeagueID.length; i++) {
			var exists = false
			for (var j = 0; j < arrLeagues.length; j++) {
				if (arrLeagues[j].league_id === arrPastSeasonsByLeagueID[i].league.league_id) {
					exists = true
					break
				}
			}

			if (exists)
				continue
			
			arrLeagues.push(arrPastSeasonsByLeagueID[i].league)
		}
		
		this.fetchDraftIDs(arrLeagues, 0, null)
  }

	fetchDraftIDs(arrLeagues, index, arrDraftIDs) {
    var URL = "https://api.sleeper.app/v1/league/" + arrLeagues[index].league_id + "/drafts"

		if (arrDraftIDs === null)
			arrDraftIDs = []

    fetch(
			URL, {
      	method: 'GET',
    	}
		)
    .then(
			(resp) => {
      	return resp.json()
    	}
		)
    .then(
			(data) => {
				if (data && data.length > 0) {
					for (const draft of data)
						if (draft.status === "complete")
							arrDraftIDs.push(draft.draft_id)

						new Promise(
							(resolve) => {
								if (index + 1 < arrLeagues.length)
									this.fetchDraftIDs(arrLeagues, index + 1, arrDraftIDs)
								else if (arrDraftIDs.length === 0)
									this.setState({arrDraftIDs: null})
								else
									this.setState({arrDraftIDs: arrDraftIDs})
								resolve(true)
							}
						).then(() => {})
				}
				else
					this.setState({
						error: "Empty result for '" + URL + "'",
						mapDraftsByYear: null
				})
    	}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.setState({ error: "Something went wrong... Please refresh the page." })
			}
		)
	}

	httpGetDraftsByYear(arrDraftIDs) {
		this.fetchDraftsByYear(arrDraftIDs, 0, null)
	}

  fetchDraftsByYear(arrDraftIDs, index, mapDraftsByYear) {
    var URL = "https://api.sleeper.app/v1/draft/" + arrDraftIDs[index]

		if (mapDraftsByYear === null)
			mapDraftsByYear = new Map()

    fetch(
			URL, {
      	method: 'GET',
    	}
		)
    .then(
			(resp) => {
      	return resp.json()
    	}
		)
    .then(
			(data) => {
				if (data && data !== null) {
					var season = parseInt(data.season)

					if (!mapDraftsByYear.has(season))
						mapDraftsByYear.set(season, [data])
					else {
						var arrDrafts = mapDraftsByYear.get(season)
						arrDrafts.push(data)
						mapDraftsByYear.set(season, arrDrafts)
					}

					new Promise(
						(resolve) => {
							if (index + 1 < arrDraftIDs.length)
								this.fetchDraftsByYear(arrDraftIDs, index + 1, mapDraftsByYear)
							else
								this.setState({mapDraftsByYear: mapDraftsByYear})
							resolve(true)
							}
						).then(() => {})
				}
				else
					this.setState({
						error: "Empty result for '" + URL + "'",
						mapDraftsByYear: null
					})
    	}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.setState({ error: "Something went wrong... Please refresh the page." })
			}
		)
  }

	httpGetDraftPicksByYear(arrDraftIDs) {
		this.fetchDraftPicks(arrDraftIDs, 0, null)
	}

	fetchDraftPicks(arrDraftIDs, index, mapDraftPicksByDraftID) {
    var URL = "https://api.sleeper.app/v1/draft/" + arrDraftIDs[index] + "/picks"

		if (mapDraftPicksByDraftID === null)
			mapDraftPicksByDraftID = new Map()

    fetch(
			URL, {
      	method: 'GET',
    	}
		)
    .then(
			(resp) => {
      	return resp.json()
    	}
		)
    .then(
			(data) => {
				if (data && data.length > 0) {
					mapDraftPicksByDraftID.set(arrDraftIDs[index], data)

					new Promise(
						(resolve) => {
							if (index + 1 < arrDraftIDs.length)
								this.fetchDraftPicks(arrDraftIDs, index + 1, mapDraftPicksByDraftID)
							else
								this.setState({ mapDraftPicksByDraftID: mapDraftPicksByDraftID })
							resolve(true)
						}
					).then(() => {})
				}
				else
					this.setState({
						error: "Empty result for '" + URL + "'",
						mapDraftPicksByDraftID: null
				})
    	}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.setState({ error: "Something went wrong... Please refresh the page." })
			}
		)
	}

	//////////////////////////////////////////////////////////////////////////////////////////////////////////

  httpGetPlayers(mapLeagueRosters) {
		// console.log("httpGetPlayers()")
    var playerIDs = []
    mapLeagueRosters.forEach(
			(item) => {
				if (item && item.hasOwnProperty('players') && Array.isArray(item.players))
					for (const next of item.players)
						playerIDs.push(parseInt(next))
    	}
		)
    
    var _this = this
    var URL = "/a/rest/sleeper/players"

    fetch(
			URL, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
				body: JSON.stringify(playerIDs),
			}
		)
    .then(
			(resp) => {
				return resp.json()
			}
		)
    .then(
			(data) => {
				if (data.hasOwnProperty("error"))
					_this.setState({error: data.error})
				else if (data.hasOwnProperty("players") && data.players !== "" && data.players.length > 0) {
					var map = new Map()
					data.players.forEach(
						(item) => {
							map.set(item.id, item.info)
						}
					)
					_this.setState({players: map})
				}
				else
					_this.setState({players: null})
			}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				_this.setState({error: "Something went wrong... Please refresh the page.", players: null})
			}
		)
  }

  httpGetLeagueRules(leagueID) {
		// console.log("httpGetLeagueRules()")
    if (!leagueID || leagueID === null)
      return
    
    var URL = "/a/rest/sleeper/leagueRules/" + leagueID

    fetch(
			URL, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
			}
		)
    .then(
			(resp) => {
				return resp.json()
			}
		)
    .then(
			(data) => {
				if (data.hasOwnProperty("error"))
					this.setState({error: data.error, leagueRules: null})
				else if (data.hasOwnProperty("rules") && data.rules !== "")
					this.setState({leagueRules: data.rules})
				else
					this.setState({leagueRules: null})
			}
		).then(() => {})
    .catch(
			(error) => {
				console.error('Error:', error)
				this.setState({error: "Something went wrong... Please refresh the page.", leagueRules: null})
			}
		)
  }

  generateLeagueDescription(league) {
    var output = []

    output.push(league.season) // year
    output.push(league.settings.num_teams + "-Team") // number of teams
    
    if (league.settings.type === 1) {
      this.setState({isKeeper: true})
      output.push("Keeper")
    }
    else if (league.settings.type === 2)
      output.push("Dynasty")
    
    if (league.settings.type !== 1)
      this.setState({isKeeper: false})
    
		if (league.scoring_settings.rec === 0.0)
			output.push("Standard")
    else if (league.scoring_settings.rec === 0.5)
      output.push("Half-PPR")
    else if (league.scoring_settings.rec === 1.0)
			output.push("PPR")
		
		return output.join(' ')
  }

	//////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
			<div id="Sleeper">
				{this.state.error ? <AlertDialog header="Error" text={this.state.error} onClick={this.handleClickCloseDialog.bind(this)} /> : null}

        <BrowserRouter>
          <User handlerUpdateUser={this.handlerUpdateUser.bind(this)} />
          <Header
						user={this.state.json_user}
						mapLeagueUsers={this.state.mapLeagueUsers}
            leagueName={this.state.leagueName}
            leagueRules={this.state.leagueRules}
            arrActiveLeagues={this.state.arrActiveLeagues}
            handlerError={this.handlerError.bind(this)}
            handleChangeSelectLeague={this.handlerUpdateLeagueID.bind(this)}
            handleChangeUpdateView={this.handlerUpdateView.bind(this)}
          />

          <div class="Sleeper_container">
            <Routes>
              <Route path="/a/Sleeper/Rosters" element={
                <Rosters
									season={this.state.season}
                  mapLeagueUsers={this.state.mapLeagueUsers}
                  mapLeagueRosters={this.state.mapLeagueRosters}
                  players={this.state.players}
									leagueID={this.state.leagueID}
									mapDraftsByYear={this.state.mapDraftsByYear}
									mapDraftPicksByDraftID={this.state.mapDraftPicksByDraftID}
                  isKeeper={this.state.isKeeper}
                  handlerError={this.handlerError.bind(this)}
                />
							} />
              <Route path="/a/Sleeper/DraftResults" element={
								<DraftResults
									season={this.state.season}
                  mapLeagueUsers={this.state.mapLeagueUsers}
                  mapLeagueRosters={this.state.mapLeagueRosters}
									mapDraftsByYear={this.state.mapDraftsByYear}
									mapDraftPicksByDraftID={this.state.mapDraftPicksByDraftID}
                  isKeeper={this.state.isKeeper}
                  handlerError={this.handlerError.bind(this)}
								/>
							} />
              <Route path="/a/Sleeper/PastSeasons" element={
								<PastSeasons
									leagueID={this.state.leagueID}
									arrPastSeasonsByLeagueID={this.state.arrPastSeasonsByLeagueID}
                  mapLeagueRosters={this.state.mapLeagueRosters}
                  mapLeagueUsers={this.state.mapLeagueUsers}
                  handlerError={this.handlerError.bind(this)}
								/>
							} />
              <Route path="/a/Sleeper/LeagueRules" element={
                <LeagueRules
									primaryText={this.state.leagueName ? 'League rules for "' + this.state.leagueName + '"' : 'League rules'}
                  secondaryText={this.state.leagueDescription ? this.state.leagueDescription : null}
                  body={this.state.leagueRules}
                />
							} />
              <Route path="/a/Sleeper" element={
								<Home
									leagueID={this.state.leagueID}
									playoffWeekStart={this.state.playoffWeekStart}
									season={this.state.season}
									week={this.state.week}
                  players={this.state.players}
                  mapLeagueRosters={this.state.mapLeagueRosters}
                  mapLeagueUsers={this.state.mapLeagueUsers}
                  handlerError={this.handlerError.bind(this)}
								/>
							} />
            </Routes>
          </div>
        </BrowserRouter>
			</div>
    )
  }
}