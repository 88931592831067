import React from 'react'

import './Input.css'

import MaterialIcons from '../../icons/MaterialIcons/MaterialIcons.jsx'

export default class Input extends React.Component {
  constructor(props) {
		super(props)
    
    this.state = {
      focused: false,
      notEmpty: false,
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown.bind(this))

    if (document.getElementById(this.props.id).value || this.props.defaultValue)
      this.setState({notEmpty: true})
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const defaultValue = this.props.defaultValue
      // if (defaultValue !== prevProps.defaultValue && defaultValue && !element.defaultValue) {
      if (defaultValue !== prevProps.defaultValue && defaultValue && !this.state.notEmpty) {
        // document.getElementById(this.props.id).defaultValue = defaultValue
        this.setState({notEmpty: true})
      }
      
      if (!this.state.notEmpty && document.getElementById(this.props.id).value)
        this.setState({notEmpty: true})
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this))
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  handleKeyDown(event) {
    if (!this.state.focused)
      return
    
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        return this.props.onEnter ? this.props.onEnter() : null
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onFocus(e) {
    if (e.currentTarget.contains(document.activeElement))
      this.setState({ notEmpty: true, focused: true })
    else
      this.setState({ focused: true })
  }
  onBlur(e) {
    if (!e.currentTarget.contains(document.activeElement) && !document.getElementById(this.props.id).value)
      this.setState({ notEmpty: false, focused: false })
    else
      this.setState({ focused: false })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getClassWrapper() {
    var output = []

    output.push("Input_Wrapper")
    if (this.props.outline)
      output.push("outline")
    else if (this.props.textarea)
      output.push("textarea")
    
    if (this.state.focused)
      output.push("focused")

    return output.join(" ")
  }

  getClassPrefix() {
    var output = []

    output.push("Input_Prefix")
    if (this.props.outline)
      output.push("outline")
    else if (this.props.textarea)
      output.push("textarea")
    
    if (this.props.label)
      output.push("label")
    
    if (this.state.focused)
      output.push("focused")
    if (this.state.notEmpty)
      output.push("notEmpty")
    
    return output.join(" ")
  }

  getClassInput() {
    var output = []

    output.push("Input_input")
    if (this.props.outline)
      output.push("outline")
    else if (this.props.textarea)
      output.push("textarea")
    
    if (this.props.label)
      output.push("label")
    if (this.props.trailingIcon || this.props.errorText)
      output.push("trailingIcon")
    if (this.props.errorText)
      output.push("error")
    if (this.props.prefix)
      output.push("prefix")
    
    return output.join(" ")
  }

  getClassLabel() {
    var output = []

    output.push("Input_Label")
    if (this.props.outline)
      output.push("outline")
    else if (this.props.textarea)
      output.push("textarea")
    
    if (this.state.focused)
      output.push("focused")
    else if (this.state.notEmpty)
      output.push("notEmpty")
    
    if (this.props.errorText)
      output.push("error")

    return output.join(" ")
  }

  getClassNotch() {
    var output = []

    output.push("Input_Outline_notch")
    if (this.props.outline)
      output.push("outline")
    
    if (this.state.focused)
      output.push("focused")
    if (this.state.notEmpty)
      output.push("notEmpty")

    return output.join(" ")
  }

  getStyleNotch() {
    const elem = document.getElementById("Input_Label_" + this.props.id)
    if ((this.state.focused || this.state.notEmpty) && elem)
      return {width: elem.scrollWidth * 0.75 + 8 + "px"}
    return {}
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div class="Input">
        <div class={this.getClassWrapper()}>
          {
            this.props.errorText && !this.props.textarea ? 
            <div class="Input_TrailingIconWrapper">
              <MaterialIcons size="24" color="var(--err-color)">error</MaterialIcons>
            </div>
            : null
          }
          {
            this.props.textarea ?
            <textarea
              class={this.getClassInput()}
              style={this.props.minHeight ? {minHeight: this.props.minHeight} : null}
  
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur.bind(this)}

              id={this.props.id}
              name={this.props.name}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue}
              maxLength={this.props.maxLength ? this.props.maxLength : "524288"}
              onKeyPress={this.props.onKeyPress}
              tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
              onChange={this.props.onChange}
            />
            :
            <React.Fragment>
              {
                this.props.prefix ?
                <div class={this.getClassPrefix()} data-prefix={this.props.prefix} />
                : null
              }
              <input
                class={this.getClassInput()}
                autoCorrect="off"
                autoComplete="off"
                spellCheck="false"
    
                onFocus={this.onFocus.bind(this)}
                onBlur={this.onBlur.bind(this)}
    
                type={this.props.type ? this.props.type : "text"}
                id={this.props.id}
                name={this.props.name}
                placeholder={this.props.placeholder}
                defaultValue={this.props.defaultValue}
                maxLength={this.props.maxLength ? this.props.maxLength : "524288"}
                onKeyPress={this.props.onKeyPress}
                tabIndex={this.props.tabIndex ? this.props.tabIndex : "0"}
                onChange={this.props.onChange}
              />
            </React.Fragment>
          }
          {
            this.props.outline || this.props.textarea ?
            <div class="Input_Outline">
              <div class={this.state.focused ? "Input_Outline_leading focused" : "Input_Outline_leading"} />
              {
                this.props.label ?
                <div class={this.getClassNotch()} style={this.getStyleNotch()}>
                  <label class={this.getClassLabel()} id={"Input_Label_" + this.props.id}>
                    {this.props.label ? (this.props.errorText ? this.props.label + "*" : this.props.label) : null}
                  </label>
                </div>
                : null
              }
              <div class={this.state.focused ? "Input_Outline_trailing focused" : "Input_Outline_trailing"} />
            </div>
            : null
          }
          {
            this.props.outline || this.props.textarea ? null :
            <div class={this.state.focused ? (this.props.errorText ? "Input_Line focused error" : "Input_Line focused") : "Input_Line"} />
          }
          {
            this.props.outline || this.props.textarea ? null :
            <label class={this.getClassLabel()}>
              {this.props.label ? (this.props.errorText ? this.props.label + "*" : this.props.label) : null}
            </label>
          }
        </div>
        {
          this.props.helperText || this.props.errorText ?
          <div class="Input_HelperTextWrapper">
            <div class={this.props.errorText ? "Input_HelperText error" : "Input_HelperText"}>
              {this.props.errorText ? this.props.errorText : this.props.helperText}
            </div>
          </div>
          : null
        }
      </div>
    )
  }
}