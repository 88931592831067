import * as fnSort from '../../../utils/functions/sort.js'
import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './WeeklySummary.css'

import MaterialIcons from '../../../components/icons/MaterialIcons/MaterialIcons.jsx'

import Matchup from './Matchup.jsx'
import User from '../components/User.jsx'

export default class WeeklySummary extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			expand: false,
			scoringLeaderOwnerIDs: null,
			scoringLoserOwnerIDs: null,
			scoringLeaderPoints: null,
		}
  }

	componentDidMount() {
		if (this.props.isCurrentWeek)
			this.setState({expand: true})
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.week !== prevProps.week ||
			this.props.mapScoringLeaderByWeek !== prevProps.mapScoringLeaderByWeek ||
			this.props.mapScoringLoserByWeek !== prevProps.mapScoringLoserByWeek
		) {
			if (
				this.props.week &&
				this.props.mapScoringLeaderByWeek &&
				this.props.mapScoringLeaderByWeek.has(this.props.week) &&
				this.props.mapScoringLoserByWeek.has(this.props.week) &&
				this.props.isCurrentWeek !== null
			) {
				new Promise(
					(resolve) => {
						this.setState({
							expand: this.props.isCurrentWeek,
							scoringLeaderOwnerIDs: this.props.mapScoringLeaderByWeek.get(this.props.week).ownerIDs,
							scoringLoserOwnerIDs: this.props.mapScoringLoserByWeek.get(this.props.week).ownerIDs,
							scoringLeaderPoints: this.props.mapScoringLeaderByWeek.get(this.props.week).points,
						})
						resolve(true)
					}
				).then(() => {})
			} else {
				new Promise(
					(resolve) => {
						this.setState({
							scoringLeaderOwnerIDs: null,
							scoringLoserOwnerIDs: null,
							scoringLeaderPoints: null,
						})
						resolve(true)
					}
				).then(() => {})
			}
		}
	}

	handleClickExpand() {
		new Promise(
			(resolve) => {
				this.setState({expand: !this.state.expand})
				resolve(true)
			}
		).then(() => {})
	}

	htmlMatchups(week, mapMatchupsByWeek, mapLeagueRosters, mapLeagueUsers, scoringLeaderOwnerIDs, scoringLoserOwnerIDs, isCurrentWeek) {
		if (
			typeof week === "undefined" ||
			typeof mapMatchupsByWeek === "undefined" ||
			typeof mapLeagueRosters === "undefined" ||
			typeof mapLeagueUsers === "undefined" ||
			typeof scoringLeaderOwnerIDs === "undefined" ||
			typeof scoringLoserOwnerIDs === "undefined" ||
			typeof isCurrentWeek === "undefined" ||
			week === null ||
			mapMatchupsByWeek === null ||
			mapLeagueRosters === null ||
			mapLeagueUsers === null ||
			scoringLeaderOwnerIDs === null ||
			scoringLoserOwnerIDs === null ||
			isCurrentWeek === null
		)
			return null
		
		var matchups = mapMatchupsByWeek.get(week)

		if (matchups === null || typeof matchups === "undefined")
			return null
		
		var matchupIDs = []
		var mapMatchupsByID = new Map()

		for (const obj of matchups) {
			var map = new Map()

			if (mapMatchupsByID.has(obj.matchup_id)) {
				map = mapMatchupsByID.get(obj.matchup_id)
				map.set("B", obj)
			} else {
				map.set("A", obj)
				matchupIDs.push(obj.matchup_id)
			}

			mapMatchupsByID.set(obj.matchup_id, map)
		}

		if (matchupIDs.length === 0)
			return null

		matchupIDs = fnSort.sortInt(matchupIDs)
		
		var html = []

		for (const id of matchupIDs) {
			var map = mapMatchupsByID.get(id)
			// console.log("["+id+"] A: " + map.get("A") + ", B: " + map.get("B"))
			html.push(
				<Matchup
					matchup={map}
					mapLeagueRosters={mapLeagueRosters}
					mapLeagueUsers={mapLeagueUsers}
					scoringLeaderOwnerIDs={scoringLeaderOwnerIDs}
					scoringLoserOwnerIDs={scoringLoserOwnerIDs}
					isCurrentWeek={isCurrentWeek}
				/>
			)
		}
		
		return html
	}

	htmlScoringLeaders(ownerIDs, mapLeagueUsers) {
		var html = []

		if (ownerIDs.length > 0)
			for (const ownerID of ownerIDs) {
				html.push(
					<li class="WeeklySummary_text_team_wrapper">
						<User ownerID={ownerID} mapLeagueUsers={mapLeagueUsers} />
					</li>
				)
			}
		else
			html.push(
				<li class="WeeklySummary_text_team_wrapper">
					<object class="WeeklySummary_scoringLeader_avatar" type="image/png" />
					<div class="WeeklySummary_text team">-</div>
				</li>
			)

		return html
	}

	html() {
		var week = this.props.week
		var mapMatchupsByWeek = this.props.mapMatchupsByWeek
		var mapLeagueRosters = this.props.mapLeagueRosters
		var mapLeagueUsers = this.props.mapLeagueUsers
		var scoringLeaderOwnerIDs = this.state.scoringLeaderOwnerIDs
		var scoringLoserOwnerIDs = this.state.scoringLoserOwnerIDs
		var scoringLeaderPoints = this.state.scoringLeaderPoints
		var isCurrentWeek = this.props.isCurrentWeek
		if (
			week === null ||
			mapMatchupsByWeek === null ||
			mapLeagueRosters === null ||
			mapLeagueUsers === null ||
			scoringLeaderOwnerIDs === null ||
			scoringLeaderPoints === null ||
			isCurrentWeek === null
		)
			return

		var html = []

		html.push(
			<React.Fragment>
				<div class={isCurrentWeek ? "hidden" : "WeeklySummary_summary_wrapper"}>

					<span class="WeeklySummary_container left">
						<div class="WeeklySummary_week_display">
							<div class="WeeklySummary_text week">Week</div>
							<div class="WeeklySummary_text weekNum">{week}</div>
						</div>

						<div class="WeeklySummary_text_wrapper team">
							<div class="WeeklySummary_text category">{scoringLeaderOwnerIDs.length === 2 ? "Scoring leaders" : "Scoring leader"}</div>
							<div class="WeeklySummary_scoringLeaders_column">
								{this.htmlScoringLeaders(scoringLeaderOwnerIDs, mapLeagueUsers)}
							</div>
						</div>
					</span>

					<span class="WeeklySummary_container right">
						<div class="WeeklySummary_text_wrapper points">
							<div class="WeeklySummary_text category" style={{textAlign: 'right'}}>Points</div>
							<div class="WeeklySummary_text points">{fnSleeper.formatPoints(scoringLeaderPoints)}</div>
						</div>

						<div
							class={this.props.isCurrentWeek ? "WeeklySummary_expand hidden" : "WeeklySummary_expand"}
							onClick={this.props.isCurrentWeek ? null : this.handleClickExpand.bind(this)}
						>
							{
								this.props.isCurrentWeek ?
								null :
								this.state.expand ?
								<MaterialIcons size="24" dark>expand_less</MaterialIcons> :
								<MaterialIcons size="24" dark>expand_more</MaterialIcons>
							}
						</div>
					</span>

				</div>

				<div class={this.state.expand ? "WeeklySummary_matchups" : "hidden"} style={isCurrentWeek ? {marginTop: '0', paddingBottom: '16px'}: null}>
					<div class={isCurrentWeek ? "hidden" : "WeeklySummary_matchups_text primary"}>Matchups</div>
					<ul class="WeeklySummary_matchups_ul" style={isCurrentWeek ? {marginTop: '0'} : null}>
						{this.htmlMatchups(week, mapMatchupsByWeek, mapLeagueRosters, mapLeagueUsers, scoringLeaderOwnerIDs, scoringLoserOwnerIDs, isCurrentWeek)}
					</ul>
				</div>
			</React.Fragment>
		)

		return html
	}

  render() {
    return (
      <li class="WeeklySummary">
				{this.html()}
      </li>
    )
  }
}