import * as fn from '../../../utils/functions/sleeper.js'

import React from 'react'

import './CurrentMatchups.css'

import Card from '../../../components/card/Card/Card.jsx'
import WeeklySummary from './WeeklySummary.jsx'

export default class CurrentMatchups extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			//
		}
  }

	htmlBody() {
		var week = this.props.week
		if (week === null)
			return
		
		var html = []
		html.push(
			<WeeklySummary
				week={week}
				mapScoringLeaderByWeek={this.props.mapScoringLeaderByWeek}
				mapScoringLoserByWeek={this.props.mapScoringLoserByWeek}
				mapMatchupsByWeek={this.props.mapMatchupsByWeek}
				mapLeagueRosters={this.props.mapLeagueRosters}
				mapLeagueUsers={this.props.mapLeagueUsers}
				isCurrentWeek={true}
			/>
		)
		return html
	}

  render() {
    return (
      <div id="CurrentMatchups">
				<Card
					clear
					primaryText={this.props.week ? "Week " + this.props.week + " Matchups" : "Matchups"}
					secondaryText={this.props.season ? this.props.season + " Regular season" : "Regular season"}
					body={this.htmlBody()}
					bodyNoPadding
				/>
      </div>
    )
  }
}