import React from 'react'

import './ResetPassword.css'

import ResetPasswordForm from './ResetPasswordForm.jsx'

export default class ResetPassword extends React.Component {
  constructor(props) {
		super(props)
		
		this.state = {
			error: null,
    }
	}

  render() {
    return (
			<div id="ResetPassword">
				<div class="ResetPassword_container">
					<ResetPasswordForm />
				</div>
			</div>
    )
  }
}