import * as fn from '../../../../utils/functions/general.js'

import React from 'react'

import './HeaderMenu.css'

import MaterialIcons from '../../../icons/MaterialIcons/MaterialIcons.jsx'

import ModalNavigation from '../../../drawer/ModalNavigation/ModalNavigation.jsx'
import HeaderModalNav from '../HeaderModalNav/HeaderModalNav.jsx'

import Select from '../../../field/Select/Select.jsx'
import MenuElement from '../../../menu/MenuElement/MenuElement.jsx'
import Divider from '../../../divider/Divider/Divider.jsx'

export default class HeaderMenu extends React.Component {
  constructor(props) {
		super(props)
		
		this.state = {
      showMenu: false,
			path: null,
			scrollY: 0,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props !== prevProps && this.state.path != fn.getPathRemoveParams(fn.getPath()))
			this.setState({path: fn.getPathRemoveParams(fn.getPath())})
		
		if (this.state.showMenu !== prevState.showMenu) {
			if (this.state.showMenu) {
				var y = window.scrollY
				this.setState({ scrollY: y })
				document.body.style.setProperty('top', '-' + y + 'px')
				document.body.style.overflow = "hidden"
				document.body.style.position = "fixed"
			} else {
				document.body.style.overflow = null
				document.body.style.position = null
				document.body.style.top = null
				window.scrollTo(0, this.state.scrollY)
			}
		}
	}

  handleClickMenu() {
    new Promise((resolve) => {
      this.setState({showMenu: !this.state.showMenu})
      resolve(true)
    }).then(() => {})
  }

	handlerClose() {
    new Promise((resolve) => {
      this.setState({showMenu: false})
      resolve(true)
    }).then(() => {})
	}

	handlerSelectOnChange() {
		new Promise((resolve) => {
			this.props.handleChangeSelectLeague()
			resolve(true)
		}).then(() => {
			fn.sleep(200).then(() => {this.setState({showMenu: false})})
		}).then(() => {})
	}

	invokeLink(pathname) {
		if (this.state.path !== pathname)
			new Promise(
				(resolve) => {
					this.setState({path: pathname})
					resolve(true)
				}
			).then(
				() => {
					this.props.handleChangeUpdateView()
				}
			).then(
				() => {
					fn.sleep(200).then(() => {this.setState({showMenu: false})})
					return true
				}
			).then(() => {})
	}

	htmlElems() {
		var output = []
		var _this = this
		
		var selectMap = new Map()
		if (this.props.arrActiveLeagues) {
			this.props.arrActiveLeagues.forEach(
				(item) => {
					selectMap.set(item.league_id, item.name)
				}
			)
		}
		
		if (selectMap.size > 0)
			output.push(
				<li class="HeaderMenu_menu_wrapper">
					<div class="Menu_li select" style={{paddingBottom: '8px'}}>
						<Select
							id="selectLeague"
							name="selectLeague"
							label="Select league"
							tabIndex="1"
							reactRouterLinkToPath="/a/Sleeper?leagueID="
							onChange={this.handlerSelectOnChange.bind(this)}
							map={selectMap}
							width="240px"
							// errorText={this.state.errorSelectLeague}
							defaultValue={fn.getParamVal("leagueID")}
							hideBlank
						/>
					</div>
				</li>
			)
		
		output.push(
			<Divider fullBleed={true} />
		)

		var paramVal = fn.getParamVal("leagueID")
		var menuElements = []
    menuElements.push({
      href: "/a/Sleeper",
			search: "?leagueID=" + paramVal,
      text: "League overview"
    })
    menuElements.push({
      href: "/a/Sleeper/Rosters",
			search: "?leagueID=" + paramVal,
      text: "Rosters"
    })
    menuElements.push({
      href: "/a/Sleeper/DraftResults",
			search: "?leagueID=" + paramVal,
      text: "Draft results"
    })
    menuElements.push({
      href: "/a/Sleeper/PastSeasons",
			search: "?leagueID=" + paramVal,
      text: "Past seasons"
    })
    if (this.props.leagueRules && this.props.leagueRules !== "")
      menuElements.push({
        href: "/a/Sleeper/LeagueRules",
				search: "?leagueID=" + paramVal,
        text: "League rules"
      })
		menuElements.forEach(function(item) {
			output.push(
				<MenuElement
					reactRouterLinkTo={{pathname: item.href, search: item.search}}
					reactRouterLinkOnClick={_this.invokeLink.bind(_this, item.href)}
					drawer
					selected={_this.state.path === fn.getPathRemoveParams(item.href) ? true : false}
					text={item.text}

					safeAreaLeft
				/>
			)
		})

		return output
	}

  render() {
    return (
			<React.Fragment>
				<div id="HeaderMenu_wrapper" onClick={this.props.drawer ? this.props.handlerClose.bind(this) : this.handleClickMenu.bind(this)}>
					<div id="HeaderMenu_container">
						<MaterialIcons size="24" color="var(--high-color)">menu</MaterialIcons>
					</div>
				</div>

				{
					this.props.drawer ?
					null :
					<ModalNavigation
						Header={<HeaderModalNav handlerClose={this.handlerClose.bind(this)} />}
						visible={this.state.showMenu}
						handlerClose={this.handlerClose.bind(this)}
						elems={this.htmlElems()}
					/>
				}
			</React.Fragment>
    )
  }
}