import * as fnSort from '../../utils/functions/sort.js'
// import * as fnSleeper from '../../utils/functions/sleeper.js'
import * as fnTime from '../../utils/functions/time.js'

import React from 'react'

import './DraftResults.css'

import DraftResult from './modules/DraftResult.jsx'

export default class DraftResults extends React.Component {
  constructor(props) {
    super(props)

		this.refDraftResults = React.createRef()
  }

	componentDidMount() {
		this.refDraftResults.current.scrollTo(0, 0)
	}

	html() {
		var season = this.props.season
		var mapLeagueUsers = this.props.mapLeagueUsers
		var mapLeagueRosters = this.props.mapLeagueRosters
		var mapDraftsByYear = this.props.mapDraftsByYear
		var mapDraftPicksByDraftID = this.props.mapDraftPicksByDraftID
		var isKeeper = this.props.isKeeper
		if (
			season === null ||
			mapLeagueUsers === null ||
			mapLeagueRosters === null ||
			mapDraftsByYear === null ||
			mapDraftPicksByDraftID === null ||
			isKeeper === null
		)
			return
		
		var mapStartTimesByYear = new Map()
		var arrDraftYears = []
		mapDraftsByYear.forEach(
			(arrDrafts, year) => {
				for (var i = 0; i < arrDrafts.length; i++) {
					if (!mapStartTimesByYear.has(year)) {
						mapStartTimesByYear.set(year, [arrDrafts[i].start_time])
						arrDraftYears.push(year)
					}
					else {
						var startTimes = mapStartTimesByYear.get(year)
						startTimes.push(arrDrafts[i].start_time)
						startTimes = fnSort.sortInt(startTimes).reverse()
						mapStartTimesByYear.set(year, startTimes)
					}
				}
			}
		)

		if (arrDraftYears.length > 0)
			arrDraftYears = fnSort.sortInt(arrDraftYears).reverse()
		
		var html = []
		
		// reverse chron
		for (const draftYear of arrDraftYears) {
			var startTimes = mapStartTimesByYear.get(draftYear)

			for (const startTime of startTimes) {
				if (!mapDraftsByYear.has(draftYear))
					continue
				
				const arrDrafts = mapDraftsByYear.get(draftYear)

				for (const draft of arrDrafts) {
					if (draft.start_time === startTime && mapDraftPicksByDraftID.has(draft.draft_id)) {
						var datetime = fnTime.unixTimeToDatetime(draft.start_time)

						var draftTypeIcon = null
						switch(draft.type) {
							case "auction":
								draftTypeIcon = "💰"
								break
							case "snake":
								draftTypeIcon = "🐍"
								break
							case "linear":
								draftTypeIcon = "↪️"
								break
						}
		
						var secondaryText = []
						secondaryText.push(
							<div style={{display: 'flex', flexDirection: 'column'}}>
								<div>{fnTime.getDateCondensedFromDatetime(datetime) + " " + fnTime.getTimeCondensedFromDatetime(datetime)}</div>
								<div class="DraftResults_draftTypeWrapper">
									<div class="DraftResults_draftType">{draft.type}</div>
									{
										draftTypeIcon ?
										<div class="DraftResults_draftTypeIcon">{draftTypeIcon}</div>
										: null
									}
								</div>
							</div>
						)
		
						var notAuction = true
						if (draft.type === "auction")
							notAuction = false
						
						var mapRosterIDBySlot = new Map()
						var arr = []
						for (const prop in draft.slot_to_roster_id) {
							if (draft.slot_to_roster_id[prop] !== null) {
								mapRosterIDBySlot.set(parseInt(prop), draft.slot_to_roster_id[prop])
								arr.push(parseInt(prop))
							}
						}
						arr = fnSort.sortInt(arr)
		
						var arrRosterIDBySlot = []
						for (var i = 0; i < arr.length; i++)
							arrRosterIDBySlot.push(mapRosterIDBySlot.get(arr[i]))
						
						var liRounds = []
						if (notAuction)
							for (var i = 1; i <= parseInt(draft.settings.rounds); i++)
								liRounds.push(
									<li class="DraftResult_draft_fixedColumn_text cell">{i}</li>
								)
						
						html.push(
							<DraftResult
								draft={draft}
								secondaryText={secondaryText}
								notAuction={notAuction}
								arrRosterIDBySlot={arrRosterIDBySlot}
								mapDraftPicksByDraftID={mapDraftPicksByDraftID}
								mapLeagueRosters={mapLeagueRosters}
								mapLeagueUsers={mapLeagueUsers}
								liRounds={liRounds}
								isKeeper={isKeeper}
							/>
						)
					}
				}
			}
		}

		return html
	}

  render() {
    return (
      <div id="DraftResults" ref={this.refDraftResults}>
				<ul class="DraftResults_wrapper">
					{this.html()}
				</ul>
      </div>
    )
  }
}