import React from 'react'
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
} from "react-router-dom";

import './MenuElement.css'

import MaterialIcons from '../../icons/MaterialIcons/MaterialIcons.jsx'
import Switch from '../../field/Switch/Switch.jsx'

export default class MenuElement extends React.Component {
  constructor(props) {
		super(props)
	}

	getClassMenuElement() {
		var output = []
		output.push("MenuElement")
		
		if (this.props.reactRouterLinkTo || this.props.href)
			output.push("pointer")

		if (this.props.safeAreaLeft)
			output.push("safeAreaLeft")

		if (this.props.drawer)
			output.push("drawer")
		
		if (this.props.selected)
			output.push("selected")
		
		return output.join(' ')
	}

	getClassMenuElement_primaryText() {
		var output = []
		output.push("MenuElement_primaryText")

		if (this.props.drawer)
			output.push("drawer")
		
		if (this.props.selected)
			output.push("selected")
		
		return output.join(' ')
	}

	getInput() {
		if (!this.props.switch)
			return
		
		var html = []

		html.push(
			<div class="MenuElement_wrapper3">
				<Switch
					initVal={this.props.switch === "1"}
					handler={this.props.handlerSwitch}
				/>
			</div>
		)
		
		return html
	}

	htmlElement() {
		var output = []
		output.push(
			<div class="MenuElement_wrapper1">
				<div class="MenuElement_wrapper2">
					<div class={this.props.leadingMaterialIcon ? "MenuElement_leadingIcon" : "hidden"}>
						<MaterialIcons size="24" color="var(--med-color)">{this.props.leadingMaterialIcon}</MaterialIcons>
					</div>
					<div class={this.getClassMenuElement_primaryText()}>
						{this.props.text}
					</div>
				</div>

				{this.getInput()}
			</div>
		)
		return output
	}

  render() {
    return (
			<li class={this.getClassMenuElement()} onClick={this.props.onClick}>
				{
					this.props.reactRouterLinkTo
					?
					<Link
						class={this.props.reactRouterLinkTo ? "MenuElement_a" : "hidden"}
						to={this.props.reactRouterLinkTo}
						onClick={this.props.reactRouterLinkOnClick.bind(this)}
					>
						{this.htmlElement()}
					</Link>
					:
					(
						this.props.href
						?
						<a class={this.props.reactRouterLinkTo ? "hidden" : "MenuElement_a"} href={this.props.href}>
							{this.htmlElement()}
						</a>
						:
						<div class="MenuElement_a">
							{this.htmlElement()}
						</div>
					)
				}
			</li>
    )
  }
}