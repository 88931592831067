import React from 'react'

import './MaterialIcons.css'

export default class MaterialIcons extends React.Component {
  constructor(props) {
    super(props)
  }

  getClass() {
    var output = []
    output.push("MaterialIcons")
    if (this.props.dark)
      output.push("md-dark")
    if (this.props.light)
      output.push("md-light")
    if (this.props.inactive)
      output.push("md-inactive")
    if (this.props.size)
      output.push("md-" + this.props.size)
    return output.join(" ")
  }

  getStyle() {
    if (this.props.color)
      return { 
        color: this.props.color
      }
  }

  render() {
    return (
      <i class={this.getClass()} style={this.getStyle()}>
        {this.props.children}
      </i>
    )
  }
}