import * as fnSleeper from '../../../utils/functions/sleeper.js'

import React from 'react'

import './WallOfShame.css'

import Card from '../../../components/card/Card/Card.jsx'
import User from '../components/User.jsx'

import MaterialIcons from '../../../components/icons/MaterialIcons/MaterialIcons.jsx'

export default class WallOfShame extends React.Component {
  constructor(props) {
    super(props)

		this.state = {
			inactivePlayersBySeason: null,
			mapShameByOwnerID: null,

			htmlBody: null,
		}
  }

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.season !== prevProps.season ||
			this.props.week !== prevProps.week ||
			this.props.mapMatchupsByWeek !== prevProps.mapMatchupsByWeek
		) {
			if (this.props.season && this.props.week && this.props.mapMatchupsByWeek)
				this.httpGetInactivePlayers(this.props.season, this.props.week, this.props.mapMatchupsByWeek)
			else
				new Promise(
					(resolve) => {
						this.setState({inactivePlayersBySeason: null})
						resolve(true)
					}
				).then(() => {})
		}

		if (
			this.state.inactivePlayersBySeason !== prevState.inactivePlayersBySeason ||
			this.props.mapLeagueUsers !== prevProps.mapLeagueUsers ||
			this.props.players !== prevProps.players ||
			this.props.season !== prevProps.season ||
			this.props.week !== prevProps.week ||
			this.props.playoffWeekStart !== prevProps.playoffWeekStart ||
			this.props.mapMatchupsByWeek !== prevProps.mapMatchupsByWeek ||
			this.props.mapLeagueRosters !== prevProps.mapLeagueRosters
		)
			this.updateMapShameByOwnerID(this.state.inactivePlayersBySeason, this.props.season, this.props.week, this.props.playoffWeekStart, this.props.mapMatchupsByWeek, this.props.mapLeagueRosters)

		if (
			this.state.mapShameByOwnerID !== prevState.mapShameByOwnerID ||
			this.props.mapLeagueUsers !== prevProps.mapLeagueUsers ||
			this.props.playoffWeekStart !== prevProps.playoffWeekStart ||
			this.props.players !== prevProps.players
		)
			this.updateHtmlBody()
	}

  httpGetInactivePlayers(season, week, mapMatchupsByWeek) {
		var mapPlayerIDs = new Map()
		var arrPlayerIDs = []

		mapMatchupsByWeek.forEach(
			(arrMatchups, matchupWeek) => {
				if (matchupWeek < week)
					for (const matchup of arrMatchups)
						if (matchup.starters !== null && matchup.starters !== "" && matchup.starters.length > 0)
							for (const starter of matchup.starters) {
								if (starter !== "0" && !mapPlayerIDs.has(starter)) {
									mapPlayerIDs.set(starter)
									arrPlayerIDs.push(parseInt(starter))
								}
							}
			}
		)

    if (!season || season === null || season.length === 0 || !(season >= 2000))
      return
    if (!arrPlayerIDs || arrPlayerIDs === null || arrPlayerIDs.length === 0)
      return
    
    var URL = "/a/rest/sleeper/inactivePlayers"

    fetch(
			URL, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'same-origin',
				body: JSON.stringify({
					seasons: [season],
					player_ids: arrPlayerIDs,
				}),
			}
		).then(
			(resp) => {
				return resp.json()
			}
		).then(
			(data) => {
				new Promise(
					(resolve) => {
						if (data.hasOwnProperty("error")) {
							this.setState({inactivePlayersBySeason: null})
							this.props.handlerError(data.error)
						}
						else if (data.hasOwnProperty("inactive_players_by_season") && data.inactive_players_by_season !== "") {
							this.setState({inactivePlayersBySeason: data.inactive_players_by_season})
						}
						else {
							this.setState({inactivePlayersBySeason: null})
							console.log("empty response for httpGetInactivePlayers()")
							this.props.handlerError("Something went wrong... Please refresh the page.")
						}
						resolve(true)
					}
				).then(() => {})
			}
		).catch(
			(error) => {
				console.error('Error:', error)
				this.props.handlerError("Something went wrong... Please refresh the page.")
			}
		)
  }

	updateMapShameByOwnerID(inactivePlayersBySeason, season, week, playoffWeekStart, mapMatchupsByWeek, mapLeagueRosters) {
		if (
			inactivePlayersBySeason === null ||
			season === null ||
			week === null ||
			playoffWeekStart === null ||
			mapMatchupsByWeek === null ||
			mapLeagueRosters === null ||
			!inactivePlayersBySeason.hasOwnProperty(season.toString())
		) {
			this.setState({ mapShameByOwnerID: null })
			return
		}

		var mapInactivePlayersByWeek = new Map()
		
		for (const [strSeason, objWeeks] of Object.entries(inactivePlayersBySeason)) {
			if (parseInt(strSeason) === season) {
				for (const [strWeek, arrInactivePlayers] of Object.entries(objWeeks)) {
					mapInactivePlayersByWeek.set(parseInt(strWeek), arrInactivePlayers)
				}
				break
			}
		}
		
		var mapShameByOwnerID = new Map()

		mapMatchupsByWeek.forEach(
			(arrMatchups, matchupWeek) => {
				if (matchupWeek < week && matchupWeek < playoffWeekStart && mapInactivePlayersByWeek.has(matchupWeek)) {
					var arrInactivePlayers = mapInactivePlayersByWeek.get(matchupWeek)

					for (const matchup of arrMatchups) {
						var ownerID = fnSleeper.getOwnerIDByRosterID(matchup.roster_id, mapLeagueRosters)
	
						for (const starter of matchup.starters) {
							if (starter === "0") {
								var mapViolationsByWeek = new Map()
								
								if (!mapShameByOwnerID.has(ownerID))
									mapViolationsByWeek.set(matchupWeek, [{reason: "emptySlot", playerID: 0}])
								else {
									mapViolationsByWeek = mapShameByOwnerID.get(ownerID)
									var arrViolationsForWeek = []

									if (mapShameByOwnerID.get(ownerID).has(matchupWeek))
										arrViolationsForWeek = mapShameByOwnerID.get(ownerID).get(matchupWeek)
									
									arrViolationsForWeek.push({reason: "emptySlot", playerID: 0})
									mapViolationsByWeek.set(matchupWeek, arrViolationsForWeek)
								}
	
								mapShameByOwnerID.set(ownerID, mapViolationsByWeek)
							}
							else {
								starter = parseInt(starter)

								for (const obj of arrInactivePlayers) {
									if (obj.player_id === starter) {
										var mapViolationsByWeek = new Map()
										
										if (!mapShameByOwnerID.has(ownerID))
											mapViolationsByWeek.set(matchupWeek, [{reason: obj.reason, playerID: starter}])
										else {
											mapViolationsByWeek = mapShameByOwnerID.get(ownerID)
											var arrViolationsForWeek = []

											if (mapShameByOwnerID.get(ownerID).has(matchupWeek))
												arrViolationsForWeek = mapShameByOwnerID.get(ownerID).get(matchupWeek)
											
											arrViolationsForWeek.push({reason: obj.reason, playerID: starter})
											mapViolationsByWeek.set(matchupWeek, arrViolationsForWeek)
										}
			
										mapShameByOwnerID.set(ownerID, mapViolationsByWeek)
										break
									}
								}
							}
						}
					}
				}
			}
		)

		this.setState({ mapShameByOwnerID: mapShameByOwnerID })
	}

	updateHtmlBody() {
		var mapShameByOwnerID = this.state.mapShameByOwnerID
		var mapLeagueUsers = this.props.mapLeagueUsers
		var playoffWeekStart = this.props.playoffWeekStart
		var players = this.props.players

		if (
			mapLeagueUsers === null ||
			playoffWeekStart === null ||
			players === null ||
			mapShameByOwnerID === null
		) {
			new Promise(
				(resolve) => {
					this.setState({
						htmlBody: (
						<li style={{listStyleType: 'none'}}>
							<div class="WallOfShame_empty_wrapper">
								<div class="WallOfShame_empty">
									<MaterialIcons size="48" color="var(--40pct-color)">hourglass_top</MaterialIcons>
									<div class="WallOfShame_empty_text">
										Loading
									</div>
								</div>
							</div>
						</li>
						)
					})
					resolve(true)
				}
			).then(() => {})
			
			return
		}

		if (mapShameByOwnerID.size === 0) {
			new Promise(
				(resolve) => {
					this.setState({
						htmlBody: (
						<li style={{listStyleType: 'none'}}>
							<div class="WallOfShame_empty_wrapper">
								<div class="WallOfShame_empty">
									<MaterialIcons size="48" color="var(--40pct-color)">emoji_emotions</MaterialIcons>
									<div class="WallOfShame_empty_text">
										Good news!<br/>The Wall of Shame has no inductees
									</div>
								</div>
							</div>
						</li>
						)
					})
					resolve(true)
				}
			).then(() => {})
			
			return
		}
		
		var html = []

		if (mapLeagueUsers && playoffWeekStart && players)
			mapShameByOwnerID.forEach(
				(mapViolationsByWeek, ownerID) => {
					html.push(
						<li class="WallOfShame_user_wrapper">
							<div class="WallOfShame_user">
								<User ownerID={ownerID} mapLeagueUsers={mapLeagueUsers} />
							</div>
							{this.htmlUserShame(playoffWeekStart, mapViolationsByWeek, players)}
						</li>
					)
				}
			)

		this.setState({ htmlBody: html })
	}

	htmlUserShame(playoffWeekStart, mapViolationsByWeek, players) {
		var html = []

		for (var week = 1; week < playoffWeekStart; week++) {
			if (mapViolationsByWeek.has(week)) {
				var html_li = []
				for (const violation of mapViolationsByWeek.get(week)) {
					var message = []

					switch(violation.reason) {
						case "emptySlot":
							message.push("had an empty slot")
							break
						case "BYE":
							message.push("started a player on BYE")
							break
						case "COV":
							message.push("started a COVID-designated player")
							break
						case "Out":
							message.push("started a player designated as 'Out'")
							break
						case "IR":
							message.push("started a player on IR")
							break
						case "Sus":
							message.push("started a suspended player")
							break
						case "":
							message.push("started a player on IR")
							break
						case "PUP":
							message.push("started a player designated as 'PUP'")
							break
						case "NA":
							message.push("started an inactive player")
							break
						case "Practice Squad":
							message.push("started a player on the practice squad")
							break
						default:
							message.push("started an inactive player")
					}

					if (violation.reason !== "emptySlot")
						message.push("(" + fnSleeper.getPlayerName(violation.playerID, players) + ")")
					
					html_li.push(
						<li class="WallOfShame_violation">
							<span>{message.join(" ")}</span>
						</li>
					)
				}

				html.push(
					<React.Fragment>
						<div class="WallOfShame_week">{"Week " + week}</div>
						<ul>
							{html_li}
						</ul>
					</React.Fragment>
				)
			}
		}

		return html
	}

  render() {
    return (
      <div id="WallOfShame">
				<div ref={this.refTable}>
					<Card
						clear
						primaryText="Wall of Shame"
						secondaryText={this.props.season ? this.props.season + " Regular season" : "Regular season"}
						body={
							<Card
								body={
									<ul>
										{this.state.htmlBody}
									</ul>
								}
							/>
						}
					/>
				</div>
      </div>
    )
  }
}