import React from 'react'

import './LeagueRules.css'

import Card from '../../components/card/Card/Card.jsx'

export default class LeagueRules extends React.Component {
  constructor(props) {
    super(props)

    this.refLeagueRules = React.createRef()
  }

  componentDidMount() {
		this.refLeagueRules.current.scrollTo(0, 0)
  }

  leagueRules() {
    if (this.props.body === null)
      return null
    
    var splits = this.props.body.split("<br>")

    var html = []
    for (const str of splits) {
      html.push(
        <li class="LeagueRules_li">{str}</li>
      )
    }
    return html
  }

  render() {
    return (
      <div id="LeagueRules" ref={this.refLeagueRules}>
				<Card
					primaryText={this.props.primaryText}
					secondaryText={this.props.secondaryText}
					body={
            <ul>
              {this.leagueRules()}
            </ul>
          }
				/>
      </div>
    )
  }
}